<template>
  <v-menu v-model="open" @input="onChange" nudge-right="-285px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind:activator="{ on, attrs }"></slot>
    </template>
    <div :class="{ 'popup-disturb': true, 'not-today': untilTime.length > 8 }">
      <div class="popup-disturb-container">
        <div class="d-flex flex-column">
          <div class="popup-disturb-title">Response in Progress...</div>
          <div class="popup-disturb-subtitle">
            Alerts paused until
            <div v-if="untilTime.length > 8" style="margin-bottom: 2px">{{ untilTime }}</div>
            <span v-else>{{ untilTime }}</span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <img src="@/assets/Sleeping_Image.svg" />
      </div>
      <div
        :class="{ 'popup-disturb-content': true, turnoff: true, focus: menuId === 0 }"
        @mouseover="onMouseOver(0)"
        @click="onClick(0)"
        style="margin-top: 11px"
      >
        <div class="text-truncate" style="font-weight: 500">Resume Alerts</div>
      </div>
      <ButtonAlertOffPausedDisturb ref="disturb" :item="item" @click="$emit('click')">
        <template v-slot="{ activator: { attrs, on } }">
          <div
            v-bind="attrs"
            :class="{ 'popup-disturb-content': true, focus: menuId === 1 }"
            @mouseover="onMouseOver(1, $event, on)"
            @click="onClick(1, $event, on)"
          >
            <div class="text-truncate">Adjust Time</div>
            <v-spacer></v-spacer>
            <img v-if="$vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-next-white1.svg" />
            <img v-else-if="menuId === 1" src="@/assets/Icon ionic-ios-arrow-next-white.svg" />
            <img v-else src="@/assets/Icon ionic-ios-arrow-next-black.svg" />
          </div>
        </template>
      </ButtonAlertOffPausedDisturb>
    </div>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'
import ButtonAlertOffPausedDisturb from './ButtonAlertOffPausedDisturb'
import { alert } from '@/api'
import moment from 'moment'

export default {
  components: { ButtonAlertOffPausedDisturb },
  /**
   * emit events
   * click
   */
  props: {
    item: Object,
  },
  data() {
    return {
      open: false,
      menuId: -1,
      prevMenuOn: null,
    }
  },
  computed: {
    untilTime() {
      const pausedEnd = moment(this.item.noti_pause_end)
      const today = moment().format('YYYY-MM-DD')
      if (pausedEnd.format('YYYY-MM-DD') === today) {
        return pausedEnd.format('hh:mm A')
      } else {
        return pausedEnd.format('MMMM Do, hh:mm A')
      }
    },
  },
  methods: {
    ...mapActions(['getCamList']),
    close() {
      this.open = false
      this.menuId = -1
      if (this.prevMenuOn) {
        this.$refs.disturb.close() // 안닫히는 경우가 있음... 수동으로 해당 컴포넌트 닫히도록 결정
        this.prevMenuOn.click(new MouseEvent('click'))
        this.prevMenuOn = null
      }
    },
    onChange(open) {
      if (!open) {
        this.close()
      }
    },
    onMouseOver(index, e, on) {
      if (this.prevMenuOn) {
        this.prevMenuOn.click(new MouseEvent('click'))
        this.prevMenuOn = null
      }
      this.menuId = index
      if (on) {
        this.prevMenuOn = on
        on.click(new MouseEvent('click'))
      }
    },
    async onClick(index, e, on) {
      if (index === 0) {
        this.$emit('click')
        this.close()
        try {
          await alert.clearPauseAlert({ camera_id: this.item.id })
          await this.getCamList({ justCamList: true })
        } catch (e) {
          this.$log.error('clearPauseAlert error')
        }
      } else if (index === 1) {
        if (this.prevMenuOn) {
          this.prevMenuOn.click(new MouseEvent('click'))
          this.prevMenuOn = null
        }
        this.prevMenuOn = on
        setTimeout(() => {
          on.click(new MouseEvent('click'))
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-disturb {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  height: 150px;
  color: var(--v-textDefault);
  background-color: var(--v-backgroundAsideTooltip-base);

  &.not-today {
    height: 171px;
  }
}

.popup-disturb-container {
  display: flex;
  background-color: var(--v-backgroundSearch-base);
  padding: 15px 20px;
}

.popup-disturb-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
  line-height: 19px;
}

.popup-disturb-subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.popup-disturb-content {
  display: flex;
  align-items: center;
  height: 26px;
  padding: 0 20px 0 17px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;

  img {
    height: 10px;
  }

  &.turnoff {
    color: #5f8bff;
    background-color: var(--v-backgroundAsideTooltip-base);
  }

  &.focus {
    color: var(--v-textCount);
    background-color: var(--v-backgroundAlertOff-base);
  }
}
</style>
