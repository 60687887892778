<template>
  <div class="root-container">
    <div v-show="!selectedTermsOfUses && !selectedPrivacyPolicy" :class="{ 'monitoring-container': true, worker: user.isWorker }">
      <main v-show="showMainLayout">
        <ImagePlayer
          ref="player"
          :currentLog="currentLog"
          :currentCamImageSrc="currentCamImageSrc"
          :monitor="true"
          @stop="onStopPlayer"
          @loaded="
            loadedImages = $event
            loading = false
          "
          @frame="currentFrameIndex = $event"
          :frame="frameIndex"
          :play="play"
          :download="frameMode === 'FRAME' && currentLogText"
          :class="{ player: true, worker: user.isWorker }"
        ></ImagePlayer>
        <ImagePlayerBar
          v-if="currentLog"
          ref="playerbar"
          :images="loadedImages"
          :frame="currentFrameIndex"
          :play="play"
          @changeFrame="onChangeFrame"
          @changeMode="frameMode = $event"
          @onPlay="onPlay()"
          style="height: 60px; margin-left: 26px; margin-top: 15px"
        ></ImagePlayerBar>
        <div :class="{ 'toolbar-title-container': true, live: currentCam && !currentLog, log: !currentCam && currentLog }">
          <div @click="onClickCamTitle" class="toolbar-title cam-name text-truncate">{{ currentCamText }}</div>
          <img v-if="alertOff" src="@/assets/Alert_Off_SkyBlue_Icon.svg" width="30px" style="margin-top: 20px" />
        </div>
        <div :class="{ toolbar: true, worker: user.isWorker }">
          <div :class="{ 'toolbar-title-container': true, empty: !currentLogText }">
            <div class="toolbar-subtitle text-truncate">{{ currentLogText }}</div>
          </div>
          <div
            v-if="(currentCam || currentLog) && tabIndex !== 2"
            :class="{
              label: true,
              unselected: currentLog && currentLog.event_type_id === eventTypeList[1].id,
              fire: currentLog && currentLog.event_type_id === eventTypeList[2].id,
              'non-fire': currentLog && currentLog.event_type_id === eventTypeList[3].id,
              unknown: currentLog && currentLog.event_type_id === eventTypeList[4].id,
              planned: currentLog && currentLog.event_type_id === eventTypeList[5].id,
            }"
          ></div>
          <div v-if="currentCam && !currentLog">
            <div class="toolbar-subtitle live" style="margin-left: 10px">Live View</div>
          </div>
          <ButtonPlayCircle
            v-if="!currentCam"
            @play="onPlay()"
            :didPlay="didPlay"
            :playing="playing"
            :play="play"
            :loading="loading"
            :disabled="!currentLog || loading"
            style="margin-left: 20px; margin-right: 10px"
          ></ButtonPlayCircle>
          <div style="flex-grow: 1"></div>
          <LinkGoto v-if="!user.isWorker" @click="onClickLinkGoto" style="margin-right: 21px"></LinkGoto>
          <LinkShare v-if="currentLog" @click="onClickLinkShare" :disabled="user.isWorker"></LinkShare>
        </div>
        <div class="toolbar-background"></div>
        <CamList v-if="!selectedHistoryCam && !user.isWorker" :currentCam="currentCam" @select="onClickCamImage"></CamList>
        <CamHistory v-if="selectedHistoryCam && !user.isWorker" :cam="historyCam" :log="currentLog" @select-log="onClickLog"></CamHistory>
      </main>
      <ReviewEvents v-if="selectedReviewEvents" @select-log="onClickLog"></ReviewEvents>
    </div>
    <div v-if="selectedTermsOfUses || selectedPrivacyPolicy" class="default-container">
      <TermsOfUse v-if="selectedTermsOfUses"></TermsOfUse>
      <PrivacyPolicy v-if="selectedPrivacyPolicy"></PrivacyPolicy>
    </div>
    <Footer v-if="!user.isWorker" @terms="selectTab = 'terms'" @policy="selectTab = 'policy'"></Footer>
    <Toolbar v-if="!user.isWorker" :tab="selectTab" @select="onClickSite" @home="onClickHome"></Toolbar>
    <AsideLog
      v-show="showAsideLayout"
      ref="asideLog"
      :currentCam="currentCam"
      :currentLog="currentLog"
      :isSiteSearchSelected="isSiteSearchSelected"
      :tabIndex="tabIndex"
      @select-cam="historyCam = $event"
      @select-log="onClickLog"
      @select-tab="onSelectTab"
    ></AsideLog>
    <SnackBar v-model="clipboard" :color="clipboardColor" :message="clipboardMsg" :mobile="isMobile"></SnackBar>
    <GotoDialog v-model="dialogGoto" :urls="gotoLinks"></GotoDialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Util from '@/util'
import { auth } from '@/api'
import Constants from '@/constants'
import Toolbar from '@desktop/superadminOrWorker/alert/Toolbar'
import AsideLog from '@desktop/superadminOrWorker/alert/AsideLog'
import CamHistory from '@desktop/superadminOrWorker/alert/CamHistory'
import ReviewEvents from '@desktop/superadminOrWorker/alert/ReviewEvents'
import TermsOfUse from '@desktop/superadminOrWorker/alert/TermsOfUse'
import PrivacyPolicy from '@desktop/superadminOrWorker/alert/PrivacyPolicy'
import ButtonPlayCircle from '@desktop/superadminOrWorker/alert/ButtonPlayCircle'
import LinkGoto from '@desktop/superadminOrWorker/alert/LinkGoto'
import LinkShare from '@desktop/superadminOrWorker/alert/LinkShare'
import ImagePlayer from '@common/ImagePlayer/ImagePlayer'
import CamList from '@desktop/superadminOrWorker/alert/CamList'
import Footer from '@desktop/superadminOrWorker/alert/Footer'
import GotoDialog from '@desktop/dialog/GotoDialog'
import SnackBar from '@common/SnackBar'
import ImagePlayerBar from '@common/ImagePlayer/ImagePlayerBar'
import moment from 'moment'
import RotuerMixin from '@/mixin/router.mixin'
import SelfInfoMixin from '@/mixin/selfinfo.mixin'

export default {
  mixins: [RotuerMixin, SelfInfoMixin],
  components: {
    Toolbar,
    AsideLog,
    ImagePlayer,
    CamList,
    Footer,
    SnackBar,
    CamHistory,
    ReviewEvents,
    TermsOfUse,
    PrivacyPolicy,
    ButtonPlayCircle,
    LinkGoto,
    LinkShare,
    GotoDialog,
    ImagePlayerBar,
  },
  props: ['name', 'logId', 'tabIndex'],
  data() {
    return {
      Constants,
      dialogGoto: false,
      INTERVAL_SITE_LOG: 5000,
      currentCam: null,
      currentLog: null,
      currentCamText: '',
      currentCamImageSrc: '',
      historyCam: null,
      play: 'stop',
      didPlay: false,
      playing: false,
      playLogId: -1,
      clipboard: false,
      clipboardColor: 'secondary',
      clipboardMsg: 'Copied to clipboard.',
      selectTab: '',
      loading: false,
      loadedImages: [],
      currentFrameIndex: 0,
      frameIndex: 0,
      frameMode: '',
      gotoLinks: [],
      actionDelaying: false,
      actionDelayingTimeout: null,
      openFirstRecentMenu: true,
      isSiteSearchSelected: false,
    }
  },
  computed: {
    ...mapState(['camMap', 'camList', 'recentLogList', 'eventTypeList', 'user']),
    selectedHistoryCam() {
      return this.selectTab === 'history' && this.$refs.asideLog && this.$refs.asideLog.isOpenSite
    },
    selectedReviewEvents() {
      return this.selectTab === 'review-events'
    },
    selectedTermsOfUses() {
      return this.selectTab === 'terms'
    },
    selectedPrivacyPolicy() {
      return this.selectTab === 'policy'
    },
    showMainLayout() {
      return !this.selectedReviewEvents && !this.selectedTermsOfUses && !this.selectedPrivacyPolicy
    },
    showAsideLayout() {
      return !this.selectedTermsOfUses && !this.selectedPrivacyPolicy
    },
    alertOff() {
      const now = moment()
      let cam = this.currentCam
      if (this.currentLog && this.selectTab !== 'cam') {
        cam = this.camMap[this.currentLog.camera_id]
      }
      if (cam && cam.noti_pause_start && cam.noti_pause_end) {
        if (now.isAfter(cam.noti_pause_start) && now.isBefore(cam.noti_pause_end)) {
          return true
        }
      }
      return false
    },
  },
  watch: {
    async tabIndex() {
      if (this.selectTab) {
        this.$refs.asideLog.onClickOpen()
      }
      await this.initialize()
    },
  },
  destroyed() {
    clearTimeout(this.intervalSiteLog)
    this.isDestroyed = true
  },
  methods: {
    ...mapActions(['getCamList', 'getRecentLogList', 'getLogList']),
    initVariable() {
      this.play = 'stop'
      this.didPlay = false
      this.playing = false
      this.loading = false
      this.currentLog = null
      this.loadedImages = []
      this.currentCam = null
      this.currentCamImageSrc = ''
      this.currentCamText = '-'
      this.currentLogText = '-'
    },
    async initialize() {
      this.initVariable()
      let name = this.$route.query.name
      let logId = parseInt(this.$route.query.id)

      // this.$log.debug(`Alert#created name=${name} logId=${logId}`)
      try {
        await this.getCamList({ tabIndex: this.tabIndex })
        if (logId >= 0) {
          // email or phone link의 logid로 들어왔을 시 해당 로그를 화면에 보여주기 위한 프로세스
          const log = this.recentLogList.find((log) => log.log_id === logId)
          // this.$log.debug(`Alert#created log=${log}`)
          if (log) {
            await this.onClickLog(log)
          } else {
            const data = await this.$store.dispatch('getLog', logId)
            if (data && data.created_at) {
              await this.onClickLog(data)
            }
          }
        } else if (name) {
          const cam = this.camList.find((cam) => cam.name === name)
          // this.$log.debug(`Alert#created cam=${cam}`)
          if (cam) {
            this.onClickCamImage(cam)
          } else {
            // 최근 로그를 보여줌
            await this.onClickLog(this.recentLogList[0])
          }
        } else if (this.recentLogList && this.recentLogList[0]) {
          // 최근 로그를 보여줌
          this.$log.debug('Alert#created')
          await this.onClickLog(this.recentLogList[0])
        } else if (this.camList && this.camList[0]) {
          this.$log.debug('Alert#created recent log empty')
          this.showFirstSiteCam()
        }
        this.$refs.asideLog.selectId = -1
        this.reqeustIntervalSiteLog()
      } catch (e) {}
    },
    showFirstSiteCam() {
      if (!this.$route.path.includes('/workspace/')) {
        this.onClickSite(this.camList[0].id)
      }
    },
    async onSelectTab(e) {
      this.$log.debug('Alert#onSelectTab select=', e)
      const prevTab = this.selectTab
      this.selectTab = e
      if (this.selectTab === 'review-events') {
        clearTimeout(this.intervalSiteLog)
        this.currentLog = null
        return
      }
      if (this.recentLogList && this.recentLogList[0]) {
        let tmp = null
        let openLog = false
        if (e === 'history') {
          tmp = this.currentLog
          this.currentLog = null
          this.loading = false
          this.loadedImages = []
        } else if (prevTab === 'history' && e === 'recent') {
          if (this.currentLog && this.recentLogList.some((log) => log.log_id === this.currentLog.log_id)) {
            tmp = this.currentLog
            this.currentLog = null
            this.loading = false
            this.loadedImages = []
            openLog = true
          }
        } else if (prevTab === '' && e === 'recent' && this.openFirstRecentMenu) {
          this.openFirstRecentMenu = false
          if (this.currentLog && this.recentLogList.some((log) => log.log_id === this.currentLog.log_id)) {
            openLog = true
          }
        }
        this.$log.debug('Alert#onSelectTab', prevTab, e)
        this.changeModePlaybar()
        this.$nextTick(async () => {
          if (tmp && prevTab !== this.selectTab) {
            await this.onClickLog(tmp, openLog)
            if (this.camMap[tmp.camera_id]) {
              this.historyCam = tmp.camera_id
            }
          } else {
            if (openLog) {
              await this.onClickLog(this.currentLog, true)
            } else if (this.selectTab !== 'cam') {
              await this.onClickLog(this.recentLogList[0])
            }
          }
        })
      } else if (this.camList && this.camList[0]) {
        this.$log.debug('Alert#onSelectTab recent log empty')
        this.showFirstSiteCam()
      }
    },
    async onClickHome() {
      this.$log.debug('Alert#onClickHome')
      this.$router.push('/')
      // if (this.recentLogList && this.recentLogList[0]) {
      //   await this.onClickLog(this.recentLogList[0])
      //   this.$refs.asideLog.onClickOpen()
      //   this.selectTab = ''
      //   this.openMap()
      // } else {
      //   this.$refs.asideLog.onClickOpen()
      //   this.selectTab = ''
      //   if (this.camList && this.camList[0]) {
      //     this.showFirstSiteCam()
      //   }
      // }
      // this.isSiteSearchSelected = false
    },
    onClickCamImage(cam, ignoreScroll) {
      this.$log.debug('Alert#onClickCamImage', cam && cam.id)
      this.play = 'stop'
      this.currentCam = cam
      if (this.currentCam) {
        this.setGotoLink(this.currentCam)
        this.currentCamImageSrc = Util.getImageUrl(this.currentCam.path, true)

        this.currentLog = null
        this.loading = false
        this.loadedImages = []
        this.currentCamText = cam.name
        this.currentLogText = ''
        !ignoreScroll && Util.moveScrollToTop()
      }
    },
    setGotoLink(cam) {
      this.gotoLinks = [cam.public_url, cam.private_url_1, cam.private_url_2]
    },
    reqeustIntervalSiteLog() {
      clearTimeout(this.intervalSiteLog)
      if (this.isDestroyed) return
      this.intervalSiteLog = setTimeout(async () => {
        const asideLog = this.$refs.asideLog
        const firstLog = this.recentLogList && this.recentLogList.length && this.recentLogList[0]
        const mapLoading = firstLog && firstLog.direction === null && !asideLog.isOpenReviewEvents && !asideLog.isOpenCam
        await this.getCamList({ justCamList: true })
        await this.getRecentLogList(this.tabIndex)
        const updateFirstLog = this.recentLogList && this.recentLogList.length && this.recentLogList[0]
        this.reqeustIntervalSiteLog()
        if (this.currentCam) {
          this.onClickCamImage(this.currentCam, true)
        } else if ((!firstLog && updateFirstLog) || firstLog.log_id !== updateFirstLog.log_id) {
          this.onClickLog(updateFirstLog)
          if (!asideLog.mapDisabled && !asideLog.mapLoading) {
            this.openMap()
          }
        } else if (firstLog && mapLoading && !asideLog.mapLoading) {
          this.openMap()
        }
      }, this.INTERVAL_SITE_LOG)
    },
    openMap() {
      const asideLog = this.$refs.asideLog
      // this.$log.debug('Alert#openMap', asideLog && asideLog.isOpenMap)
      if (asideLog && !asideLog.isOpenMap) {
        asideLog.onClickMap()
      }
    },
    onClickCamTitle() {
      this.$log.debug('Alert#onClickCamTitle', this.currentCam, this.currentLog)
      if (this.currentLog && this.selectTab !== 'cam') {
        if (this.currentCam) {
          this.onClickSite(this.currentCam.id)
        } else {
          this.onClickSite(this.currentLog.camera_id)
        }
      }
    },
    onClickSite(id) {
      this.$log.debug('Alert#onClickSite', id)
      if (id === null) {
        this.initialize()
        this.isSiteSearchSelected = false
      } else {
        this.isSiteSearchSelected = true
        this.didPlay = false
        this.playing = false
        const cam = this.camList.find((cam) => cam.id === id)
        if (cam) {
          this.onClickCamImage(cam)
        }
      }
    },
    async onClickLog(log, openLog = false) {
      this.$log.debug('Alert#onClickLog', log)
      if (log) {
        this.initVariable()
        this.currentLog = { ...log }

        const cam = this.camMap[log.camera_id]
        this.setGotoLink(cam)
        this.currentCamText = cam?.name || '-'
        this.currentLogText = this.displayLogTime(log)
        Util.moveScrollToTop()
        log.callback && log.callback()
        this.$nextTick(() => {
          this.openMap()
          if (openLog) {
            this.$refs.asideLog.onClickLog(log)
            // 어떻게 참조하고 가져와야 할지 애매해서 일단은 querySelector를 이용함
            setImmediate(() => {
              const logElement = document.querySelector('.recent-log.labeled.unlabeled.focus')
              if (logElement) {
                const scrollWrapper = logElement.parentElement
                scrollWrapper.scrollTop = logElement.offsetTop - scrollWrapper.offsetTop
              }
            })
          }
        })
      } else {
        this.initVariable()
        this.currentLog = log
      }
    },
    displayLogTime(log) {
      const cam = this.camMap[log.camera_id]
      if (cam) {
        const date = Util.getOffsetDate(log.created_at, log.offset)
        return `[${date}] ${cam.name}`
      } else {
        return log.log
      }
    },
    onStopPlayer() {
      this.$log.debug('Admin#onStopPlayer', this.playLogId, this.currentLog?.log_id)
      this.play = 'stop'
      if (this.currentLog && this.playLogId === this.currentLog.log_id) {
        this.didPlay = true
      } else {
        this.didPlay = false
      }
      this.playing = false
    },
    onPlay() {
      this.$log.debug('Alert$onPlay', this.currentLog, this.play)
      if (this.currentLog) {
        this.$log.debug('Alert#onPlay', this.play, this.playing, this.didPlay, this.loading)
        if (this.play === 'play') {
          this.play = 'pause'
          this.playing = true
        } else if (this.play === 'pause') {
          this.changeModePlaybar()
          this.play = 'play'
        } else {
          this.changeModePlaybar()
          this.didPlay = false
          this.play = 'play'
          this.playLogId = this.currentLog.log_id
          this.loading = true
        }
        this.actionButton()
      }
    },
    actionButton() {
      this.actionDelaying = true
      clearTimeout(this.actionDelayingTimeout)
      this.actionDelayingTimeout = setTimeout(() => {
        this.actionDelaying = false
      }, 500)
    },
    changeModePlaybar() {
      const playerbar = this.$refs.playerbar instanceof Array ? this.$refs.playerbar[0] : this.$refs.playerbar
      if (playerbar) {
        if (playerbar.mode === playerbar.FRAME) {
          playerbar.onChangeMode(true)
        }
      }
    },
    onChangeFrame(frameIndex) {
      this.$log.debug('Alert#onChangeFrame', this.play, this.didPlay, this.frameIndex, frameIndex)
      if (this.frameIndex === frameIndex) {
        this.frameIndex = frameIndex
        const player = this.$refs.player instanceof Array ? this.$refs.player[0] : this.$refs.player
        player.playerPause(frameIndex)
      } else {
        this.frameIndex = frameIndex
      }
      if (this.play === 'play' || (this.play === 'stop' && !this.didPlay)) {
        this.onPlay()
      } else {
        this.play = 'pause'
        this.playing = true
        this.didPlay = false
      }
    },
    async onClickLinkShare() {
      try {
        this.clipboardColor = 'secondary'
        this.clipboardMsg = 'Copied to clipboard.'
        const copyRes = await Util.copyClipboard(`${location.origin}/m/detection?name=${this.currentLog.camera_name}&id=${this.currentLog.log_id}`)
        if (!!copyRes) {
          setTimeout(() => {
            this.clipboard = true
          }, 500)
        }
      } catch (e) {
        this.clipboard = true
        this.clipboardColor = 'error'
        this.clipboardMsg = e.toString()
      }
    },
    onClickLinkGoto() {
      this.$log.debug('Alert#onClickLinkGoto')
      this.dialogGoto = true
    },
  },
}
</script>

<style lang="scss" scoped>
.root-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--v-background-base);
  width: 100%;
  height: 100%;
}
.monitoring-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 74px 485px 0px 0px;

  &.worker {
    margin-top: 89px;
  }
}

.default-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 74px 0px 0px 0px;
  height: 0;
  overflow: hidden;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.player {
  position: relative;
  min-height: 430px;
  height: 60vh;
  background-color: #000;
  margin: 15px 0px 0px 30px;
  border-radius: 20px;

  &.worker {
    flex-grow: 1;
  }
}
.toolbar {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &.worker {
    margin-bottom: 120px;
  }

  .label {
    min-width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #ffc32b;

    &.unselected {
      background: none;
      border: 1px solid var(--v-backgroundUnselected);
    }

    &.fire {
      background-color: var(--v-backgroundFire-base);
    }

    &.non-fire {
      background-color: var(--v-backgroundNoneFire-base);
    }

    &.unknown {
      background-color: var(--v-backgroundUnknown-base);
    }

    &.planned {
      background-color: var(--f-supplementary-purple);
    }
  }
}

.toolbar-background {
  width: 100%;
  margin-top: 13px;
  margin-left: 30px;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: var(--v-borderToolbarBottom-base);
}

.toolbar-title-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 50px;
  margin-right: 20px;
  overflow: hidden;

  &.live {
    margin-bottom: 10px;
  }

  &.log {
    margin-bottom: 3px;
  }

  &.empty {
    margin-right: 0px;
  }
}

.toolbar-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--v-textDefault);

  &.cam-name {
    margin-top: 20px;
  }
}

.toolbar-subtitle {
  font-weight: 400;
  font-size: 15px;
  color: var(--v-textDefault);
  user-select: text;

  &.live {
    user-select: none;
    font-size: 16px;
    color: var(--v-textToolbarLiveview);
  }
}

.toolbar-button {
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 1;
  width: 120px;
  height: 34px;
  background-color: #3d51fb;
  border-radius: 30px;
}
</style>
