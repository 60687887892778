<template>
  <div class="cam-history-container">
    <div
      v-for="l in logList"
      :key="l.log_id"
      @click="onClickLog(l)"
      :class="{ 'cam-history-log-container': true, focus: log && l.log_id === log.log_id }"
    >
      <span class="text-truncate" style="padding: 0 20px">{{ displayLogTime(l) }}</span>
      <div class="spacer-1"></div>
      <div
        v-if="l.event_type_id > eventTypeList[1].id"
        :class="{
          'icon-label': true,
          fire: l.event_type_id === eventTypeList[2].id,
          'non-fire': l.event_type_id === eventTypeList[3].id,
          unknown: l.event_type_id === eventTypeList[4].id,
          planned: l.event_type_id === eventTypeList[5].id,
        }"
      >
        <div v-if="l.event_type_id === eventTypeList[2].id">{{ eventTypeList[2].name }}</div>
        <div v-if="l.event_type_id === eventTypeList[3].id">{{ eventTypeList[3].name }}</div>
        <div v-if="l.event_type_id === eventTypeList[4].id">{{ eventTypeList[4].name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Util from '@/util'
import Constants from '@/constants'

export default {
  /**
   * emit events
   * select-log
   */
  props: {
    cam: {
      type: Object,
      required: true,
      default: [],
    },
    log: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      Constants,
    }
  },
  computed: {
    ...mapState(['recentLogList', 'camMap', 'camList', 'eventTypeList']),
    logList() {
      if (this.cam && (this.cam.id || this.cam.camera_id)) {
        const selectedSite = this.cam.camera_id || this.cam.id
        const logs = this.camMap[selectedSite].logs
        return logs
      }
      return []
    },
  },
  methods: {
    displayLogTime(log) {
      const date = Util.getOffsetDate(log.created_at, log.offset)
      return `[${date}] ${this.cam.name}`
    },
    onClickLog(log) {
      this.$emit('select-log', log)
    },
  },
}
</script>

<style lang="scss" scoped>
.cam-history-container {
  display: flex;
  flex-direction: column;
  margin: 20px 10px 70px 50px;
  min-height: 25px;
}

.cam-history-log-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 500;
  font-size: 16px;
  color: var(--v-textDefault);
  height: 60px;
  background-color: var(--v-backgroundSearch-base);
  margin-bottom: 15px;
  border-radius: 15px;

  &.focus {
    background-color: var(--v-backgroundAsideHeaderRecentLabel-base);
    // color: #5f8bff;
  }
}

.icon-label {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 110px;
  height: 36px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffffde;
  margin-right: 30px;

  &.fire {
    background-color: var(--v-backgroundFire-base);
  }

  &.non-fire {
    background-color: var(--v-backgroundNoneFire-base);
  }

  &.unknown {
    background-color: var(--v-backgroundUnknown-base);
  }

  &.planned {
    background-color: var(--f-supplementary-purple);
  }
}
</style>
