<template>
  <div class="log-accordion-content">
    <div class="log-accordion-content wrapper">
      <div
        v-for="(log, index) in recentLogList"
        :key="log.log_id"
        @click="onClickLog(log)"
        :class="{
          'log-accordion-content': true,
          text: true,
          focus: selectId === log.log_id || (selectId === -1 && index === 0),
        }"
      >
        {{ displayLogTime(log) }}
        <div class="spacer-1"></div>
        <div
          :class="{
            'icon-label': true,
            fire: log.event_type_id === eventTypeList[2].id,
            'non-fire': log.event_type_id === eventTypeList[3].id,
            unknown: log.event_type_id === eventTypeList[4].id,
            planned: log.event_type_id === eventTypeList[5].id,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import AsideLogRecent from '@desktop/superadminOrWorker/alert/AsideLogRecent'

export default {
  extends: AsideLogRecent,
}
</script>

<style lang="scss" scoped>
.log-accordion-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: var(--v-backgroundAsideRecentSpace-base);

  &.wrapper {
    margin-left: 30px;
    overflow: auto;
  }

  &.text {
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    color: var(--v-textDefault);
    background-color: var(--v-backgroundAsideHeaderRecent-base);
    padding: 0px 20px 0 25px;
    flex: 0 0 40px;
    cursor: pointer;

    &.focus {
      background-color: var(--v-backgroundAsideHeaderRecentLabel-base);
    }

    &:not(:last-child) {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-color: var(--v-borderLineBottom-base);
    }
  }
}

.icon-label {
  min-width: 8px;
  height: 8px;
  border-radius: 8px;
  border: 1px solid var(--v-textLabelBorder);

  &.fire {
    background-color: var(--v-backgroundFire-base);
    border-width: 0px;
  }

  &.non-fire {
    background-color: var(--v-backgroundNoneFire-base);
    border-width: 0px;
  }

  &.unknown {
    background-color: var(--v-backgroundUnknown-base);
    border-width: 0px;
  }

  &.planned {
    background-color: var(--f-supplementary-purple);
    border-width: 0px;
  }
}
</style>
