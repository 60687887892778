<template>
  <div class="terms-of-use-container">
    <div class="terms-of-use-title">Terms of Use</div>
    <div class="terms-of-use-line"></div>
    <div style="height: 0; flex-grow: 1; overflow: auto">
      <div class="terms-of-use-subtitle">1. Acceptance of Terms</div>
      <div>
        Alchera Inc. (“we” or “Alchera”) provides you with access to a variety of contents and information via this website. The Services (as defined
        below) that we provide to you are subject to this Terms of Use. We may update this Terms of Use at any time without notice. By using this
        website, you agree to accept and comply with this Terms of Use.
      </div>
      <div class="terms-of-use-subtitle">2. Definition</div>
      <div>
        • “Event Type” shall mean type of fire (e.g. fire, non-fire, unknown) in each Log that User records by using Services.<br />
        • “Log” shall mean date, time and detection image of smoke which Alchera’s AI technology detected and Alchera’s monitoring personnel confirmed
        since User’s camara is connected to Services.<br />
        • “Services” shall mean Alchera’s services for User in relation to Wildfire Alert System.<br />
        • “User” (or you) shall mean a customer who entered into a service agreement with Alchera and is granted an account to use the Services, and
        its interested party who is authorized and granted an account by Alchera.
      </div>
      <div class="terms-of-use-subtitle">3. Service Agreement</div>
      <div>
        Alchera, in principle, will grant an account to User who has entered into a service agreement with us and applied to use the Services.
        However, Alchera may, in its sole discretion, grant an account to an appropriate interested party who did not enter into a service agreement
        with it. Alchera may classify Users’ accounts in accordance with its company policy and grant different right to use Services.
      </div>
      <div class="terms-of-use-subtitle">4. Services</div>
      <div>
        Alchera may modify its Services in whole or in part as necessary for its operational or technological needs. In the event of error or failure
        of server, Alchera shall take any necessary technical action to cure the problem. The details of the Services are as below:<br />
        • Smoke detection notification: sending notification via message or email that a customer provided<br />
        • History of Log search: provision of list of Log for a designated period by each User<br />
        • Record for individual Event Type in generated Log: User may record Event Type in dashboard for a recorded Log<br />
        Provided, that access to the above Services varies depending on the account rights granted to each User.
      </div>
      <div class="terms-of-use-subtitle">5. Limited Right to Use</div>
      <div>
        Unless otherwise specified, all materials on this website are the property of Alchera and are protected by the copyright laws of the United
        States and, throughout the world by the applicable copyright laws. All materials on this website are solely for your personal, informational
        and non-commercial use. You shall not disclose to a third party nor modify, copy, distribute, transmit, display, perform, reproduce, publish,
        license, transfer or sell any information, software, products, or services obtained from the Services.
      </div>
      <div class="terms-of-use-subtitle">6. Confidentiality</div>
      <div>
        Except as specified in our Privacy Policy, all communications from you to our website will be considered non-confidential and non-proprietary.
        Any and all your communications to our website will be deemed the property of Alchera, and Alchera shall be entitled to full rights of
        ownership, including without limitation, unrestricted right to use or disclose such communications for any purpose, without compensation to
        you. Further, Alchera may collect Event Type that User recorded and use for technical research.
      </div>
      <div class="terms-of-use-subtitle">7. No Unlawful or Prohibited Use</div>
      <div>
        As a condition of your use of the Services, you will not use the Services for any purpose that is unlawful or prohibited by this Terms of Use.
        You may not use the Services in any manner that could damage or impair our Services or website, or interfere with any other party’s use of our
        Services.
      </div>
      <div class="terms-of-use-subtitle">8. Indemnity</div>
      <div>
        You agree to indemnify, defend and hold Alchera harmless from and against any and all third party claims, liabilities, damages, losses or
        expenses (including reasonable attorney's fees and costs) arising out of, based on or in connection with your access and/or use of our
        Services.
      </div>
      <div class="terms-of-use-subtitle">9. Limitation of Liability</div>
      <div>
        IN NO EVENT SHALL ALCHERA BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES INCLUDING, WITHOUT LIMITATION, LOSS
        PROFITS OR REVENUES, COSTS OF REPLACEMENT GOODS, LOSS OR DAMAGE TO DATA ARISING OUT OF THE USE OR INABILITY TO USE THIS WEBSITE OR ANY LINKED
        SITE, DAMAGES RESULTING FROM USE OF OR RELIANCE ON THE INFORMATION OR MATERIALS PRESENTED ON THIS WEBSITE, WHETHER BASED ON WARRANTY,
        CONTRACT, TORT OR ANY OTHER LEGAL THEORY EVEN IF ALCHERA HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.<br />
        Further, in no event shall Alchera be liable for i) any disability in use of the Services due to reasons attributable to you, and ii) any
        false-positive alarm for smoke detection and any result therefrom. Alchera is not responsible for accuracy or reliability of Event Type in
        each Log.
      </div>
      <div class="terms-of-use-subtitle">10. Governing Law and Jurisdiction</div>
      <div>
        • This Agreement shall be governed by and construed in accordance with Laws of the State of California.<br />
        • Any dispute arising out of or in connection with this Agreement shall be resolved amicably by mutual agreement. If such dispute cannot be
        resolved, the Santa Clara county District Court shall have the exclusive jurisdiction as the court of first instance over such dispute.
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.terms-of-use-container {
  color: var(--v-textDefault);
  font-size: 14px;
  font-weight: 400;
  margin: 38px 100px 28px;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.terms-of-use-line {
  height: 1px;
  background-color: var(--v-borderToolbarBottom-base);
  margin-top: 10px;
}

.terms-of-use-title {
  font-weight: 500;
  font-size: 20px;
}

.terms-of-use-subtitle {
  font-weight: 600;
  margin-top: 30px;
}
</style>
