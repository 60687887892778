<template>
  <header class="background-toolbar">
    <div @click="onClickHome" class="d-flex">
      <img v-if="$vuetify.theme.dark" src="@/assets/group 1600-dark.svg" class="icon-logo" />
      <img v-else src="@/assets/group 1600-light.svg" class="icon-logo" />
      <div class="d-flex flex-column">
        <div class="text-title">Wildfire Alert System</div>
        <div class="text-subtitle">{{ organization }}</div>
      </div>
    </div>
    <div v-if="tab !== 'review-events' && componentShow" style="margin-left: 40px; width: 340px">
      <DropdownSearch :items="camList" item-text="name" item-value="id" @input="onSelectSite"></DropdownSearch>
    </div>
    <ButtonAlertOff v-if="tab !== 'review-events' && componentShow" :disabled="!isAdmin || !isRecentLog"></ButtonAlertOff>
    <div class="spacer-1"></div>
    <div v-if="componentShow" class="hello">
      Hello, <span class="name">{{ username }}</span>
    </div>
    <button v-if="componentShow" @click="onClickMonitor" :class="{ monitor: true, disabled: isViewer }">Monitor</button>
    <button v-if="componentShow" @click="onClickLogout" class="logout">Logout</button>
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { auth } from '@/api'
import NativeService from '@/native-service'
import Constants from '@/constants'
import ButtonAlertOff from './ButtonAlertOff'
import DropdownSearch from '@common/DropdownSearch'

export default {
  components: { ButtonAlertOff, DropdownSearch },
  props: {
    tab: String,
  },
  data() {
    return {
      Constants,
      searchInput: '',
    }
  },
  /**
   * emit events
   * select Number
   * home
   */
  computed: {
    ...mapState(['camList', 'user', 'recentLogList']),
    organization() {
      return this.user.organization_name
    },
    username() {
      return this.user.user_name ? this.user.user_name + '!' : ''
    },
    languageText() {
      let lang
      if (this.$vuetify.lang.current === 'ko') {
        lang = 'ENG'
      } else {
        lang = 'KOR'
      }
      return lang
    },
    componentShow() {
      return this.tab !== 'terms' && this.tab !== 'policy'
    },
    isViewer() {
      return this.user.isViewer
    },
    isAdmin() {
      return this.user.isAdmin
    },
    isRecentLog() {
      return !!this.recentLogList.length
    },
  },
  methods: {
    onChangeLanguage() {
      if (this.$vuetify.lang.current === 'en') {
        this.$vuetify.lang.current = 'ko'
      } else {
        this.$vuetify.lang.current = 'en'
      }
    },
    onSelectSite(camid) {
      this.$emit('select', camid)
    },
    async onClickLogout() {
      this.$store.dispatch('logout')
    },
    onClickLogin() {
      this.$router.push('/login')
    },
    onClickMonitor() {
      if (!this.isViewer) {
        this.$router.push(`/${this.user.organization_name}/monitor`)
      }
    },
    onClickHome() {
      this.$emit('home')
    },
  },
}
</script>

<style lang="scss" scoped>
.background-toolbar {
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 74px;
  background-color: var(--v-background-base);
  z-index: 2;

  .icon-logo {
    margin-left: 30px;
    margin-right: 15px;
  }
}

.text-title {
  color: var(--v-textDefault);
  font-weight: 500;
  font-size: 18px;
}

.text-subtitle {
  font-weight: 500;
  font-size: 14px;
  color: #3d51fb;
}

.lang-text {
  height: 70px;
  margin-right: 15px;
  font-weight: bold !important;
  font-size: 20px !important;
  color: white !important;
}

.hello {
  font-weight: 500;
  font-size: 14px;
  color: #8d90a2;
  margin-right: 15px;

  & .name {
    color: #6d7dff;
  }
}

button {
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #3d51fb;
  border-radius: 30px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    margin-right: 10px;
  }

  &:active {
    opacity: 0.5;
  }

  &.monitor {
    color: #ffffffde;
    background-color: #3d51fb;
    margin-right: 15px;
    font-weight: 500;

    &.disabled {
      border-width: 0;
      color: var(--v-textDisabledButton);
      background-color: var(--v-backgroundDisabledButton);
    }
  }

  &.logout {
    color: var(--v-textDefault);
    margin-left: 20px;
    margin-right: 30px;
    border-width: 0px;
    min-width: 0px;
    font-weight: 500 !important;
  }
}
</style>
