<template>
  <div class="log-accordion-content">
    <div class="log-accordion-content wrapper">
      <div
        v-for="cam in camList"
        :key="cam.id"
        @click="onClickCamImage(cam)"
        :class="{
          'log-accordion-content': true,
          text: true,
          focus: selectSite === cam.id,
        }"
      >
        <div class="text-truncate">{{ cam.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /**
   * emit events
   * cam
   */
  props: {
    camList: Array,
    selectSite: Number,
  },
  methods: {
    onClickCamImage(cam) {
      this.$emit('cam', cam)
    },
  },
}
</script>

<style lang="scss" scoped>
.log-accordion-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: var(--v-backgroundAsideRecentSpace-base);

  &.wrapper {
    margin-left: 30px;
    overflow: auto;
  }

  &.text {
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    color: var(--v-textDefault);
    background-color: var(--v-backgroundAsideHeaderRecent-base);
    padding: 0px 20px 0 25px;
    flex: 0 0 40px;
    cursor: pointer;

    &.focus {
      background-color: var(--v-backgroundAsideHeaderRecentLabel-base);
    }

    &:not(:last-child) {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-color: var(--v-borderLineBottom-base);
    }
  }
}
</style>
