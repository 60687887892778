<template>
  <div refs="coverRefs" class="button-fullscreen-icon-container" :class="{ disabled: toggle }" @click="switchToShow">
    <img
      v-if="play === 'pause' && !isMobileLiveCamImage"
      class="icon"
      :class="{ stop: toggle }"
      src="@/assets/Play-Icon.svg"
      @click="clickPlayerButton"
    />
    <img
      v-if="play === 'play' && !isMobileLiveCamImage"
      class="icon"
      :class="{ stop: toggle }"
      src="@/assets/Pause-Icon.svg"
      @click="clickPlayerButton"
    />
    <img
      v-if="play === 'stop' && !isMobileLiveCamImage"
      class="icon"
      :class="{ stop: toggle }"
      src="@/assets/Replay-Icon.svg"
      @click="clickPlayerButton"
    />
    <img :class="{ 'fullscreen-icon': isFullscreen }" src="@/assets/Mobile_FullScreen_Button.svg" width="16px" @click="onClickButton" />
  </div>
</template>

<script>
export default {
  name: 'MobileFullscreenCover',
  props: {
    isFullscreen: {
      type: Boolean,
    },
    isMobileLiveCamImage: {
      type: Boolean,
    },
    play: {
      type: String,
      default: 'pause',
    },
  },
  data() {
    return {
      toggle: true,
    }
  },
  methods: {
    clickPlayerButton(event) {
      event.stopPropagation()
      if (this.play !== 'play') this.toggle = true
      this.$emit('onPlay')
    },
    switchToShow() {
      if (this.toggle) {
        this.toggle = false
      } else {
        this.toggle = true
      }
    },
    onClickButton() {
      this.$emit('clickToFullscreenPlayer')
    },
  },
}
</script>

<style lang="scss" scoped>
.button-fullscreen-icon-container {
  transition: opacity 0.3s ease;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
}
.stop {
  display: none;
}
.disabled {
  opacity: 0;
}

@media (orientation: portrait) {
  .fullscreen-icon {
    top: 95%;
    position: absolute;
    left: 5%;
  }
  .icon {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

@media (orientation: landscape) {
  .fullscreen-icon {
    top: 90%;
    position: absolute;
    right: 5%;
  }
  .icon {
    transform: translate(-50%, -50%);
  }
}
</style>
