<template>
  <div @click="$emit('click')" class="d-flex align-center" style="z-index: 1; cursor: pointer; font-weight: 500; font-size: 16px">
    <img v-if="$vuetify.theme.dark && !disabled" src="@/assets/Group 330_dark.svg" />
    <img v-else-if="!disabled" src="@/assets/carbon_copy-link.svg" />
    <img v-else src="@/assets/Copylink_Grey_Icon.svg" />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped></style>
