<template>
  <aside class="background">
    <div class="split-line"></div>
    <div class="content-wrapper">
      <!-- Detailed Information -->
      <div v-if="!isOpenCam || isSiteSearchSelected" @click="onClickDetailedInfo" :class="{ 'log-accordion-header': true, open: isOpenDetailedInfo }">
        <img v-if="$vuetify.theme.dark" src="@/assets/Info_Line_16px_Icon_Dark.svg" class="img-recent" />
        <img v-else src="@/assets/Info_Line_16px_Icon_Light.svg" class="img-recent" />
        <div :class="{ 'text-log-header': true, active: isOpenDetailedInfo }" style="margin-left: 10px">Detailed Information</div>
        <div class="spacer-1"></div>
        <img v-if="mapLoading" src="@/assets/Loading_Icon.svg" width="16px" style="margin-right: 24px" />
        <img
          v-else-if="$vuetify.theme.dark && isOpenDetailedInfo"
          src="@/assets/Icon ionic-ios-arrow-up-sub.svg"
          width="10px"
          style="margin-right: 27px"
        />
        <img
          v-else-if="$vuetify.theme.dark && !isOpenDetailedInfo"
          src="@/assets/Icon ionic-ios-arrow-down-dark.svg"
          width="10px"
          style="margin-right: 27px"
        />
        <img v-else-if="isOpenDetailedInfo" src="@/assets/Icon ionic-ios-arrow-up-sub-black.svg" width="10px" style="margin-right: 27px" />
        <img v-else src="@/assets/Icon ionic-ios-arrow-down-sub-black.svg" width="10px" style="margin-right: 27px" />
      </div>
      <AsideDetailedInfo v-if="isOpenDetailedInfo" :currentCam="currentCam" :currentLog="currentLog"></AsideDetailedInfo>
      <!-- Map -->
      <div
        v-if="!isOpenCam || isSiteSearchSelected"
        @click="onClickMap"
        :class="{ 'log-accordion-header': true, open: isOpenMap, disabled: mapDisabled }"
      >
        <img v-if="$vuetify.theme.dark && !mapDisabled" src="@/assets/Map_Line_18px_Icon_Light.svg" class="img-recent" />
        <img v-else-if="!mapDisabled" src="@/assets/Map_Line_18px_Icon_Dark.svg" class="img-recent" />
        <img v-else-if="$vuetify.theme.dark" src="@/assets/Map_Line_18px_Icon_Light.svg" class="img-recent" style="opacity: 0.8" />
        <img v-else src="@/assets/Map_Line_18px_Icon_Dark.svg" class="img-recent" style="opacity: 0.4" />
        <div :class="{ 'text-log-header': true, active: isOpenMap, disabled: mapDisabled }" style="margin-left: 10px">Map</div>
        <div class="spacer-1"></div>
        <img v-if="mapLoading" src="@/assets/Loading_Icon.svg" width="16px" style="margin-right: 24px" />
        <img
          v-else-if="$vuetify.theme.dark && mapDisabled"
          src="@/assets/Icon ionic-ios-arrow-down-gray-dark.svg"
          width="10px"
          style="margin-right: 27px"
        />
        <img v-else-if="mapDisabled" src="@/assets/Icon ionic-ios-arrow-down-gray.svg" width="10px" style="margin-right: 27px" />
        <img v-else-if="$vuetify.theme.dark && isOpenMap" src="@/assets/Icon ionic-ios-arrow-up-sub.svg" width="10px" style="margin-right: 27px" />
        <img
          v-else-if="$vuetify.theme.dark && !isOpenMap"
          src="@/assets/Icon ionic-ios-arrow-down-dark.svg"
          width="10px"
          style="margin-right: 27px"
        />
        <img v-else-if="isOpenMap" src="@/assets/Icon ionic-ios-arrow-up-sub-black.svg" width="10px" style="margin-right: 27px" />
        <img v-else src="@/assets/Icon ionic-ios-arrow-down-sub-black.svg" width="10px" style="margin-right: 27px" />
      </div>
      <Map v-if="isOpenMap" :currentCam="currentCam" :currentLog="currentLog" @cardinal-direction="degreeString = $event" style="height: 156px"></Map>
      <!-- will be delete -->
      <div v-if="isOpenMap" style="position: relative">
        <div class="text-map">
          <div class="text-map-container">
            <span class="direction">{{ degreeString }}</span> Direction may differ in the range of ±5˚.
          </div>
        </div>
      </div>
      <!-- Recent -->
      <div
        v-if="!isSiteSearchSelected && !isOpenSite && !isOpenCam"
        @click="onClickRecent"
        :class="{ 'log-accordion-header': true, open: isOpenRecent }"
      >
        <img v-if="$vuetify.theme.dark" src="@/assets/Icon ionic-ios-time-white.svg" class="img-recent" />
        <img v-else src="@/assets/Icon ionic-ios-time-black.svg" class="img-recent" />
        <div :class="{ 'text-log-header': true, active: isOpenRecent }" style="margin-left: 10px">Recent</div>
        <div class="spacer-1"></div>
        <img v-if="$vuetify.theme.dark && isOpenRecent" src="@/assets/Exit_Icon-mobile.svg" class="img-arrow" />
        <img v-else-if="$vuetify.theme.dark && !isOpenRecent" src="@/assets/Open_Icon-white.svg" class="img-arrow" />
        <img v-else-if="isOpenRecent" src="@/assets/Exit_Icon_Black-mobile.svg" class="img-arrow" />
        <img v-else src="@/assets/Open_Icon-black.svg" class="img-arrow" />
      </div>
      <div v-if="isOpenRecent" class="split-line type"></div>
      <AsideLogRecent v-if="isOpenRecent && !isSiteSearchSelected" :selectId="selectId" @click-log="onClickLog"></AsideLogRecent>
      <!-- History -->
      <div
        v-if="!isSiteSearchSelected && !isOpenRecent && !isOpenCam"
        @click="onClickSite"
        :class="{ 'log-accordion-header': true, open: isOpenSite }"
      >
        <img v-if="$vuetify.theme.dark" src="@/assets/Icon material-history-white.svg" class="img-recent" />
        <img v-else src="@/assets/Icon material-history-black.svg" class="img-recent" />
        <div :class="{ 'text-log-header': true, active: isOpenSite }" style="margin-left: 10px">History</div>
        <div class="spacer-1"></div>
        <img v-if="$vuetify.theme.dark && isOpenSite" src="@/assets/Exit_Icon-mobile.svg" class="img-arrow" />
        <img v-else-if="$vuetify.theme.dark && !isOpenSite" src="@/assets/Open_Icon-white.svg" class="img-arrow" />
        <img v-else-if="isOpenSite" src="@/assets/Exit_Icon_Black-mobile.svg" class="img-arrow" />
        <img v-else src="@/assets/Open_Icon-black.svg" class="img-arrow" />
      </div>
      <div v-if="isOpenSite" class="split-line type"></div>
      <AsideLogHistory
        v-if="isOpenSite && !isSiteSearchSelected"
        :selectSite="selectSite"
        :selectId="selectId"
        :tabIndex="tabIndex"
        :siteList="siteList"
        @click-log="onClickLog"
      ></AsideLogHistory>
      <!-- Site -->
      <div
        v-if="!isSiteSearchSelected && !isOpenRecent && !isOpenSite"
        @click="onClickCam"
        :class="{ 'log-accordion-header': true, open: isOpenCam }"
      >
        <img v-if="$vuetify.theme.dark" src="@/assets/Icon material-location-on-white.svg" class="img-site" />
        <img v-else src="@/assets/Icon material-location-on-black.svg" class="img-site" />
        <div :class="{ 'text-log-header': true, active: isOpenCam }" style="margin-left: 12px">Site</div>
        <div class="spacer-1"></div>
        <img v-if="$vuetify.theme.dark && isOpenCam" src="@/assets/Exit_Icon-mobile.svg" class="img-arrow" />
        <img v-else-if="$vuetify.theme.dark && !isOpenCam" src="@/assets/Open_Icon-white.svg" class="img-arrow" />
        <img v-else-if="isOpenCam" src="@/assets/Exit_Icon_Black-mobile.svg" class="img-arrow" />
        <img v-else src="@/assets/Open_Icon-black.svg" class="img-arrow" />
      </div>
      <div v-if="isOpenCam" class="split-line type"></div>
      <AsideLogCam v-if="isOpenCam && !isSiteSearchSelected" :camList="camList" :selectSite="selectSite" @cam="onClickCamImage"></AsideLogCam>
    </div>
    <div class="split-line"></div>
  </aside>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AsideLog from '@desktop/superadminOrWorker/alert/AsideLog'
import AsideDetailedInfo from '@mobile/alert/AsideDetailedInfo'
import AsideLogRecent from '@mobile/alert/AsideLogRecent'
import AsideLogHistory from '@mobile/alert/AsideLogHistory'
import AsideLogCam from '@mobile/alert/AsideLogCam'
import Util from '@/util'
import Map from '@/components/common/ArcGIS/MobileMap'

export default {
  // extends: AsideLog,
  components: { Map, AsideDetailedInfo, AsideLogRecent, AsideLogHistory, AsideLogCam },
  props: {
    isSiteSearchSelected: {
      type: Boolean,
      default: false,
    },
    currentCam: Object,
    currentLog: Object,
  },
  data() {
    return {
      isOpenDetailedInfo: false,
      isOpenCam: false,
      placeholderImg: require('@/assets/skeleton.jpg'),
      INTERVAL_SITE_IMAGE: 10000,
      isOpenSite: false,
      selectSite: -1,
      currentCamImagePath: '',
      selectId: -1,
      isOpenMap: false,
      openDatepicker: false,
      isOpenRecent: false,
    }
  },
  watch: {
    isSiteSearchSelected(newLog, preLog) {
      if (newLog) {
        this.isOpenDetailedInfo = true
        this.isOpenMap = true
      }
    },
  },
  computed: {
    ...mapState(['camMap', 'recentLogList', 'camList', 'logListv2']),
    siteList() {
      const camList = this.camList
      if (this.currentCam && this.currentCam.id) {
        const searchIndex = camList.findIndex((cam) => cam.id === this.currentCam.id)
        if (searchIndex > -1) {
          this.selectId = -1
          return [...camList]
        }
      }
      return camList
    },
    mapLoading() {
      if (this.selectedLog && this.selectedLog.direction === null && !this.isOpenCam) {
        return true
      }
      return false
    },
    degree() {
      if (this.currentCam) {
        return -1
      } else {
        return this.log && this.log.direction
      }
    },
    selectedLog() {
      let log
      if (this.currentLog) {
        log = this.recentLogList.find((log) => log.log_id === this.currentLog.log_id)
      }
      return log
    },
    log() {
      let log = null
      if (this.currentLog) {
        log = this.recentLogList.find((log) => log.log_id === this.currentLog.log_id)
        if (!log) {
          log = this.logListv2.find((log) => log.log_id === this.currentLog.log_id)
        } else if (this.currentLog.log_id === log.log_id) {
          log = this.currentLog
        }
      }
      return log
    },
    mapDisabled() {
      if (!this.isOpenCam) {
        return false
      } else if (!!this.selectedLog && (!this.selectedLog.direction || this.selectedLog.direction === -1)) {
        return false
      } else if (!!this.currentCam) {
        return false
      }
      return true
    },
    degreeString() {
      return Util.locaitonToDegree(this.degree)
    },
  },
  methods: {
    async onClickSite() {
      this.openDatepicker = false
      this.isOpenSite = !this.isOpenSite
      if (this.isOpenRecent) {
        this.isOpenRecent = !this.isOpenRecent
      }
      if (this.isOpenCam) {
        this.isOpenCam = !this.isOpenCam
      }
      this.$emit('select-tab', this.isOpenSite ? 'history' : '')
    },
    onClickMap() {
      // this.$log.debug('desktop#AsideLog#onClickMap mapLoading/mapDisabled', this.mapLoading, this.mapDisabled)
      if (!this.mapDisabled && !this.mapLoading) {
        this.openDatepicker = false
        this.isOpenMap = !this.isOpenMap
      }
    },
    onClickDetailedInfo() {
      // this.$log.debug('desktop#AsideLog#onClickDetailedInfo')
      this.openDatepicker = false
      this.isOpenDetailedInfo = !this.isOpenDetailedInfo
    },
    onClickCam() {
      this.isOpenCam = !this.isOpenCam
      if (this.isOpenRecent) {
        this.isOpenRecent = !this.isOpenRecent
      }
      if (this.isOpenSite) {
        this.isOpenSite = !this.isOpenSite
      }
      this.$emit('select-tab', this.isOpenCam ? 'cam' : '')
      if (this.isOpenCam) {
        let cam = this.currentCam
        if (this.currentLog) {
          cam = this.camMap[this.currentLog.camera_id]
        }
        this.onClickCamImage(cam)
      } else if (!this.isOpenCam && this.recentLogList && this.recentLogList.length) {
        this.onClickLog(this.recentLogList[0])
      }
    },
    onClickCamImage(cam) {
      this.$log.debug('AsideLog#onClickCamImage', this.selectSite, cam && cam.id)
      if (cam && cam.id) {
        this.$emit('select-log', cam)
        // 점점 점진적인 개발이 이루어 지면서 timing issues가 계속 발견되고 있어서
        // 한 번 전체적으로 refactoring이 필요할 듯 하나 언제 할 지...
        // 대대적인 UI 개편이 있을 경우에 해야 할 것 같음
        this.$nextTick(() => {
          this.selectSite = cam.id
          this.currentCamImagePath = Util.getImageUrl(cam.path, true)
          this.selectId = -1
        })
      }
    },
    onClickRecent() {
      this.selectSite = -1
      this.selectId = -1
      this.openDatepicker = false
      this.isOpenRecent = !this.isOpenRecent
      if (this.isOpenSite) {
        this.isOpenSite = !this.isOpenSite
      }
      if (this.isOpenCam) {
        this.isOpenCam = !this.isOpenCam
      }
      this.$emit('select-tab', this.isOpenRecent ? 'recent' : '')
      this.$emit('select-cam', null)
    },
    findFirstLog(siteId) {
      const site = this.siteList.find((site) => site.id === siteId)
      return site.logs[0]
    },
    onClickLog(log, noSelectId = false) {
      // aside쪽 점진적 개발에 따른 컬럼들간의 로직이 점점 복잡해지는 로직에 대해서 정리하고 단순화하도록할 refactoring이 필요함
      const selectedSite = log.camera_id || log.id
      // this.$log.debug('desktop#AsideLog#onClickLog', this.selectSite, selectedSite)
      if (log.log_id || selectedSite !== this.selectSite) {
        this.selectSite = log.camera_id || log.id
        if (log.log_id) {
          // this.$log.debug('desktop#AsideLog#onClickLog select-log')
          this.$emit('select-log', log)
        } else {
          // this.$log.debug('desktop#AsideLog#onClickLog select-log first of site', selectedSite)
          this.$emit('select-log', this.findFirstLog(selectedSite))
        }
        if (this.selectId == log.log_id || noSelectId) {
          // this.$log.debug('desktop#AsideLog#onClickLog selectId -1', noSelectId)
          if (!this.isOpenSite) {
            this.selectId = -1
            !noSelectId && this.$emit('select-tab', 'recent')
          }
        } else {
          this.selectId = log.log_id
          // this.$log.debug('desktop#AsideLog#onClickLog selectId', this.selectId)
        }
        if (!this.mapLoading && !this.mapDisabled) {
          this.isOpenMap = true
        }
        if (this.selectSite) {
          // this.$log.debug('desktop#AsideLog#onClickLog selectSite', this.selectSite)
          const cam = this.camMap[this.selectSite]
          if (cam) {
            this.$emit('select-cam', cam)
            this.currentCamImagePath = Util.getImageUrl(cam.path, true)
          }
        }
      } else {
        // this.$log.debug('desktop#AsideLog#onClickLog null recent first', this.recentLogList && this.recentLogList[0])
        if (this.recentLogList && this.recentLogList[0]) {
          // 일단, sync가 안맞아서 어떻게 해야 할지 고민하다가 callback으로 처리하는 방법을 사용함... sync를 어떻게 맞춰야 할지 잘 모르겠음
          this.recentLogList[0].callback = () => {
            this.$emit('select-cam', null)
            this.selectSite = -1
            this.selectId = -1
          }
          this.$emit('select-log', this.recentLogList[0])
        }
        this.$emit('select-cam', null)
        this.selectSite = -1
        this.selectId = -1
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.background {
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--v-background-base);
}

.text-map {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 6px;
  font-size: 10px;
  color: var(--v-textCount);

  .text-map-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    height: 20px;
    background-color: var(--v-backgroundMap-base);
    border-radius: 4px;
    padding: 0 5px;

    .direction {
      font-weight: 600;
      font-size: 11px;
      color: var(--v-backgroundMapDirection-base);
      margin-right: 5px;
      user-select: text;
    }
  }
}

.log-accordion-header {
  display: flex;
  align-items: center;
  flex: 0 0 48px;
  cursor: pointer;
  background-color: var(--v-backgroundAsideHeaderRecent-base);

  &.open {
    background-color: var(--v-backgroundAsideHeaderRecent-base);
  }

  &.disabled {
    background-color: var(--v-backgroundDisabledMap);
  }
}

.log-accordian-label {
  color: #363636;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  line-height: 30px;
}

.text-log-header {
  font-weight: 500;
  font-size: 13px;
  color: var(--v-textDefault);
  user-select: none;
  padding-top: 4px;

  &.active {
    color: var(--v-textDefault);
  }

  &.disabled {
    color: var(--v-textDisabled);
  }
}

.split-line {
  background-color: var(--v-borderLineBottom1-base);
  height: 1px;
  flex-shrink: 0;

  &.type {
    background-color: var(--v-borderLineBottom-base);
  }
}

.img-recent {
  width: 18px;
  margin-left: 26px;
}

.img-site {
  width: 12px;
  margin-left: 29px;
}

.img-arrow {
  margin-right: 26px;
}
</style>
