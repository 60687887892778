var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"10px","close-on-content-click":false},on:{"input":_vm.onChange},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$vuetify.theme.dark && _vm.disabled)?_c('img',{staticStyle:{"margin-left":"15px"},attrs:{"src":require("@/assets/Alert_Off_Button-disabled-dark.svg")}}):(_vm.disabled)?_c('img',{staticStyle:{"margin-left":"15px"},attrs:{"src":require("@/assets/Alert_Off_Button-disabled.svg")}}):(_vm.$vuetify.theme.dark)?_c('img',_vm._g(_vm._b({staticStyle:{"margin-left":"15px"},attrs:{"src":require("@/assets/Alert_Off_Button-dark.svg")}},'img',attrs,false),on)):_c('img',_vm._g(_vm._b({staticStyle:{"margin-left":"15px"},attrs:{"src":require("@/assets/Alert_Off_Button.svg")}},'img',attrs,false),on))]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"button-alert-off-menu"},[_c('div',{staticClass:"button-alert-off-title"},[_vm._v("Recent Cameras")]),_c('div',{staticStyle:{"max-height":"130px","overflow-y":"auto"}},_vm._l((_vm.recentList),function(recent){return _c('div',{key:recent.id},[_c('ButtonAlertOffRecent',{attrs:{"item":recent},on:{"click":_vm.close},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_activator = ref.activator;
var attrs = ref_activator.attrs;
var on = ref_activator.on;
return [_c('div',_vm._b({class:{ 'button-alert-off-content': true, focus: _vm.menuId === recent.id },on:{"mouseover":function($event){return _vm.onMouseOver(recent, $event, on)},"click":function($event){return _vm.onClick($event, on)}}},'div',attrs,false),[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(recent.name))]),_c('v-spacer'),(_vm.$vuetify.theme.dark)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-next-white1.svg")}}):(_vm.menuId === recent.id)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-next-white.svg")}}):_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-next-black.svg")}})],1)]}}],null,true)})],1)}),0),_c('div',{staticClass:"split-line"}),_c('div',{staticClass:"button-alert-off-title"},[_vm._v("Paused Alerts")]),_c('div',{staticStyle:{"max-height":"130px","overflow-y":"auto"}},_vm._l((_vm.pausedList),function(paused){return _c('div',{key:paused.id},[_c('ButtonAlertOffPaused',{attrs:{"item":paused},on:{"click":_vm.close},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_activator = ref.activator;
var attrs = ref_activator.attrs;
var on = ref_activator.on;
return [_c('div',_vm._b({class:{ 'button-alert-off-content': true, focus: _vm.menuId === paused.id },on:{"mouseover":function($event){return _vm.onMouseOver(paused, $event, on)},"click":function($event){return _vm.onClick($event, on)}}},'div',attrs,false),[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(paused.name))]),_c('v-spacer'),(_vm.$vuetify.theme.dark)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-next-white1.svg")}}):(_vm.menuId === paused.id)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-next-white.svg")}}):_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-next-black.svg")}})],1)]}}],null,true)})],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }