var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"log-accordion-content"},[_c('div',{staticClass:"log-accordion-content wrapper"},[_c('div',{staticClass:"log-accordion-header"},[_c('div',{staticClass:"log-accordian-label"},[_vm._v("Date")]),_c('div',{staticClass:"log-accordian-calendar-container",on:{"click":function($event){_vm.openDatepicker = !_vm.openDatepicker}}},[_c('div',[_vm._v(_vm._s(_vm.dates))])]),_c('div',{staticClass:"log-accordian-calendar-image",on:{"click":function($event){_vm.openDatepicker = !_vm.openDatepicker}}},[_c('img',{attrs:{"src":require("@/assets/Icon material-date-range.svg"),"width":"12px"}})])]),(_vm.openDatepicker)?_c('Calendar',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"min-date":_vm.calendarMinDate},on:{"update":_vm.onUpdateDate}}):_vm._e(),_c('div',{staticClass:"log-accordion-content"},[_c('div',{staticClass:"log-accordion-content wrapper-site"},_vm._l((_vm.sortedDateList),function(date){return _c('div',{key:date.name},[(date.logs.length)?_c('div',{class:{
              'log-accordion-content': true,
              site: true,
              focus: _vm.selectSiteDate === date.name,
            },on:{"click":function($event){return _vm.onClickLog(date)}}},[_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(date.name)),_c('span',{staticClass:"log-count"},[_vm._v(_vm._s(date.logs.length))])]),_c('div',{staticClass:"spacer-1"}),(!_vm.$vuetify.theme.dark && _vm.selectSiteDate !== date.name)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-down-sub-black.svg")}}):_vm._e(),(_vm.$vuetify.theme.dark && _vm.selectSiteDate !== date.name)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-down-dark.svg")}}):_vm._e(),(!_vm.$vuetify.theme.dark && _vm.selectSiteDate == date.name)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-up-sub-black.svg")}}):_vm._e(),(_vm.$vuetify.theme.dark && _vm.selectSiteDate == date.name)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-up-sub.svg")}}):_vm._e()]):_vm._e(),(_vm.selectSiteDate === date.name)?_c('div',{staticClass:"log-accordion-content"},_vm._l((date.logs),function(log){return _c('div',{key:log.log_id,class:{
                'log-accordion-content': true,
                text: true,
                sub: true,
                focus: _vm.selectId === log.log_id,
              },on:{"click":function($event){return _vm.onClickLog(log)}}},[_vm._v(" "+_vm._s(_vm.displayLogTime(log))+" "),_c('div',{staticClass:"spacer-1"}),_c('div',{class:{
                  'icon-label': true,
                  fire: log.event_type_id === _vm.eventTypeList[2].id,
                  'non-fire': log.event_type_id === _vm.eventTypeList[3].id,
                  unknown: log.event_type_id === _vm.eventTypeList[4].id,
                  planned: log.event_type_id === _vm.eventTypeList[5].id,
                }})])}),0):_vm._e()])}),0)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }