<template>
  <div @click="onClickTheme" class="theme-container">
    <span style="margin-right: 10px">Light</span>
    <img v-if="!$vuetify.theme.dark" src="@/assets/DarkmodeToggle_Light.svg" />
    <img v-else src="@/assets/DarkmodeToggle_Dark.svg" />
    <span style="margin-left: 10px">Dark</span>
  </div>
</template>

<script>
import NativeService from '@/native-service'
import Constants from '@/constants'

export default {
  methods: {
    onClickTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      NativeService.setData(Constants.THEME_DARK, this.$vuetify.theme.dark)
    },
  },
}
</script>

<style lang="scss" scoped>
.theme-container {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-right: 30px;
  cursor: pointer;
  color: var(--v-textDefault);
}
</style>
