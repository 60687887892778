<template>
  <div @click="onClickPaly()" :class="{ 'butotn-play-circle': true, disabled: disabled }">
    <!-- play -->
    <div v-if="isPlay" class="icon-container">
      <img v-if="!disabled && $vuetify.theme.dark" src="@/assets/play.svg" width="12px" style="margin-left: 2px" />
      <img v-else-if="disabled && $vuetify.theme.dark" src="@/assets/play.svg" width="12px" style="margin-left: 2px" />
      <img v-else src="@/assets/Play_Filled_16px_Icon.svg" width="12px" style="margin-left: 2px" />
    </div>
    <!-- resume -->
    <div v-if="isResume" class="icon-container">
      <img v-if="$vuetify.theme.dark" src="@/assets/play.svg" width="12px" style="margin-left: 2px" />
      <img v-else src="@/assets/Play_Filled_16px_Icon.svg" width="12px" style="margin-left: 2px" />
    </div>
    <!-- playback -->
    <div v-if="isPlayback" class="icon-container">
      <img v-if="$vuetify.theme.dark" src="@/assets/Mobile_Playback_Button.svg" width="14px" />
      <img v-else src="@/assets/Mobile_Playback_Filled_Button.svg" width="14px" />
    </div>
    <!-- pause -->
    <div v-if="isPause" class="icon-container">
      <img v-if="$vuetify.theme.dark" src="@/assets/pause.svg" width="12px" />
      <img v-else src="@/assets/Pause_Filled_16px_Icon.svg" width="12px" />
    </div>
  </div>
</template>

<script>
import ButtonPlay from './ButtonPlay'
import Theme from '@/mixin/theme.mixin'

export default {
  extends: ButtonPlay,
  mixins: [Theme],
}
</script>

<style lang="scss" scoped>
.butotn-play-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
  width: 24px;
  height: 24px;
  // background-color: #3d51fb;
  border-radius: 24px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffffde;

  &.disabled {
    background-color: #cbcbcb;
    color: #00000042;
  }
}

.icon-container {
  display: flex;
  align-items: center;
}
</style>
