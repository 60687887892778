<template>
  <v-menu v-model="open" @input="onChange" offset-y nudge-bottom="10px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }" class="button-alert-off">
      <img v-if="$vuetify.theme.dark && disabled" src="@/assets/Alert_Off_Button-disabled-dark.svg" style="margin-left: 15px" />
      <img v-else-if="disabled" src="@/assets/Alert_Off_Button-disabled.svg" style="margin-left: 15px" />
      <img v-else-if="$vuetify.theme.dark" v-bind="attrs" v-on="on" src="@/assets/Alert_Off_Button-dark.svg" style="margin-left: 15px" />
      <img v-else v-bind="attrs" v-on="on" src="@/assets/Alert_Off_Button.svg" style="margin-left: 15px" />
    </template>
    <div class="button-alert-off-menu">
      <div class="button-alert-off-title">Recent Cameras</div>
      <div style="max-height: 130px; overflow-y: auto">
        <div v-for="recent in recentList" :key="recent.id">
          <ButtonAlertOffRecent :item="recent" @click="close">
            <template v-slot="{ activator: { attrs, on } }">
              <div
                v-bind="attrs"
                @mouseover="onMouseOver(recent, $event, on)"
                @click="onClick($event, on)"
                :class="{ 'button-alert-off-content': true, focus: menuId === recent.id }"
              >
                <div class="text-truncate">{{ recent.name }}</div>
                <v-spacer></v-spacer>
                <img v-if="$vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-next-white1.svg" />
                <img v-else-if="menuId === recent.id" src="@/assets/Icon ionic-ios-arrow-next-white.svg" />
                <img v-else src="@/assets/Icon ionic-ios-arrow-next-black.svg" />
              </div>
            </template>
          </ButtonAlertOffRecent>
        </div>
      </div>
      <div class="split-line"></div>
      <div class="button-alert-off-title">Paused Alerts</div>
      <div style="max-height: 130px; overflow-y: auto">
        <div v-for="paused in pausedList" :key="paused.id">
          <ButtonAlertOffPaused :item="paused" @click="close">
            <template v-slot="{ activator: { attrs, on } }">
              <div
                v-bind="attrs"
                @mouseover="onMouseOver(paused, $event, on)"
                @click="onClick($event, on)"
                :class="{ 'button-alert-off-content': true, focus: menuId === paused.id }"
              >
                <div class="text-truncate">{{ paused.name }}</div>
                <v-spacer></v-spacer>
                <img v-if="$vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-next-white1.svg" />
                <img v-else-if="menuId === paused.id" src="@/assets/Icon ionic-ios-arrow-next-white.svg" />
                <img v-else src="@/assets/Icon ionic-ios-arrow-next-black.svg" />
              </div>
            </template>
          </ButtonAlertOffPaused>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import ButtonAlertOffRecent from './ButtonAlertOffRecent'
import ButtonAlertOffPaused from './ButtonAlertOffPaused'
import moment from 'moment'

/**
 * 해당 카메라에서 발생한 마지막 로그를 기준으로 72시간 내 까지만 알림을 끌 수 있음. 72시간을 초과하는 옵션(3 hours, 6hours, Tomorrow)은 숨김 처리.
 * Custom 옵션에서 마지막 로그의 발생 시각을 30분 00초 기준으로 설정. ex) 6:29 로그 -> 6시까지 설정 가능 6:30 -> 7시까지 설정 가능
 */
export default {
  components: { ButtonAlertOffRecent, ButtonAlertOffPaused },
  props: {
    disabled: Boolean,
  },
  data() {
    return {
      open: false,
      menuId: -1,
      prevMenuOn: null,
    }
  },
  computed: {
    ...mapState(['camMap', 'recentLogList']),
    list() {
      const cameraIdSet = new Set()
      this.recentLogList.forEach((log) => cameraIdSet.add(log.camera_id))
      const cameraList = [...cameraIdSet].map((id) => this.camMap[id]).filter((cam) => !!cam)
      return cameraList
    },
    recentList() {
      return this.list.filter((cam) => !cam.noti_pause_end || moment().isAfter(cam.noti_pause_end))
    },
    pausedList() {
      return this.list.filter((cam) => !!cam.noti_pause_end && moment().isBefore(cam.noti_pause_end))
    },
  },
  methods: {
    close() {
      this.open = false
      this.menuId = -1
      if (this.prevMenuOn) {
        this.prevMenuOn.click(new MouseEvent('click'))
        this.prevMenuOn = null
      }
    },
    onChange(open) {
      if (!open) {
        this.close()
      }
    },
    onMouseOver(item, e, on) {
      if (this.prevMenuOn) {
        this.prevMenuOn.click(new MouseEvent('click'))
        this.prevMenuOn = null
      }
      this.menuId = item.id
      if (on) {
        this.prevMenuOn = on
        on.click(new MouseEvent('click'))
      }
    },
    onClick(e, on) {
      if (this.prevMenuOn) {
        this.prevMenuOn.click(new MouseEvent('click'))
        this.prevMenuOn = null
      }
      this.prevMenuOn = on
      setTimeout(() => {
        on.click(new MouseEvent('click'))
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.button-alert-off {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.button-alert-off-menu {
  display: flex;
  flex-direction: column;
  width: 220px;
  font-size: 13px;
  font-weight: 400;
  color: var(--v-textDefault);
  background-color: var(--v-backgroundAsideTooltip-base);
  padding-bottom: 11px;

  img {
    height: 10px;
  }
}

.split-line {
  height: 1px;
  margin-top: 11px;
  background-color: var(--v-borderAlertOff-base);
}

.button-alert-off-title {
  font-size: 14px;
  font-weight: 500;
  margin: 15px 20px 6px;
}

.button-alert-off-content {
  display: flex;
  align-items: center;
  height: 26px;
  padding: 0 17px 0 20px;

  &.focus {
    color: var(--v-textCount);
    background-color: var(--v-backgroundAlertOff-base);
  }
}
</style>
