import { render, staticRenderFns } from "./ButtonAlertOffRecent.vue?vue&type=template&id=8c2737c2&scoped=true&"
import script from "./ButtonAlertOffRecent.vue?vue&type=script&lang=js&"
export * from "./ButtonAlertOffRecent.vue?vue&type=script&lang=js&"
import style0 from "./ButtonAlertOffRecent.vue?vue&type=style&index=0&id=8c2737c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c2737c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VMenu})
