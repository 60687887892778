<template>
  <div class="root-container">
    <v-dialog :value="isFullscreen" fullscreen :content-class="'fullscreen-dialog-dev'">
      <div id="live-image-fullscreen"></div>
      <MobileFullscreenCover
        class="button-close-fullscreen"
        :isFullscreen="isFullscreen"
        :isMobileLiveCamImage="isMobileLiveCamImage"
        @clickToFullscreenPlayer="onFullscreenToggle"
        :play="play"
        @onPlay="onPlay()"
      ></MobileFullscreenCover>
    </v-dialog>
    <div v-show="!selectedTermsOfUses && !selectedPrivacyPolicy" class="monitoring-container">
      <main id="player-container" ref="playerContainer">
        <ImagePlayer
          ref="mobilePlayer"
          :currentLog="currentLog"
          :currentCamImageSrc="currentCamImageSrc"
          :frame="currentFrameIndex"
          :hasAfterImages="afterImagesExist"
          :monitor="true"
          :mobile="true"
          @stop="onStopPlayer"
          @loaded="
            loadedImages = $event
            loading = false
          "
          @frame="onFrame"
          :openSidebar="openAside"
          :play="play"
          :isFullscreen="isFullscreen"
          :isMobileLiveCamImage="isMobileLiveCamImage"
          class="player"
          @onClickFullScreenPlayer="onClickFullScreenPlayer"
          @automaticPlay="automaticPlayInFullscreen"
          @pauseForPlayMode="pauseForPlayMode"
        ></ImagePlayer>
        <div class="toolbar" :class="{ live: isMobileLiveCamImage }">
          <!--캠을 눌렀을때 삭제할 수 있어야 합니다. -->
          <div class="toolbar-background"></div>
          <div class="toolbar-image-player-bar-container">
            <div class="button-play-circle-container">
              <ButtonPlayCircle
                @play="onPlay()"
                :didPlay="didPlay"
                :playing="playing"
                :play="play"
                :loading="loading"
                :disabled="!currentLog || loading"
              ></ButtonPlayCircle>
            </div>
            <div class="image-player-bar-container">
              <v-slider
                v-model="currentFrameIndex"
                :max="currentTotalImagesLength"
                thumb-color="#FF5B54"
                :track-color="$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.26)'"
                track-fill-color="#FF5B54"
                hide-details
                @change="onChangeSlider"
                @mousedown="onChangeSlider"
                :style="getSliderStyle"
                readonly
              ></v-slider>
              <div v-if="loadedImages.length && !afterImagesExist" :class="`slider-area-disabled ${themeName}`" :style="getDisabledSliderAreaStyle">
                <div :class="`slider-area-disabled-line ${themeName}`"></div>
              </div>
              <div v-if="loadedImages.length" class="caret-container" :style="getCaretAlignment">
                <img
                  v-show="loadedImages.length && $vuetify.theme.dark"
                  src="@/assets/Caret-Up_Filled_16px_Icon.svg"
                  width="14px"
                  class="caret-up-align"
                />
                <img
                  v-show="loadedImages.length && $vuetify.theme.dark"
                  src="@/assets/Caret-Down_Filled_16px_Icon.svg"
                  width="14px"
                  class="caret-down-align"
                />
                <img
                  v-show="loadedImages.length && !$vuetify.theme.dark"
                  src="@/assets/Caret-Up_Filled_12px_Icon_Light_Mode.svg"
                  width="14px"
                  class="caret-up-align"
                />
                <img
                  v-show="loadedImages.length && !$vuetify.theme.dark"
                  src="@/assets/Caret-Down_Filled_12px_Icon_Light_Mode.svg"
                  width="14px"
                  class="caret-down-align"
                  :style="getCaretStyle"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <div class="split-line"></div>
      <div class="toolbar">
        <div class="toolbar-background"></div>
        <div class="toolbar-title-container">
          <div :class="{ 'toolbar-title': true, 'text-truncate': true, live: !currentLog }">
            <div @click="onClickCamTitle" class="d-flex align-center">{{ currentCamText }}</div>
            <img v-if="alertOff" src="@/assets/Alert_Off_SkyBlue_Icon.svg" width="12px" style="margin-left: 4px" />
          </div>
          <div class="toolbar-subtitle">
            <div v-if="currentLogText" class="text-truncate" style="margin-right: 5px; user-select: text">{{ currentLogText }}</div>
            <div
              v-if="currentCam || currentLog"
              :class="{
                label: true,
                default: !currentCam && !!currentLog,
                unselected: !currentCam && currentLog && currentLog.event_type_id === eventTypeList[1].id,
                fire: !currentCam && currentLog && currentLog.event_type_id === eventTypeList[2].id,
                'non-fire': !currentCam && currentLog && currentLog.event_type_id === eventTypeList[3].id,
                unknown: !currentCam && currentLog && currentLog.event_type_id === eventTypeList[4].id,
                planned: !currentCam && currentLog && currentLog.event_type_id === eventTypeList[5].id,
              }"
            ></div>
            <div v-if="currentCam" class="toolbar-subtitle live">Live View</div>
          </div>
        </div>
        <img v-if="providerLogoUrl" :src="providerLogoUrl" width="83px" style="margin-right: 11px; position: relative" />
        <LinkShare v-if="!currentCam" @click="onClickLinkShare" :disabled="user.isWorker" style="margin-right: 16px"></LinkShare>
      </div>

      <AsideLog
        ref="asideLog"
        :currentCam="currentCam"
        :currentLog="currentLog"
        :isSiteSearchSelected="isSiteSearchSelected"
        @select-cam="historyCam = $event"
        @select-log="onClickCamAndLog"
        @select-tab="onSelectTab"
      ></AsideLog>
    </div>
    <div v-if="selectedTermsOfUses || selectedPrivacyPolicy" class="monitoring-container">
      <TermsOfUse v-if="selectedTermsOfUses"></TermsOfUse>
      <PrivacyPolicy v-if="selectedPrivacyPolicy"></PrivacyPolicy>
    </div>
    <v-spacer></v-spacer>
    <Footer v-if="!user.isWorker && showFooter" @terms="selectTab = 'terms'" @policy="selectTab = 'policy'"></Footer>
    <Toolbar :tab="selectTab" @select="onClickSite" @home="onClickHome"></Toolbar>
    <SnackBar v-model="clipboard" :color="clipboardColor" :message="clipboardMsg" :mobile="isMobile"></SnackBar>
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'
import Toolbar from '@mobile/alert/Toolbar'
import Alert from './AlertOrigin'
import AsideLog from '@mobile/alert/AsideLog'
import LinkShare from '@mobile/alert/LinkShare'
import Footer from '@mobile/alert/Footer'
import ButtonPlayCircle from '@mobile/alert/ButtonPlayCircle'
import MobileFullscreenCover from '@mobile/MobileFullscreenCover'
import TermsOfUse from '@mobile/alert/TermsOfUse'
import PrivacyPolicy from '@mobile/alert/PrivacyPolicy'
import SnackBar from '@common/SnackBar'
import Util from '@/util'

export default {
  extends: Alert,
  mixins: [Theme],
  components: {
    Toolbar,
    AsideLog,
    LinkShare,
    SnackBar,
    Footer,
    TermsOfUse,
    PrivacyPolicy,
    ButtonPlayCircle,
    MobileFullscreenCover,
  },
  data() {
    return {
      isMobileLiveCamImage: false,
      showFooter: false,
      currentFrameIndex: 0,
      isFullscreen: false,
      fullscreenCheckInterval: null,
      fullscreenButtonInterval: null,
      isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
      ],
    }
  },
  watch: {
    selectTab() {
      this.checkShowFooter()
    },
  },
  async created() {
    this.ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    await this.initialize()
  },
  mounted() {
    this.checkShowFooter()
    window.addEventListener('orientationchange', this.detectOrientationChange)
  },
  destroyed() {
    clearInterval(this.fullscreenButtonInterval)
    window.removeEventListener('orientationchange', this.detectOrientationChange)
  },
  computed: {
    providerLogoUrl() {
      if (this.currentCam) {
        if (this.currentCam.provider_dark_logo_path || this.currentCam.provider_light_logo_path) {
          const currentLogoUrl = this.themeName === 'dark' ? this.currentCam.provider_dark_logo_path : this.currentCam.provider_light_logo_path

          return Util.getImageUrl(currentLogoUrl)
        }
      } else if (this.currentLog) {
        const cameraInfo = this.camMap[this.currentLog.camera_id]

        if (cameraInfo.provider_dark_logo_path || cameraInfo.provider_light_logo_path) {
          const currentLogoUrl = this.themeName === 'dark' ? cameraInfo.provider_dark_logo_path : cameraInfo.provider_light_logo_path

          return Util.getImageUrl(currentLogoUrl)
        }
      }

      return null
    },
    beforeImagePathsListLength() {
      return !!this.currentLog ? this.currentLog.before_image_paths.length : 0
    },
    afterImagesExist() {
      return !!this.currentLog && !!this.currentLog.after_image_paths && this.currentLog.after_image_paths.length
    },
    currentTotalImagesLength() {
      if (!this.currentLog) {
        return 0
      }
      if (!this.afterImagesExist) {
        return this.currentLog.before_image_paths.length + 1
      }
      return this.currentLog.before_image_paths.length + this.currentLog.after_image_paths.length + 1
    },

    getSliderStyle() {
      let style = 'z-index: 2; '
      const afterImagePathsLength = !!this.currentLog && !!this.currentLog.after_image_paths ? this.currentLog.after_image_paths.length : 0

      if (this.loadedImages.length && !afterImagePathsLength) {
        style = style.concat(`max-width: 70%`)
      }
      return style
    },
    getDisabledSliderAreaStyle() {
      if (!this.currentLog) {
        return 'width: 0%'
      }
      const afterImagePathsLength = !!this.currentLog && !!this.currentLog.after_image_paths ? this.currentLog.after_image_paths.length : 0
      const BEFORE_IMAGES_SIZE = this.currentLog.before_image_paths.length
      const TOTAL_IMAGES_SIZE = this.currentLog.before_image_paths.length + afterImagePathsLength + 1
      if (BEFORE_IMAGES_SIZE === TOTAL_IMAGES_SIZE - 1) return `width: 24.5%`
      const marginLeftPercentageValue = 100 - ((BEFORE_IMAGES_SIZE / TOTAL_IMAGES_SIZE) * 100).toFixed(2)
      return `width: calc(${marginLeftPercentageValue}% - 28px);`
    },
    getCaretAlignment() {
      const afterImagePathsLength = !!this.currentLog && !!this.currentLog.after_image_paths ? this.currentLog.after_image_paths.length : 0
      if (!this.currentLog) {
        return 'width: 0%'
      }
      const BEFORE_IMAGES_SIZE = this.currentLog.before_image_paths.length
      const TOTAL_IMAGES_SIZE = this.currentLog.before_image_paths.length + afterImagePathsLength + 1
      if (BEFORE_IMAGES_SIZE === TOTAL_IMAGES_SIZE - 1) return `width: 55%;`

      const marginLeftPercentageValue = ((BEFORE_IMAGES_SIZE / TOTAL_IMAGES_SIZE) * 100).toFixed(2)
      return `width: calc(${marginLeftPercentageValue}% - 45px);`
    },
  },
  methods: {
    onStopPlayer() {
      this.$log.debug('Admin#onStopPlayer', this.playLogId, this.currentLog?.log_id)
      this.play = 'stop'
      if (this.currentLog && this.playLogId === this.currentLog.log_id) {
        this.didPlay = true
      } else {
        this.didPlay = false
      }
      this.playing = false
    },
    pauseForPlayMode() {
      const mobilePlayer = this.$refs.mobilePlayer instanceof Array ? this.$refs.mobilePlayer[0] : this.$refs.mobilePlayer
      mobilePlayer.canvas.pause(this.currentFrameIndex)
    },
    detectOrientationChange() {
      this.$log.debug('Alert$mounted$orientaion')
      this.play = 'pause'
    },
    automaticPlayInFullscreen() {
      if (this.isFullscreen && !this.isMobileLiveCamImage) {
        this.onPlay()
      }
    },
    onClickFullScreenPlayer() {
      this.play = 'pause'
      this.isFullscreen = true

      this.$nextTick(function () {
        const canvasDom = document.getElementsByClassName('player')[0]

        const fullscreenWrapper = document.getElementById('live-image-fullscreen')

        fullscreenWrapper.append(canvasDom)
      })
      this.$log.debug('풀화면', this.currentLog, this.isFullscreen, this.isMobileLiveCamImage)
    },

    onFullscreenToggle(evt) {
      const canvasDom = document.getElementsByClassName('player')[0]
      const main = document.getElementsByClassName('monitoring-container')[0]
      main.insertBefore(canvasDom, main.childNodes[0])
      // if (evt === 'exitFullscreen' || (this.isIOS && evt === 'requestFullscreen') || (this.isMobileLiveCamImage && this.isFullscreen)) {
      // this.isFullscreen = false 테스트를 위해 임시로 아래로 빼놓았습니다.
      // }
      this.isFullscreen = false
      this.loading = false
    },
    onChangeSlider(val) {
      this.$log.debug('onChangeSlider', val)
      if (this.play === 'play') {
        this.play = 'pause'
      }
      // this.currentFrameIndex = val
    },
    onFrame(frameIndex) {
      this.$log.debug('Alert#onFrame', this.play, frameIndex, this.currentFrameIndex, this.beforeImagePathsListLength)
      if (!this.afterImagesExist && frameIndex >= this.beforeImagePathsListLength) {
        this.currentFrameIndex = frameIndex
        // this.play = 'stop'
        return
      } else if (this.play === 'play' && frameIndex === this.beforeImagePathsListLength && frameIndex !== this.currentFrameIndex) {
        this.currentFrameIndex = frameIndex
        this.$log.debug('should pause on detect', this.play, this.beforeImagePathsListLength)
        this.play = 'pause'
        return
      }
      this.currentFrameIndex = frameIndex
    },
    async onClickCamImage(cam, ignoreScroll) {
      this.$log.debug('Alert#onClickCamImage', cam)
      this.play = 'stop'
      this.currentCam = cam
      this.isMobileLiveCamImage = true
      if (this.currentCam && this.currentCam.camera_id) {
        this.setGotoLink(this.currentCam)
        const path = this.camMap[this.currentCam.camera_id].path
        this.currentCamImageSrc = Util.getImageUrl(path, true)
        // this.currentLog = null
        this.loading = false
        this.loadedImages = []
        this.currentCamText = cam.name
        this.currentLogText = ''
        !ignoreScroll && Util.moveScrollToTop()
      } else if (this.currentCam && this.currentCam.id) {
        this.setGotoLink(this.currentCam)
        const path = this.camMap[this.currentCam.id].path
        this.currentCamImageSrc = Util.getImageUrl(path, true)
        // this.currentLog = null
        this.loading = false
        this.loadedImages = []
        this.currentCamText = cam.name
        this.currentLogText = ''
        !ignoreScroll && Util.moveScrollToTop()
      }
    },
    onClickCamAndLog(value) {
      this.$log.debug('Alert#onClickCamAndLog', value.camera_name)
      if (value.camera_name) {
        // log
        this.isMobileLiveCamImage = false
        this.onClickLog(value)
      } else {
        // cam
        this.isMobileLiveCamImage = true
        this.onClickCamImage(value)
      }
    },
    checkShowFooter() {
      if (this.$refs.asideLog) {
        this.showFooter =
          !this.$refs.asideLog.isOpenRecent &&
          !this.$refs.asideLog.isOpenSite &&
          !this.$refs.asideLog.isOpenCam &&
          !this.selectedTermsOfUses &&
          !this.selectedPrivacyPolicy
      } else {
        this.showFooter = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.root-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--v-background-base);
  height: 100%;
  position: relative;
}

.monitoring-container {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

main {
  display: flex;
  flex-direction: column;
}

.player {
  position: relative;
  flex: 0 0 211px;
  background-color: #000;
}

.player-control {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 211px;

  &.bg {
    width: 100%;
    background-color: black;
    opacity: 0.5;
  }

  img {
    position: relative;
  }
}

.toolbar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &.live {
    display: none;
  }

  .label {
    min-width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #ffc32b;

    &.default {
      min-width: 8px;
      height: 8px;
      border-radius: 8px;
    }

    &.unselected {
      background: none;
      border: 1px solid var(--v-borderLabel-base);
    }

    &.fire {
      background-color: var(--v-backgroundFire-base);
    }

    &.non-fire {
      background-color: var(--v-backgroundNoneFire-base);
    }

    &.unknown {
      background-color: var(--v-backgroundUnknown-base);
    }

    &.planned {
      background-color: var(--f-supplementary-purple);
    }
  }
}

.toolbar-background {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--v-background-base);
  width: 100%;
  height: 50px;
}

.toolbar-image-player-bar-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  flex-grow: 1;
  padding-right: 16px;
}

.slider-area-disabled {
  height: 2px;
  width: 30%;
  background-color: transparent;
  z-index: 1;
  position: absolute;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.dark {
    // background-color: transparent;
  }

  .slider-area-disabled-line {
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.12);
    z-index: 1;

    &.dark {
      background-color: rgba(255, 255, 255, 0.38);
      opacity: 1;
    }
  }
}

.button-play-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}

.image-player-bar-container {
  height: 50px;
  width: calc(100% - 50px);
  display: flex;
  // justify-content: center;
  align-items: center;
}

.toolbar-title-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  flex-grow: 1;
  width: 0;
}

.toolbar-title {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: var(--v-textDefault);
  margin: 0px 20px 0px;

  &.live {
    margin-top: 7px;
  }
}

.toolbar-subtitle {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 400;
  color: var(--v-textDefault50);
  margin: 2px 20px 0px;

  &.live {
    user-select: none;
    font-weight: 400;
    margin: 0 5px;
    color: var(--v-textToolbarLiveview);
  }
}

.split-line {
  height: 1px;
  width: 100%;
  background-color: var(--v-borderLineBottom1-base);
  flex-shrink: 0;
}

.caret-container {
  height: 40px;
  position: absolute;
  left: 50px;
  z-index: 0;
}

.caret-up-align {
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-right: -7px;
  z-index: 1;
}

.caret-down-align {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: -7px;
  z-index: 1;
}

#live-image-fullscreen {
  width: 100%;
  height: 100%;
  background-color: black;
}

.button-close-fullscreen {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0px;
  background-color: #00000061;
}

@media (orientation: portrait) {
  #live-image-fullscreen {
    transform: rotate(90deg) scale(1.4);
  }
  .button-close-fullscreen {
    left: 0px;
  }
}
</style>
