<template>
  <v-menu v-model="open" @input="onChange" max-width="200px" nudge-right="-205px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind:activator="{ on, attrs }"></slot>
    </template>
    <div class="popup-disturb">
      <div class="popup-disturb-title">Do not disturb...</div>
      <div v-if="show3Hours" :class="{ 'popup-disturb-content': true, focus: menuId === 0 }" @mouseover="onMouseOver(0)" @click="onClick(0)">
        For 3 hours
      </div>
      <div v-if="show6Hours" :class="{ 'popup-disturb-content': true, focus: menuId === 1 }" @mouseover="onMouseOver(1)" @click="onClick(1)">
        For 6 hours
      </div>
      <div v-if="show24Hours" :class="{ 'popup-disturb-content': true, focus: menuId === 2 }" @mouseover="onMouseOver(2)" @click="onClick(2)">
        For Today
      </div>
      <div :class="{ 'popup-disturb-content': true, focus: menuId === 3 }" @mouseover="onMouseOver(3)" @click="onClick(3)">Custom...</div>
    </div>
    <PauseAlertDialog v-model="dialog" :item="item"></PauseAlertDialog>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PauseAlertDialog from '@desktop/dialog/PauseAlertDialog'
import moment from 'moment'
import { alert } from '@/api'
import Util from '@/util'

export default {
  components: { PauseAlertDialog },
  /**
   * emit events
   * click
   */
  props: {
    item: Object,
  },
  data() {
    return {
      open: false,
      menuId: -1,
      dialog: false,
    }
  },
  computed: {
    ...mapState(['recentLogList']),
    show3Hours() {
      return moment().add(3, 'h').isBefore(this.after72hours)
    },
    show6Hours() {
      return moment().add(6, 'h').isBefore(this.after72hours)
    },
    show24Hours() {
      return moment().add(24, 'h').isBefore(this.after72hours)
    },
    after72hours() {
      return moment(this.lastestLog.created_at).add(72, 'h').utc().format()
    },
    lastestLog() {
      return this.recentLogList.find((log) => log.camera_id === this.item.id)
    },
  },
  methods: {
    ...mapActions(['getCamList']),
    close() {
      this.open = false
      this.menuId = -1
    },
    onChange(open) {
      if (!open) {
        this.close()
      }
    },
    onMouseOver(index) {
      this.menuId = index
    },
    async onClick(index) {
      this.close()
      this.$emit('click')
      if (index === 0 || index === 1 || index === 2) {
        let end_time
        if (index === 0) {
          end_time = moment().add(3, 'h')
        } else if (index === 1) {
          end_time = moment().add(6, 'h')
        } else if (index === 2) {
          end_time = moment().endOf('day')
        }
        try {
          await alert.updatePauseAlert({
            camera_id: this.item.id,
            start_time: moment(this.lastestLog.created_at).format(),
            end_time,
          })
          await this.getCamList({ justCamList: true })
        } catch (e) {
          this.$log.error('updatePauseAlert error')
        }
      } else if (index === 3) {
        this.dialog = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-disturb {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  color: var(--v-textDefault);
  background-color: var(--v-backgroundAsideTooltip-base);
}

.popup-disturb-title {
  font-size: 11px;
  font-weight: 500;
  margin: 15px 20px 6px;
}

.popup-disturb-content {
  display: flex;
  align-items: center;
  height: 26px;
  padding-left: 25px;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;

  &.focus {
    color: var(--v-textCount);
    background-color: var(--v-backgroundAlertOff-base);
  }
}
</style>
