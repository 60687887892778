<template>
  <div @click="onClickPaly()" :class="{ 'toolbar-button': true, disabled: disabled }">
    <!-- play -->
    <div v-if="isPlay">
      <img v-if="!disabled" src="@/assets/play.svg" class="icon" />
      <img v-else src="@/assets/play_disabled.svg" class="icon" />
      <span>Play</span>
    </div>
    <!-- resume -->
    <div v-if="isResume">
      <img src="@/assets/play.svg" class="icon" />
      <span>Resume</span>
    </div>
    <!-- playback -->
    <div v-if="isPlayback">
      <img src="@/assets/playback.svg" class="icon" />
      <span>Playback</span>
    </div>
    <!-- pause -->
    <div v-if="isPause">
      <img src="@/assets/pause.svg" class="icon" />
      <span>Pause</span>
    </div>
  </div>
</template>

<script>
import ButtonPlay from '@desktop/superadminOrWorker/alert/ButtonPlay'

export default {
  extends: ButtonPlay,
}
</script>

<style lang="scss" scoped>
.toolbar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 19px;
  cursor: pointer;
  z-index: 1;
  width: 80px;
  height: 24px;
  background-color: #3d51fb;
  border-radius: 30px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffffde;
  padding-bottom: 2px;

  &.disabled {
    background-color: #cbcbcb;
    color: #00000042;
  }
}

.icon {
  width: 7px;
  margin-right: 5px;
}
</style>
