<template>
  <div class="log-accordion-content">
    <div class="log-accordion-content wrapper">
      <div class="log-accordion-header">
        <div class="log-accordian-label">Date</div>
        <div @click="openDatepicker = !openDatepicker" class="log-accordian-calendar-container">
          <div>{{ dates }}</div>
        </div>
        <div @click="openDatepicker = !openDatepicker" class="log-accordian-calendar-image">
          <img src="@/assets/Icon material-date-range.svg" width="12px" />
        </div>
      </div>
      <Calendar v-if="openDatepicker" :min-date="calendarMinDate" @update="onUpdateDate" style="display: flex; align-items: center"></Calendar>
      <div class="log-accordion-content">
        <div class="log-accordion-content wrapper-site">
          <div v-for="date in sortedDateList" :key="date.name">
            <div
              v-if="date.logs.length"
              @click="onClickLog(date)"
              :class="{
                'log-accordion-content': true,
                site: true,
                focus: selectSiteDate === date.name,
              }"
            >
              <div class="text-truncate">
                {{ date.name }}<span class="log-count">{{ date.logs.length }}</span>
              </div>
              <div class="spacer-1"></div>
              <img v-if="!$vuetify.theme.dark && selectSiteDate !== date.name" src="@/assets/Icon ionic-ios-arrow-down-sub-black.svg" />
              <img v-if="$vuetify.theme.dark && selectSiteDate !== date.name" src="@/assets/Icon ionic-ios-arrow-down-dark.svg" />
              <img v-if="!$vuetify.theme.dark && selectSiteDate == date.name" src="@/assets/Icon ionic-ios-arrow-up-sub-black.svg" />
              <img v-if="$vuetify.theme.dark && selectSiteDate == date.name" src="@/assets/Icon ionic-ios-arrow-up-sub.svg" />
            </div>
            <div v-if="selectSiteDate === date.name" class="log-accordion-content">
              <div
                v-for="log in date.logs"
                :key="log.log_id"
                @click="onClickLog(log)"
                :class="{
                  'log-accordion-content': true,
                  text: true,
                  sub: true,
                  focus: selectId === log.log_id,
                }"
              >
                {{ displayLogTime(log) }}
                <div class="spacer-1"></div>
                <div
                  :class="{
                    'icon-label': true,
                    fire: log.event_type_id === eventTypeList[2].id,
                    'non-fire': log.event_type_id === eventTypeList[3].id,
                    unknown: log.event_type_id === eventTypeList[4].id,
                    planned: log.event_type_id === eventTypeList[5].id,
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from '@mobile/Calendar'
import AsideLogHistory from '@desktop/superadminOrWorker/alert/AsideLogHistory'

export default {
  extends: AsideLogHistory,
  components: { Calendar },
}
</script>

<style lang="scss" scoped>
.log-accordion-header {
  display: flex;
  align-items: center;
  flex: 0 0 48px;
  cursor: pointer;
  background-color: var(--v-backgroundAsideHeaderRecent-base);
  color: var(--v-textDefault);
  font-size: 12px;
  font-weight: 500;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--v-borderLineBottom-base);
}

.log-accordian-label {
  margin-left: 25px;
}

.log-accordian-calendar-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: var(--v-backgroundDate-base);
  min-width: 165px;
  max-width: 203px;
  height: 30px;
  border-radius: 6px;
  padding: 0 10px;
  margin: 0 10px;
}

.log-accordian-calendar-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: #3d51fb;
  cursor: pointer;
  margin-right: 25px;
  &:active {
    opacity: 0.5;
  }
}

.log-accordion-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: var(--v-backgroundAsideRecentSpace-base);
  color: var(--v-textDefault);
  font-size: 12px;
  font-weight: 500;

  &:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: var(--v-borderLineBottom-base);
  }

  &.wrapper {
    margin-left: 30px;
    overflow: auto;
  }

  &.text {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--v-backgroundAsideHeaderRecent-base);
    padding: 0px 25px;
    flex: 0 0 36px;
    cursor: pointer;

    &.sub {
      flex-direction: row;
    }

    &.focus {
      color: #3d51fb;
    }
  }

  .log-count {
    margin-left: 10px;
    display: inline-block;
    text-align: center;
    background-color: var(--v-backgroundCount);
    color: var(--v-textCount);
    height: 18px;
    width: 30px;
    border-radius: 4px;
  }

  &.site {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    flex: 0 0 40px;
    cursor: pointer;
    user-select: none;
    padding: 0 25px;
    background-color: var(--v-backgroundAsideHeaderRecent-base);
    border-top-width: 1px;
    border-top-style: solid;
    border-color: var(--v-borderLineBottom-base);

    &.focus {
      background-color: var(--v-backgroundAsideHeaderRecentLabel-base);
    }

    & img {
      width: 10px;
      margin-right: 1px;
    }
  }
}

.icon-label {
  min-width: 8px;
  height: 8px;
  border-radius: 8px;
  border: 1px solid var(--v-textLabelBorder);
  margin-right: 3px;

  &.fire {
    background-color: var(--v-backgroundFire-base);
    border-width: 0px;
  }

  &.non-fire {
    background-color: var(--v-backgroundNoneFire-base);
    border-width: 0px;
  }

  &.unknown {
    background-color: var(--v-backgroundUnknown-base);
    border-width: 0px;
  }

  &.planned {
    background-color: var(--f-supplementary-purple);
    border-width: 0px;
  }
}
</style>
