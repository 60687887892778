<template>
  <div :class="`detailed-info-container ${themeName}`">
    <!-- Event Information -->
    <div :class="`section-label ${themeName}`">Event Information</div>

    <div :class="`section-container ${themeName}`">
      <div v-if="currentLog && !currentCam" class="section-row">
        <div :class="`section-cell label ${themeName}`">Event Type</div>
        <div :class="`section-cell value ${themeName}`">{{ eventType }}</div>
      </div>
      <div v-if="currentLog && !currentCam" class="section-row">
        <div :class="`section-cell label ${themeName}`">Event ID</div>
        <div :class="`section-cell value ${themeName}`">{{ eventId }}</div>
      </div>
      <div v-if="currentLog && !currentCam" class="section-row">
        <div :class="`section-cell label ${themeName}`">Detected Time</div>
        <div :class="`section-cell value ${themeName}`">{{ detectedTime }}</div>
      </div>
      <div v-if="currentLog && !currentCam" class="section-row">
        <div :class="`section-cell label ${themeName}`">Fire Location</div>
        <div :class="`section-cell value ${themeName}`">{{ fireLocationValue }}</div>
      </div>
      <div v-if="currentLog && !currentCam" class="section-row">
        <div :class="`section-cell label ${themeName}`">Fire Direction</div>
        <div :class="`section-cell value ${themeName}`">{{ fireDirectionValue }}</div>
      </div>
      <div :class="`section-row ${currentLog && !currentCam ? '' : 'no-bottom-margin'}`">
        <div :class="`section-cell label ${themeName}`">Camera Location</div>
        <div :class="`section-cell value ${themeName}`">{{ cameraLocation }}</div>
      </div>
    </div>
    <!-- Weather Information -->
    <div v-if="currentLog && !currentCam" :class="`section-label ${themeName}`">Weather Information</div>

    <div v-if="currentLog && !currentCam" :class="`section-container ${themeName}`">
      <div class="section-row">
        <div class="section-cell-third-left">
          <div :class="`label ${themeName}`">Temperature</div>
          <div :class="`value ${themeName}`">{{ temperature }}</div>
        </div>
        <div class="section-cell-third">
          <div :class="`label ${themeName}`">Wind Speed</div>
          <div :class="`value ${themeName}`">{{ windSpeed }}</div>
        </div>
        <div class="section-cell-third">
          <div :class="`label ${themeName}`">Wind Direction</div>
          <div :class="`value ${themeName}`">{{ windDirection }}</div>
        </div>
      </div>
      <div class="section-row row-station" style="font-size: 11px">
        <div :class="`label ${themeName}`" style="width: 110px">Relative Humidity</div>
        <div :class="`value ${themeName}`" style="width: calc(100% - 110px)">{{ relativeHumidity }}</div>
      </div>
      <div class="section-row row-station" style="font-size: 11px">
        <div :class="`label ${themeName}`" style="width: 64.64px">Station</div>
        <div :class="`value ${themeName}`" style="width: calc(100% - 64.64px); white-space: inherit">
          {{ station }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Theme from '@/mixin/theme.mixin'
import Util from '@/util'
import moment from 'moment'

export default {
  name: 'AsideDetailedInfo',
  mixins: [Theme],
  props: {
    currentCam: Object,
    currentLog: Object,
  },
  computed: {
    ...mapState(['camMap', 'eventTypeList']),
    eventType() {
      if (this.currentLog && this.currentLog.event_type_id) {
        return this.eventTypeList.find((type) => type.id === this.currentLog.event_type_id).name
      }
      return '-'
    },
    eventId() {
      if (this.currentLog && this.currentLog.log_id) {
        return `#${this.currentLog.log_id}`
      }
      return '-'
    },
    detectedTime() {
      if (this.currentLog && this.currentLog.created_at) {
        return Util.getOffsetDate(this.currentLog.created_at, this.currentLog.offset)
      }
      return '-'
    },
    fireLocationValue() {
      if (!!this.currentLog && !!this.currentLog.latitude && !!this.currentLog.longitude) {
        if (this.currentLog.latitude !== -1 && this.currentLog.longitude !== -1) {
          // fire location found
          return `${this.currentLog.latitude} ${this.currentLog.longitude}`
        }
      }
      return '-'
    },
    fireDirectionValue() {
      if (!!this.currentLog.pan_direction) {
        if (this.currentLog.pan_direction !== -1) {
          // fire direction found
          return Util.locaitonToDegree(this.currentLog.pan_direction)
        }
      }
      return '-'
    },
    cameraLocation() {
      if (this.currentCam && this.currentCam.coordinate) {
        return this.currentCam.coordinate
      } else if (this.currentLog && this.currentLog.camera_id) {
        return this.camMap[this.currentLog.camera_id].coordinate
      }
      return '-'
    },
    temperature() {
      if (this.currentLog && this.currentLog.temperature) {
        return this.currentLog.temperature
      }
      return '-'
    },
    windSpeed() {
      if (this.currentLog && this.currentLog.wind_speed) {
        return this.currentLog.wind_speed
      }
      return '-'
    },
    windDirection() {
      if (this.currentLog && this.currentLog.wind_direction) {
        return this.currentLog.wind_direction
      }
      return '-'
    },
    relativeHumidity() {
      if (this.currentLog && this.currentLog.relative_humidity) {
        return this.currentLog.relative_humidity
      }
      return '-'
    },
    station() {
      if (this.currentLog && this.currentLog.station_name) {
        return this.currentLog.station_name
      }
      return '-'
    },
  },
}
</script>

<style lang="scss" scoped>
.detailed-info-container {
  // height: 100%;
  width: 100%;
  padding: 10px 26px;

  .section-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--v-textDefault-white);
    &.dark {
      color: var(--f-text-white-high);
    }
    margin-bottom: 10px;
  }

  .section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 14px;
    background-color: var(--f-light-gray-1);
    margin-bottom: 10px;
    overflow: hidden;
    &.dark {
      background-color: var(--f-bg-high);
    }

    .section-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      &.row-station {
        justify-content: flex-start !important;
        align-items: flex-start !important;
      }
      &.no-bottom-margin {
        margin-bottom: 0px !important;
      }

      .section-cell {
        width: 50%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 11px;
      }

      .label {
        font-weight: 600;
        color: var(--f-text-gray);
        white-space: nowrap;
        &.dark {
          color: var(--f-text-white-high);
        }
      }

      .value {
        font-weight: 600;
        white-space: nowrap;
        color: var(--f-text-black);
        &.dark {
          color: var(--f-primary-50);
        }
      }

      .section-cell-third {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        .label {
          font-weight: 600;
          color: var(--f-text-gray);
          white-space: nowrap;
          &.dark {
            color: var(--f-text-white-high);
          }
        }

        .value {
          font-weight: 600;
          white-space: nowrap;
          color: var(--f-text-black);
          &.dark {
            color: var(--f-primary-50);
          }
        }
      }

      .section-cell-third-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        .label {
          font-weight: 600;
          color: var(--f-text-gray);
          white-space: nowrap;
          &.dark {
            color: var(--f-text-white-high);
          }
        }

        .value {
          font-weight: 600;
          white-space: nowrap;
          color: var(--f-text-black);
          &.dark {
            color: var(--f-primary-50);
          }
        }
      }
    }
  }
}
</style>
