<template>
  <div @click="$emit('click', $event)" class="button-edit">
    <img src="@/assets/icon-edit.svg" class="icon" />
    <span>Edit</span>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.button-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 34px;
  background-color: var(--v-backgroundDownload);
  border-radius: 30px;
  font-weight: 500;
  font-size: 15px;
  color: var(--v-backgroundDownloadText);
  cursor: pointer;
}

.icon {
  margin-right: 10px;
}
</style>
