import { render, staticRenderFns } from "./ButtonPlay.vue?vue&type=template&id=bb33e4d4&scoped=true&"
import script from "./ButtonPlay.vue?vue&type=script&lang=js&"
export * from "./ButtonPlay.vue?vue&type=script&lang=js&"
import style0 from "./ButtonPlay.vue?vue&type=style&index=0&id=bb33e4d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb33e4d4",
  null
  
)

export default component.exports