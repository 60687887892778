<template>
  <div class="pagination-container">
    <img
      v-show="value === 1 && $vuetify.theme.dark"
      @click="onClickPage('first')"
      src="@/assets/Icon ionic-ios-arrow-first-white-disabled.svg"
      class="indicator first"
    />
    <img
      v-show="value === 1 && !$vuetify.theme.dark"
      @click="onClickPage('first')"
      src="@/assets/Icon ionic-ios-arrow-first-black-disabled.svg"
      class="indicator first"
    />
    <img
      v-show="value !== 1 && $vuetify.theme.dark"
      @click="onClickPage('first')"
      src="@/assets/Icon ionic-ios-arrow-first-white.svg"
      class="indicator first"
    />
    <img
      v-show="value !== 1 && !$vuetify.theme.dark"
      @click="onClickPage('first')"
      src="@/assets/Icon ionic-ios-arrow-first-black.svg"
      class="indicator first"
    />
    <img
      v-show="value === 1 && $vuetify.theme.dark"
      @click="onClickPage('prev')"
      src="@/assets/Icon ionic-ios-arrow-prev-white-disabled.svg"
      class="indicator prev"
    />
    <img
      v-show="value === 1 && !$vuetify.theme.dark"
      @click="onClickPage('prev')"
      src="@/assets/Icon ionic-ios-arrow-prev-black-disabled.svg"
      class="indicator prev"
    />
    <img
      v-show="value !== 1 && $vuetify.theme.dark"
      @click="onClickPage('prev')"
      src="@/assets/Icon ionic-ios-arrow-prev-white.svg"
      class="indicator prev"
    />
    <img
      v-show="value !== 1 && !$vuetify.theme.dark"
      @click="onClickPage('prev')"
      src="@/assets/Icon ionic-ios-arrow-prev-black.svg"
      class="indicator prev"
    />
    <div class="text">{{ pageText }}</div>
    <img
      v-show="(value === totalPage || !totalPage) && $vuetify.theme.dark"
      @click="onClickPage('next')"
      src="@/assets/Icon ionic-ios-arrow-next-white-disabled.svg"
      class="indicator next"
    />
    <img
      v-show="(value === totalPage || !totalPage) && !$vuetify.theme.dark"
      @click="onClickPage('next')"
      src="@/assets/Icon ionic-ios-arrow-next-black-disabled.svg"
      class="indicator next"
    />
    <img
      v-show="value !== totalPage && totalPage && $vuetify.theme.dark"
      @click="onClickPage('next')"
      src="@/assets/Icon ionic-ios-arrow-next-white.svg"
      class="indicator next"
    />
    <img
      v-show="value !== totalPage && totalPage && !$vuetify.theme.dark"
      @click="onClickPage('next')"
      src="@/assets/Icon ionic-ios-arrow-next-black.svg"
      class="indicator next"
    />
    <img
      v-show="(value === totalPage || !totalPage) && $vuetify.theme.dark"
      @click="onClickPage('last')"
      src="@/assets/Icon ionic-ios-arrow-last-white-disabled.svg"
      class="indicator last"
    />
    <img
      v-show="(value === totalPage || !totalPage) && !$vuetify.theme.dark"
      @click="onClickPage('last')"
      src="@/assets/Icon ionic-ios-arrow-last-black-disabled.svg"
      class="indicator last"
    />
    <img
      v-show="value !== totalPage && totalPage && $vuetify.theme.dark"
      @click="onClickPage('last')"
      src="@/assets/Icon ionic-ios-arrow-last-white.svg"
      class="indicator last"
    />
    <img
      v-show="value !== totalPage && totalPage && !$vuetify.theme.dark"
      @click="onClickPage('last')"
      src="@/assets/Icon ionic-ios-arrow-last-black.svg"
      class="indicator last"
    />
  </div>
</template>

<script>
export default {
  props: {
    // current page
    value: {
      type: Number,
      default: 1,
    },
    // total items length
    length: {
      type: Number,
      default: 0,
    },
    pagePerCount: {
      type: Number,
      default: 12,
    },
    /**
     * emit events
     * select-range
     */
  },
  computed: {
    totalPage() {
      if (this.length > 0) {
        return Math.ceil(this.length / this.pagePerCount)
      } else {
        return 0
      }
    },
    start() {
      return (this.value - 1) * this.pagePerCount + 1
    },
    end() {
      let end = this.start + this.pagePerCount - 1
      if (end > this.length) {
        end = this.length
      }
      return end
    },
    pageText() {
      return `${this.start}-${this.end} of ${this.length}`
    },
  },
  watch: {
    value(val) {
      if (val > 0) {
        this.$emit('select-range', [this.start - 1, this.end])
      }
    },
  },
  methods: {
    onClickPage(navi) {
      if (navi === 'first') {
        if (this.value > 1) {
          this.$emit('input', 1)
        }
      } else if (navi === 'prev') {
        if (this.value > 1) {
          this.$emit('input', this.value - 1)
        }
      } else if (navi === 'next') {
        if (this.value < this.totalPage) {
          this.$emit('input', this.value + 1)
        }
      } else if (navi === 'last') {
        if (this.value < this.totalPage) {
          this.$emit('input', this.totalPage)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--v-textDefault);

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 112px;
  }

  .indicator {
    cursor: pointer;

    &.first {
      margin-right: 34px;
    }

    &.prev {
      margin-right: 32px;
    }

    &.next {
      margin-left: 32px;
    }

    &.last {
      margin-left: 34px;
    }
  }
}
</style>
