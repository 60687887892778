<template>
  <v-dialog v-model="value" width="500px" @keydown.esc="hide" persistent no-click-animation>
    <v-card style="background-color: var(--v-backgroundAsideHeaderRecent-base)">
      <v-card-text class="pa-0">
        <div class="pause-alert-dialog-title">Pause Alerts Until...</div>
        <img v-if="$vuetify.theme.dark" @click="hide" src="@/assets/Exit_Icon.svg" class="pause-alert-dialog-close" />
        <img v-else @click="hide" src="@/assets/Exit_Icon_Black.svg" class="pause-alert-dialog-close" />
        <div class="pause-alert-dialog-content">
          <div class="date-time-container" style="margin-bottom: 20px">
            <div class="label">Date</div>
            <div class="calendar-container">{{ currentDate }}</div>
            <v-menu
              v-model="openDatepicker"
              offset-y
              nudge-bottom="5px"
              nudge-left="307px"
              :close-on-content-click="false"
              content-class="calendar-layout"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="calendar-image">
                  <img src="@/assets/Icon material-date-range.svg" width="18px" />
                </div>
              </template>
              <Calendar
                v-if="openDatepicker"
                :min-date="calendarMinDate"
                :max-date="calendarMaxDate"
                :is-range="false"
                @update="onUpdateDate"
                style="border-radius: 15px"
              ></Calendar>
            </v-menu>
          </div>
          <div class="date-time-container" style="cursor: pointer">
            <div class="label">Time</div>
            <v-menu offset-y nudge-bottom="12px">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="calendar-container">
                  <span>{{ currentTime }}</span>
                  <v-spacer></v-spacer>
                  <img v-if="$vuetify.theme.dark" src="@/assets/Icon ionic-ios-arrow-down-dark.svg" width="10px" />
                  <img v-else src="@/assets/Icon ionic-ios-arrow-down-sub-black.svg" width="10px" />
                </div>
              </template>
              <div class="time-container">
                <div class="wrapper">
                  <div
                    v-for="(time, index) in timeList"
                    :key="index"
                    @click="onClickTime(index, $event)"
                    :class="{ time: true, disabled: invalidTime(index) }"
                  >
                    {{ time }}
                  </div>
                </div>
              </div>
            </v-menu>
          </div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px">
          <button @click="ok" class="pause-alert-dialog-button ok">Pause Alerts</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Calendar from '@components/common/Calendar'
import moment from 'moment'
import Util from '@/util'
import { alert } from '@/api'

export default {
  components: { Calendar },
  props: {
    value: Boolean,
    item: Object,
    /**
     * emit events
     * ok
     */
  },
  data() {
    const timeList = (() => {
      return new Array(24).fill(0).map((v, i) => {
        if (i < 12) {
          return `${i.toString().padStart(2, '0')}:00 AM`
        } else {
          return `${i}:00 PM`
        }
      })
    })()
    return {
      openDatepicker: false,
      today: '',
      currentDate: '',
      calendarMinDate: '',
      calendarMaxDate: '',
      currentTimeIndex: 0,
      timeList,
    }
  },
  computed: {
    ...mapState(['recentLogList']),
    currentTime() {
      if (this.currentTimeIndex < 12) {
        return `${this.currentTimeIndex.toString().padStart(2, '0')}:00 AM`
      } else {
        return `${this.currentTimeIndex}:00 PM`
      }
    },
    after72hours() {
      return Util.defineAlertOffTime(moment(this.lastestLog.created_at).add(72, 'h').utc().format())
    },
    lastestLog() {
      return this.recentLogList.find((log) => log.camera_id === this.item.id)
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.today = moment().format('YYYY-MM-DD')
        this.currentDate = moment().format('YYYY-MM-DD')
        this.calendarMinDate = moment().subtract(1, 'day')
        this.calendarMaxDate = moment(this.after72hours)
        if (this.calendarMaxDate.format('H') == 0) {
          this.calendarMaxDate.add(1, 'm')
        }
        this.currentTimeIndex = parseInt(moment().format('H')) + 1
      }
    },
  },
  methods: {
    ...mapActions(['getCamList']),
    hide() {
      this.$emit('input', false)
    },
    async ok() {
      this.hide()
      try {
        await alert.updatePauseAlert({
          camera_id: this.item.id,
          start_time: this.lastestLog.created_at,
          end_time: moment(this.currentDate).hour(this.currentTimeIndex).format(),
        })
        await this.getCamList({ justCamList: true })
      } catch (e) {
        this.$log.error('updatePauseAlert error')
      }
    },
    invalidTime(currentTimeIndex) {
      if (this.currentDate === this.today) {
        const hour = parseInt(moment().format('H')) + 1
        if (currentTimeIndex < hour) {
          return true
        }
      }
      if (this.calendarMaxDate && this.currentDate === this.calendarMaxDate.format('YYYY-MM-DD')) {
        const hour = moment(Util.defineAlertOffTime(this.calendarMaxDate)).format('H')
        if (hour < currentTimeIndex) {
          return true
        }
      }
      return false
    },
    onUpdateDate(dates) {
      const selectedDate = moment(dates[0])
      this.openDatepicker = false
      this.currentDate = selectedDate.format('YYYY-MM-DD')
      this.setCurrentTime()
    },
    setCurrentTime() {
      if (this.invalidTime(this.currentTimeIndex)) {
        const firstInvalidTime = this.invalidTime(0)
        for (let i = 0, j = this.timeList.length; i < j; i++) {
          if (firstInvalidTime) {
            if (!this.invalidTime(i)) {
              this.currentTimeIndex = i
              break
            }
          } else {
            if (!this.invalidTime(i)) {
              this.currentTimeIndex = i
            }
          }
        }
      }
    },
    onClickTime(index, e) {
      if (this.invalidTime(index)) {
        e.stopPropagation()
      } else {
        this.currentTimeIndex = index
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pause-alert-dialog-title {
  padding: 30px 40px 0;
  font-weight: 600;
  font-size: 20px;
  color: var(--v-textDefault);
}

.pause-alert-dialog-close {
  position: absolute;
  top: 25px;
  right: 30px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.pause-alert-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 205px;
  font-size: 15px;
  font-weight: 500;
  color: var(--v-textDefault);

  .date-time-container {
    display: flex;
    align-items: center;

    .label {
      margin-left: 50px;
      margin-right: 20px;
    }

    .calendar-container {
      display: flex;
      align-items: center;
      padding: 0 17px 0 15px;
      width: 297px;
      height: 40px;
      border-radius: 10px;
      color: var(--v-textDefault-white);
      background-color: var(--v-backgroundDate-base);
      font-weight: 400;
      font-size: 16px;
    }
  }
}

.time-container {
  max-height: 147px;
  border-radius: 6px;
  background-color: var(--v-backgroundAsideTooltip-base);
  padding: 6px 0;
  overflow: hidden;

  .wrapper {
    display: flex;
    flex-direction: column;
    max-height: calc(147px - 12px);
    overflow: auto;
  }

  .time {
    display: flex;
    align-items: center;
    min-height: 27px;
    padding-left: 15px;
    color: var(--v-textDefault);
    cursor: pointer;
    font-size: 14px;

    &:hover {
      color: var(--v-textCount);
      background-color: var(--v-backgroundAlertOff-base);
    }

    &.disabled {
      display: none;
    }
  }
}

.calendar-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #3d51fb;
  user-select: none;
  cursor: pointer;
  margin-left: 10px;
  &:active {
    opacity: 0.5;
  }
}

.calendar-layout {
  border-radius: 15px !important;
}

.pause-alert-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 150px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #3d51fb;
    color: var(--v-textCount);
  }
}
</style>
