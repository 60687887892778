<template>
  <v-dialog v-model="value" max-width="500px" @keydown.esc="hide" @click:outside="hide">
    <v-card style="background-color: var(--v-backgroundAsideHeaderRecent-base)">
      <v-card-text class="pa-0">
        <div class="save-label-dialog-title">Save Event Type</div>
        <div class="save-label-dialog-content">
          <div>Please click save to confirm event type selection.</div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px">
          <button @click="hide" class="save-label-dialog-button cancel">Cancel</button>
          <button @click="ok" class="save-label-dialog-button ok">Save</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    /**
     * emit events
     * ok
     */
  },
  methods: {
    hide() {
      this.$emit('input', false)
    },
    ok() {
      this.hide()
      this.$emit('ok', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.save-label-dialog-title {
  padding: 30px 40px 0;
  font-weight: 600;
  font-size: 20px;
  color: var(--v-textAside);
}

.save-label-dialog-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  font-size: 16px;
  color: var(--v-textDefault);
}

.save-label-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #3d51fb;
    color: var(--v-textCount);
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}
</style>
