<template>
  <div v-if="recentLogList.length" class="log-accordian-content">
    <div class="wrapper">
      <div
        v-for="log in recentLogList"
        :key="log.log_id"
        @click="onClickLog(log)"
        :class="{
          'recent-log': true,
          labeled: !isViewer && log.event_type_id <= eventTypeList[1].id,
          viewer: isViewer,
          unlabeled: log.event_type_id <= eventTypeList[1].id,
          focus: selectId === log.log_id,
        }"
      >
        <div class="d-flex">
          <div class="text-truncate">{{ displayLogTime(log) }}</div>
          <div class="spacer-1"></div>
          <img
            v-if="!isViewer && log.event_type_id <= eventTypeList[1].id && selectId !== log.log_id"
            src="@/assets/Icon ionic-ios-arrow-down-sub-blue.svg"
            width="14px"
          />
          <img
            v-if="!$vuetify.theme.dark && log.event_type_id > eventTypeList[1].id && selectId !== log.log_id"
            src="@/assets/Icon ionic-ios-arrow-down-sub-black.svg"
            width="14px"
          />
          <img
            v-if="$vuetify.theme.dark && log.event_type_id > eventTypeList[1].id && selectId !== log.log_id"
            src="@/assets/Icon ionic-ios-arrow-down-dark.svg"
            width="14px"
          />
          <img
            v-if="!isViewer && log.event_type_id <= eventTypeList[1].id && selectId == log.log_id"
            src="@/assets/Icon ionic-ios-arrow-up-sub-blue.svg"
            width="14px"
          />
          <img
            v-if="!$vuetify.theme.dark && log.event_type_id > eventTypeList[1].id && selectId == log.log_id"
            src="@/assets/Icon ionic-ios-arrow-up-sub-black.svg"
            width="14px"
          />
          <img
            v-if="$vuetify.theme.dark && log.event_type_id > eventTypeList[1].id && selectId == log.log_id"
            src="@/assets/Icon ionic-ios-arrow-up.svg"
            width="14px"
          />
        </div>
        <div v-if="!isViewer && selectId === log.log_id && log.event_type_id <= eventTypeList[1].id" class="d-flex">
          <div @click.stop="onClickLabel(eventTypeList[2].id)" class="label">{{ eventTypeList[2].name }}</div>
          <div @click.stop="onClickLabel(eventTypeList[3].id)" class="label">{{ eventTypeList[3].name }}</div>
          <div @click.stop="onClickLabel(eventTypeList[4].id)" class="label">{{ eventTypeList[4].name }}</div>
        </div>
        <div v-if="selectId === log.log_id && log.event_type_id > eventTypeList[1].id" class="d-flex align-center">
          <div v-if="log.event_type_id === eventTypeList[2].id" class="label fire">{{ eventTypeList[2].name }}</div>
          <div v-if="log.event_type_id === eventTypeList[3].id" class="label non-fire">{{ eventTypeList[3].name }}</div>
          <div v-if="log.event_type_id === eventTypeList[4].id" class="label unknown">{{ eventTypeList[4].name }}</div>
          <div v-if="log.event_type_id === eventTypeList[5].id" class="label planned">{{ eventTypeList[5].name }}</div>
          <!-- <div class="text-labeler">
                confirmed by <span class="text-labeler-name">{{ log.labeler }}</span>
              </div> -->
        </div>
      </div>
    </div>
    <SaveLabelDialog v-model="showSaveLabelDialog" @ok="onClickSaveLabel"></SaveLabelDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Util from '@/util'
import SaveLabelDialog from '@/components/desktop/dialog/SaveLabelDialog'

export default {
  components: { SaveLabelDialog },
  props: {
    selectId: Number,
    /**
     * emit events
     * click-log Object
     * save-label Object
     */
  },
  data() {
    return {
      showSaveLabelDialog: false,
      showSaveLabelDialogLabel: '',
    }
  },
  computed: {
    ...mapState(['recentLogList', 'camMap', 'eventTypeList', 'user']),
    isViewer() {
      return this.user.isViewer
    },
  },
  methods: {
    displayLogTime(log) {
      const cam = this.camMap[log.camera_id]
      if (cam) {
        const date = Util.getOffsetDate(log.created_at, log.offset)
        return `[${date}] ${cam.name}`
      } else {
        return log.log
      }
    },
    onClickLog(log) {
      this.$emit('click-log', log)
    },
    onClickLabel(label) {
      this.showSaveLabelDialog = true
      this.showSaveLabelDialogLabel = label
    },
    onClickSaveLabel() {
      this.$emit('save-label', this.showSaveLabelDialogLabel)
    },
  },
}
</script>

<style lang="scss" scoped>
.log-accordian-content {
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: var(--v-backgroundAsideHeaderRecentBorder-base);
  margin: 10px 16px 0px 34px;
  border-radius: 15px;
  max-height: 402px;
  overflow: hidden;

  .wrapper {
    overflow: auto;
  }

  .recent-log {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
    color: var(--v-textDefault);
    background-color: var(--v-backgroundAsideHeaderRecent-base);
    padding: 0 30px;
    height: 50px;
    cursor: pointer;
    border-style: solid;
    border-color: var(--v-backgroundAsideHeaderRecentBorder-base);
    border-width: 0 0 1px;

    &.labeled {
      color: #5f8bff;
    }

    &.focus {
      height: 100px;
      background-color: var(--v-backgroundAsideHeaderRecentLabel-base);

      &.viewer.unlabeled {
        height: 50px;
      }
    }

    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 36px;
      background-color: var(--v-backgroundAsideTooltip-base);
      border: 1px solid var(--v-textLabel);
      border-radius: 30px;
      margin-top: 14px;
      margin-right: 10px;
      color: var(--v-textLabel);
      font-weight: 500;
      font-size: 15px;
      text-align: center;

      &.fire {
        background-color: var(--v-backgroundFire-base);
        border-width: 0px;
        color: #ffffffde;
      }

      &.non-fire {
        background-color: var(--v-backgroundNoneFire-base);
        border-width: 0px;
        color: #ffffffde;
      }

      &.unknown {
        background-color: var(--v-backgroundUnknown-base);
        border-width: 0px;
        color: #ffffffde;
      }

      &.planned {
        background-color: var(--f-supplementary-purple);
        border-width: 0px;
        color: #ffffffde;
      }
    }
  }

  .text-labeler {
    margin-top: 14px;
    font-size: 13px;
    color: #8d90a2;
    .text-labeler-name {
      font-weight: 600;
      color: var(--v-textDefault);
    }
  }
}
</style>
