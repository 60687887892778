<template>
  <div style="position: relative" class="player-bar-root" @click="onChangeMode()">
    <div style="position: absolute; width: 100%; height: 100%">
      <div
        :style="timebarPosition"
        :class="{
          'player-bar-timebar': true,
          start: timebarStart || timebarMiddle,
          end: mode === FRAME ? timebarEnd : timebarPositionFull,
        }"
      ></div>
      <div
        v-if="mode === FRAME"
        :style="timebarPosition"
        :class="{
          'player-bar-timebar-border': true,
          start: timebarStart,
          middle: timebarMiddle,
          end: timebarEnd,
        }"
      ></div>
      <div v-else-if="!timebarPositionFull" :style="timebarPosition" :class="{ 'player-bar-timebar-border': true }"></div>
    </div>
    <div class="player-bar-container">
      <div v-for="(image, index) in loadedImages" :key="index" @mouseover="frameIndex = index" :style="makeStyle(image, index)">
        <img
          v-show="isLoaded && index === detectionFrameIndex && !admin && $vuetify.theme.dark"
          src="@/assets/Caret-Up_Filled_16px_Icon.svg"
          width="14px"
          :class="getCaretClass('up')"
          style="opacity: 1"
        />
        <img
          v-show="isLoaded && index === detectionFrameIndex && !admin && $vuetify.theme.dark"
          src="@/assets/Caret-Down_Filled_16px_Icon.svg"
          width="14px"
          :class="getCaretClass('down')"
          style="opacity: 1"
        />
        <img
          v-show="isLoaded && index === detectionFrameIndex && !admin && !$vuetify.theme.dark"
          src="@/assets/Caret-Up_Filled_12px_Icon_Light_Mode.svg"
          width="14px"
          :class="getCaretClass('up')"
          style="opacity: 1"
        />
        <img
          v-show="isLoaded && index === detectionFrameIndex && !admin && !$vuetify.theme.dark"
          src="@/assets/Caret-Down_Filled_12px_Icon_Light_Mode.svg"
          width="14px"
          :class="getCaretClass('down')"
          style="opacity: 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detectionFrameIndex: {
      type: Number,
      default: 0,
    },
    hasAfterImages: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: [],
    },
    frame: {
      type: Number,
      default: 0,
    },
    play: {
      type: String,
      default: 'stop',
    },
    monitor: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    /**
     * emit events
     * changeFrame
     * changeMode
     * onPlay
     */
  },
  data() {
    const PLAY = 'PLAY'
    const FRAME = 'FRAME'
    return {
      defaultImages: (() => {
        const defaultImage = require('@/assets/playerbar-default-image.jpg')
        return Array(20).fill(defaultImage)
      })(),
      PLAY,
      FRAME,
      mode: PLAY,
      ArrowLeft: 37,
      ArrowRight: 39,
      Enter: 32,
      position: this.frame,
      frameIndex: 0,
    }
  },
  computed: {
    isLoaded() {
      return this.images.length
    },
    loadedImages() {
      if (this.isLoaded) {
        if (!this.hasAfterImages) {
          return this.images.concat(this.defaultImages)
        }
        return this.images
      } else {
        return this.defaultImages
      }
    },
    imageWidth() {
      return 100 / this.loadedImages.length
    },
    timebarPositionFull() {
      if (!this.isLoaded) {
        return true
      } else if (this.mode === this.PLAY && ((this.play !== 'play' && this.timebarStart) || this.timebarEnd)) {
        return true
      }
      return false
    },
    timebarPosition() {
      if (this.mode === this.FRAME) {
        return `transform: translateX(${this.position * 100}%); width: ${this.imageWidth}%`
      } else {
        if (!this.timebarPositionFull) {
          return `width: ${(this.frame + 1) * this.imageWidth}%`
        } else if (!this.hasAfterImages && this.isLoaded) {
          return `width: ${this.imageWidth * (this.detectionFrameIndex + 1)}%`
        } else {
          return 'width: 100%'
        }
      }
    },
    timebarStart() {
      if (this.mode === this.FRAME) {
        return this.position === 0
      } else {
        return this.frame === 0
      }
    },
    timebarMiddle() {
      if (this.mode === this.FRAME) {
        return this.position !== 0 && !this.timebarEnd
      } else {
        return true
      }
    },
    timebarEnd() {
      if (this.mode === this.FRAME) {
        return this.position === this.images.length - 1
      } else {
        if (this.isLoaded) {
          return this.frame === this.images.length - 1
        } else {
          return this.defaultImages.length
        }
      }
    },
  },
  watch: {
    images(val) {
      if (!val || !val.length) {
        this.mode = this.PLAY
        this.position = 0
      }
    },
  },
  created() {
    this.__uid = Date.now()
    if (!window.alcheraListener) {
      window.alcheraListener = { keydown: {} }
    }
    // this.$log.debug('ImagePlayerBar#created', Object.keys(window.alcheraListener.keydown))
    window.alcheraListener.keydown[this.__uid] = this.onKeyDown.bind(this)
    window.addEventListener('keydown', window.alcheraListener.keydown[this.__uid])
  },
  destroyed() {
    // this.$log.debug('ImagePlayerBar#destroyed', Object.keys(window.alcheraListener.keydown))
    window.removeEventListener('keydown', window.alcheraListener.keydown[this.__uid])
    delete window.alcheraListener.keydown[this.__uid]
  },
  methods: {
    makeStyle(src, index) {
      if (index === this.detectionFrameIndex && !this.admin) {
        return {
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          border: '2px solid #F9423A',
          height: '100%',
          width: `${this.imageWidth}%`,
          display: 'flex',
          justifyContent: 'center',
          opacity: this.$vuetify.theme.dark ? '0.7' : '0.45',
        }
      } else if (!this.hasAfterImages && index > this.detectionFrameIndex) {
        return {
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          filter: 'brightness(20%)',
          height: '100%',
          width: `${this.imageWidth}%`,
          opacity: this.$vuetify.theme.dark ? '0.5' : '0.35',
        }
      }
      return {
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        height: '100%',
        width: `${this.imageWidth}%`,
        opacity: this.$vuetify.theme.dark ? '0.5' : '0.35',
      }
    },
    onKeyDown(e) {
      // this.$log.debug('ImagePlayerBar#onKeyDown', !!this.isLoaded, e.code, e.keyCode)
      if (this.isLoaded && this.mode === this.FRAME) {
        if (e.keyCode === this.ArrowLeft) {
          if (this.position > 0) {
            this.setFrameIndex(this.position - 1)
          }
        } else if (e.keyCode === this.ArrowRight) {
          if (this.position < this.images.length - 1) {
            this.setFrameIndex(this.position + 1)
          }
        }
      }
      if (e.keyCode === this.Enter) {
        e.preventDefault()
        this.$emit('onPlay')
      }
    },
    setFrameIndex(index) {
      // this.$log.debug('ImagePlayerBar#setFrameIndex', this.mode, index)
      if (this.hasAfterImages || (!this.hasAfterImages && index <= this.detectionFrameIndex)) {
        this.position = index
        this.$log.debug('ImagePlayerBar$setFrame', index)
        this.$emit('changeFrame', index)
      }
    },
    onChangeMode(forcePlayMode) {
      this.$log.debug('ImagePlayerbar$onChangeMode', this.isLoaded, forcePlayMode)
      if (this.isLoaded) {
        if (forcePlayMode) {
          if (this.mode === this.FRAME) {
            this.$emit('changeMode', this.PLAY)
          }
          this.mode = this.PLAY
          this.setFrameIndex(this.frame)
        } else {
          if (this.hasAfterImages || (!this.hasAfterImages && this.frameIndex <= this.detectionFrameIndex)) {
            this.$emit('changeMode', this.FRAME)
            this.mode = this.FRAME
            this.setFrameIndex(this.frameIndex)
          }
        }
      }
    },
    getCaretClass(direction) {
      let className = `caret-${direction}-align`
      if (this.monitor) {
        className = className.concat('-monitor')
      }
      return className
    },
  },
}
</script>

<style lang="scss" scoped>
.player-bar-root {
}

.player-bar-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.player-bar-timebar {
  position: absolute;
  pointer-events: none;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: linear-gradient(to right, rgba(0, 255, 255, 0.3), rgba(245, 235, 53, 0.3));
  z-index: 1;

  &.start {
    border-radius: 4px 0 0 4px;
  }

  &.end {
    border-radius: 4px;
  }
}

.player-bar-timebar-border {
  position: absolute;
  pointer-events: none;
  height: 100%;
  z-index: 1;

  &::before {
    position: absolute;
    content: '';
    top: -3px;
    left: 0px;
    right: 0px;
    bottom: -3px;
    padding: 0px;
    border-radius: 4px 0 0 4px;
    border-style: none solid none none;
    border-width: 4px;
    border-color: rgba(245, 235, 53, 1);
  }

  &.start::before {
    position: absolute;
    content: '';
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 3px;
    border-radius: 4px 0 0 4px;
    border-style: none;
    background: linear-gradient(to right, rgba(0, 255, 255, 1), rgba(245, 235, 53, 1));
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }

  &.middle::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 3px;
    border-radius: 0;
    border-style: none;
    background: linear-gradient(to right, rgba(0, 255, 255, 1), rgba(245, 235, 53, 1));
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }

  &.end::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 3px;
    border-radius: 0 4px 4px 0;
    border-style: none;
    background: linear-gradient(to right, rgba(0, 255, 255, 1), rgba(245, 235, 53, 1));
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }
}

.caret-up-align {
  position: absolute;
  top: 36px;
}

.caret-down-align {
  position: absolute;
  bottom: 36px;
}

.caret-up-align-monitor {
  position: absolute;
  top: 62px;
}

.caret-down-align-monitor {
  position: absolute;
  bottom: 62px;
}
</style>
