<template>
  <div class="terms-of-use-container">
    <div class="terms-of-use-title">Privacy Policy</div>
    <div class="terms-of-use-line"></div>
    <div>
      <div>
        Alchera Inc. (“we” or “Alchera”) respects your privacy and is committed to protecting your personal information that you provide to us.
        Alchera may review and update this Privacy Policy from time to time as needed without notice. By using our service, you agree to the terms of
        this Privacy Policy, and your continued use of our service constitutes your ongoing agreement to it.
      </div>
      <div class="terms-of-use-subtitle">1. Personal Information</div>
      <div>
        We collect information about you when you use our service or otherwise provide directly to us (“Personal Information”). The information we
        collect may include your personal information such as your name, ID, password, contact information, email addresses, IP address, cookies,
        service use history, device information, location information and other information that may identify you.
      </div>
      <div class="terms-of-use-subtitle">2. Use of Personal Information</div>
      <div>
        We intend to use your Personal Information for legitimate business purposes only, such as:<br />
        - To improve our software;<br />
        - To setup your account and to provide our services to you;<br />
        - To identify and authenticate your access to our website or service;<br />
        - To enhance our services for Wildfire Alert System;<br />
        - To send smoke detection notification; and<br />
        - To provide support and customer service for our website.<br />
      </div>
      <div class="terms-of-use-subtitle">3. Disclosure of Personal Information</div>
      <div>We do not rent, sell or share your Personal Information with third parties except as described in this Privacy Policy.</div>
      <div class="terms-of-use-subtitle">4. Retention of Personal Information</div>
      <div>
        We retain your Personal Information for so long as your service agreement remains effective or as needed to provide with services and to
        comply with our legal obligations, resolve disputes and enforce our agreements unless you instruct us otherwise.<br />
        Your name, ID, password, contact information and email address will be destroyed upon termination of your service agreement with us, and other
        Personal Information will be destroyed three (3) months after the termination of your service agreement.<br /><br />

        You may request to change update, correct errors or delete your Personal Information by emailing us at
        <a href="mailto:contact@alcherainc.com" target="_blank" style="text-decoration: underline">contact@alcherainc.com</a>.
      </div>
    </div>
  </div>
</template>

<script>
import Util from '@/util'

export default {
  created() {
    Util.moveScrollToTop(false)
  },
}
</script>

<style lang="scss" scoped>
.terms-of-use-container {
  color: var(--v-textDefault);
  margin: 15px 16px;
  font-weight: 400;
  font-size: 12px;
  margin-top: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.terms-of-use-line {
  height: 1px;
  background-color: var(--v-borderToolbarBottom-base);
  margin-top: 10px;
  margin-bottom: 15px;
}

.terms-of-use-title {
  font-weight: 500;
  font-size: 13px;
}

.terms-of-use-subtitle {
  font-weight: 600;
  font-size: 12px;
  margin-top: 15px;
}
</style>
