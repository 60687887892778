<template>
  <footer>
    <span @click="onClickTerms()" class="footer-text-element" style="margin-left: 26px">Terms of Use</span>
    <span @click="onClickPolicy()" class="footer-text-element" style="margin-left: 10px">Privacy Policy</span>
    <div class="spacer-1"></div>
    <ButtonTheme style="margin-right: 20px"></ButtonTheme>
  </footer>
</template>

<script>
import Footer from '@desktop/superadminOrWorker/alert/Footer'
import ButtonTheme from './ButtonTheme'

export default {
  extends: Footer,
  components: { ButtonTheme },
}
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--v-background-base);
  color: var(--v-textDefault-black);
  padding: 20px 0;
  font-weight: 400;
  font-size: 11px;
}

.footer-text-element {
  cursor: pointer;
  text-decoration: underline;
}
</style>
