<template>
  <footer>
    <img v-if="$vuetify.theme.dark" src="@/assets/wildfire-logo-white.png" width="120px" class="icon-logo" />
    <img v-else src="@/assets/wildfire-logo-black.png" width="120px" class="icon-logo" />
    <div class="split-line"></div>
    <a href="https://alcherainc.com/en/" target="_blank"> About Us </a>
    <div v-if="!signup" class="split-line"></div>
    <span v-if="!signup" @click="onClickTerms()" style="cursor: pointer">Terms of Use</span>
    <div class="split-line"></div>
    <span @click="onClickPolicy()" style="cursor: pointer">Privacy Policy</span>
    <div v-if="!signup" class="split-line"></div>
    <img v-if="!signup && $vuetify.theme.dark" src="@/assets/User Manual_icon.svg" class="icon-site" />
    <img v-else-if="!signup" src="@/assets/User Manual_icon_open.svg" class="icon-site" />
    <a v-if="!signup" href="https://www.notion.so/PC-7dd7daeae3d444fe8617a13624869c43" target="_blank" style="margin-left: 10px">
      <v-hover v-slot="{ hover }"
        ><div>
          User Manual
          <Tooltip v-if="hover" :title="tooltip.userManual.title" :content="tooltip.userManual.content" direction="up"></Tooltip></div></v-hover
    ></a>
    <div class="split-line"></div>
    <div style="color: var(--v-textDefault)">ⓒ 2021 Alchera Inc.</div>
    <div class="spacer-1"></div>
    <ButtonTheme v-if="!signup"></ButtonTheme>
  </footer>
</template>

<script>
import Tooltip from './Tooltip'
import ButtonTheme from './ButtonTheme'

export default {
  components: { Tooltip, ButtonTheme },
  props: {
    /**
     * emit events
     * terms
     * policy
     */
    signup: Boolean,
  },
  data() {
    return {
      tooltip: {
        userManual: {
          title: 'User Manual',
          content: 'Click to navigate to the user manual.',
        },
      },
    }
  },
  methods: {
    onClickTerms() {
      this.$emit('terms')
    },
    onClickPolicy() {
      this.$emit('policy')
    },
  },
}
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  background-color: var(--v-background-base);
  color: var(--v-textDefault50);
  margin-top: 100px;
  padding-bottom: 30px;
  font-weight: 500;
  font-size: 14px;
  z-index: 2;

  .icon-logo {
    margin-left: 30px;
    margin-top: -12px;
  }

  .icon-site {
    width: 16px;
    color: var(--v-backgroundLine-base);
  }

  a {
    color: var(--v-textDefault50);
    text-decoration-line: underline;
  }

  .split-line {
    margin: 0 15px;
    width: 1px;
    height: 20px;
    background-color: var(--v-backgroundLine-base);
  }

  .theme-container {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    margin-right: 30px;
    cursor: pointer;
    color: var(--v-textDefault);
  }
}
</style>
