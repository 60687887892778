var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"review-events-container"},[_c('div',{staticClass:"review-events-title"},[_vm._v("Review Events")]),_c('div',{staticClass:"review-events-control-container"},[_c('div',{staticClass:"subtitle"},[_vm._v("Date")]),_c('div',{staticClass:"calendar-container"},[_vm._v(_vm._s(_vm.dates))]),_c('div',{staticClass:"image",on:{"click":function($event){_vm.openDatepicker = !_vm.openDatepicker}}},[_c('img',{attrs:{"src":require("@/assets/Icon material-date-range.svg")}})]),_c('div',{staticClass:"spacer-1"}),_c('MenuFilter',{on:{"select":_vm.onChangeFilter}}),_c('div',{class:{ 'button-download': true, disabled: !_vm.siteList.length },on:{"click":_vm.onClickDownload}},[_vm._v("Download as .xlsx")])],1),(_vm.openDatepicker)?_c('Calendar',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickCalendarOutside),expression:"onClickCalendarOutside"}],staticClass:"datepicker",attrs:{"min-date":_vm.calendarMinDate},on:{"update":_vm.onUpdateDate}}):_vm._e(),_c('div',{staticClass:"split-line"}),_c('div',{staticStyle:{"margin-left":"40px","margin-bottom":"20px"}},[_c('InputSearchBar',{attrs:{"placeholder":"Search"},on:{"input":_vm.onSelectSiteSearch}})],1),_c('div',{staticClass:"review-events-list-container"},[_vm._l((_vm.siteList),function(site){return _c('div',{key:site.camera_id,staticClass:"cam-container"},[_c('div',{class:{
          site: true,
          focus: _vm.selectSite === site.id,
        },on:{"click":function($event){return _vm.onSelectSite(site.id)}}},[_c('div',{staticClass:"text-truncate",domProps:{"innerHTML":_vm._s(_vm.createSearchMaskTag(site.name))}}),_c('v-spacer'),(site.logs.length && !_vm.$vuetify.theme.dark && _vm.selectSite !== site.id)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-down-sub-black.svg")}}):_vm._e(),(site.logs.length && _vm.$vuetify.theme.dark && _vm.selectSite !== site.id)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-down-dark.svg"),"width":"14px"}}):_vm._e(),(site.logs.length && !_vm.$vuetify.theme.dark && _vm.selectSite == site.id)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-up-sub-black.svg")}}):_vm._e(),(site.logs.length && _vm.$vuetify.theme.dark && _vm.selectSite == site.id)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-up-sub.svg")}}):_vm._e()],1),(_vm.selectSite === site.id && site.logs.length)?_c('div',{staticClass:"review-events-content-container"},[_c('div',{staticClass:"wrapper"},[_vm._l((site.logs),function(log){return _c('div',{key:log.log_id,class:{
              'log-content': true,
              focus: _vm.selectId === log.log_id,
              hidden: _vm.selectId !== -1 && _vm.selectId !== log.log_id,
            },on:{"click":function($event){return _vm.onClickLog(log)}}},[_c('div',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.displayLogTime(log)))]),_c('div',{staticClass:"spacer-1"}),(log.event_type_id > _vm.eventTypeList[1].id)?_c('div',{class:{
                'icon-label': true,
                fire: log.event_type_id === _vm.eventTypeList[2].id,
                'non-fire': log.event_type_id === _vm.eventTypeList[3].id,
                unknown: log.event_type_id === _vm.eventTypeList[4].id,
              }},[(log.event_type_id === _vm.eventTypeList[2].id)?_c('div',[_vm._v(_vm._s(_vm.eventTypeList[2].name))]):_vm._e(),(log.event_type_id === _vm.eventTypeList[3].id)?_c('div',[_vm._v(_vm._s(_vm.eventTypeList[3].name))]):_vm._e(),(log.event_type_id === _vm.eventTypeList[4].id)?_c('div',[_vm._v(_vm._s(_vm.eventTypeList[4].name))]):_vm._e()]):_vm._e(),(!_vm.$vuetify.theme.dark && _vm.selectId === -1)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-down-sub-black.svg")}}):_vm._e(),(_vm.$vuetify.theme.dark && _vm.selectId === -1)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-down-dark.svg"),"width":"14px"}}):_vm._e(),(!_vm.$vuetify.theme.dark && _vm.selectId !== -1)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-up-sub-black.svg")}}):_vm._e(),(_vm.$vuetify.theme.dark && _vm.selectId !== -1)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-up.svg")}}):_vm._e()])}),(_vm.selectId !== -1)?_c('div',{staticClass:"log-control-container"},[_c('ImagePlayer',{ref:"player",refInFor:true,staticStyle:{"position":"relative","min-height":"310px"},attrs:{"canvas-id":"reivewlog","currentLog":_vm.currentLog,"monitor":true,"frame":_vm.frameIndex,"play":_vm.play,"download":_vm.frameMode === 'FRAME' && _vm.displayLogTime(_vm.currentLog)},on:{"stop":_vm.onStopPlayer,"loaded":function($event){_vm.loadedImages = $event
                _vm.loading = false},"frame":function($event){_vm.currentFrameIndex = $event},"margin-left":function($event){_vm.imageMarginLeft = $event}}}),_c('div',{staticClass:"log-control"},[(_vm.currentLog)?_c('ImagePlayerBar',{ref:"playerbar",refInFor:true,style:({ height: '53px', width: '430px' }),attrs:{"images":_vm.loadedImages,"frame":_vm.currentFrameIndex,"play":_vm.play},on:{"changeFrame":_vm.onChangeFrame,"changeMode":function($event){_vm.frameMode = $event},"onPlay":function($event){return _vm.onPlay()}}}):_vm._e(),_c('ButtonPlayCircle',{staticStyle:{"margin-left":"25px","margin-right":"9px"},attrs:{"didPlay":_vm.didPlay,"playing":_vm.playing,"play":_vm.play,"loading":_vm.loading,"disabled":_vm.loading},on:{"play":function($event){return _vm.onPlay()}}}),_c('ButtonEdit',{on:{"click":function($event){_vm.showEditDialog = true}}})],1)],1):_vm._e()],2)]):_vm._e()])}),_c('Pagination',{staticStyle:{"margin-top":"10px","margin-bottom":"40px"},attrs:{"length":_vm.filteredList.length,"page-per-count":_vm.pagePerCount},on:{"select-range":_vm.onChangePageRange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],2),_c('EditLabelDialog',{attrs:{"event-type":_vm.currentLog ? _vm.currentLog.event_type_id : 1,"content":_vm.displayLogTime(_vm.currentLog)},on:{"ok":_vm.onClickSaveLabel},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}}),_c('SaveLabelDialog',{on:{"ok":_vm.onClickSaveLabel},model:{value:(_vm.showSaveLabelDialog),callback:function ($$v) {_vm.showSaveLabelDialog=$$v},expression:"showSaveLabelDialog"}}),_c('SnackBar',{attrs:{"color":_vm.errorColor,"message":_vm.errorMessage},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }