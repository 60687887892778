var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"player-bar-root",staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.onChangeMode()}}},[_c('div',{staticStyle:{"position":"absolute","width":"100%","height":"100%"}},[_c('div',{class:{
        'player-bar-timebar': true,
        start: _vm.timebarStart || _vm.timebarMiddle,
        end: _vm.mode === _vm.FRAME ? _vm.timebarEnd : _vm.timebarPositionFull,
      },style:(_vm.timebarPosition)}),(_vm.mode === _vm.FRAME)?_c('div',{class:{
        'player-bar-timebar-border': true,
        start: _vm.timebarStart,
        middle: _vm.timebarMiddle,
        end: _vm.timebarEnd,
      },style:(_vm.timebarPosition)}):(!_vm.timebarPositionFull)?_c('div',{class:{ 'player-bar-timebar-border': true },style:(_vm.timebarPosition)}):_vm._e()]),_c('div',{staticClass:"player-bar-container"},_vm._l((_vm.loadedImages),function(image,index){return _c('div',{key:index,style:(_vm.makeStyle(image, index)),on:{"mouseover":function($event){_vm.frameIndex = index}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded && index === _vm.detectionFrameIndex && !_vm.admin && _vm.$vuetify.theme.dark),expression:"isLoaded && index === detectionFrameIndex && !admin && $vuetify.theme.dark"}],class:_vm.getCaretClass('up'),staticStyle:{"opacity":"1"},attrs:{"src":require("@/assets/Caret-Up_Filled_16px_Icon.svg"),"width":"14px"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded && index === _vm.detectionFrameIndex && !_vm.admin && _vm.$vuetify.theme.dark),expression:"isLoaded && index === detectionFrameIndex && !admin && $vuetify.theme.dark"}],class:_vm.getCaretClass('down'),staticStyle:{"opacity":"1"},attrs:{"src":require("@/assets/Caret-Down_Filled_16px_Icon.svg"),"width":"14px"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded && index === _vm.detectionFrameIndex && !_vm.admin && !_vm.$vuetify.theme.dark),expression:"isLoaded && index === detectionFrameIndex && !admin && !$vuetify.theme.dark"}],class:_vm.getCaretClass('up'),staticStyle:{"opacity":"1"},attrs:{"src":require("@/assets/Caret-Up_Filled_12px_Icon_Light_Mode.svg"),"width":"14px"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded && index === _vm.detectionFrameIndex && !_vm.admin && !_vm.$vuetify.theme.dark),expression:"isLoaded && index === detectionFrameIndex && !admin && !$vuetify.theme.dark"}],class:_vm.getCaretClass('down'),staticStyle:{"opacity":"1"},attrs:{"src":require("@/assets/Caret-Down_Filled_12px_Icon_Light_Mode.svg"),"width":"14px"}})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }