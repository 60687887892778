<template>
  <div class="background">
    <div class="camlist-header">
      <div class="camlist-title">Site</div>
      <v-spacer></v-spacer>
      <diV class="camlist-count"
        >Monitored Camera<span style="font-weight: 600; color: #6d7dff; margin-left: 9px">{{ cams.length }}</span></diV
      >
    </div>
    <div v-for="(row, index) of camList" :key="index" class="cam-row-container">
      <div v-for="cam of row" :key="cam.id" @click="onClickCam(cam)" class="cam-container">
        <!-- <div style="position: relative">
          <div v-if="currentCam && currentCam.id === cam.id" class="cam--focus">
            <img src="" />
          </div>
        </div> -->
        <LazyImg v-if="cam.name" :data-src="Util.getImageUrl(cam.path, true)" :placeholder-src="placeholderImg" class="cam-image"></LazyImg>
        <div v-if="cam.name" class="cam-text text-truncate">{{ cam.name }}</div>
      </div>
    </div>
    <div class="button-container">
      <v-btn v-if="!isListEnd" @click="onClickViewMore" class="button-more">View More...</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Util from '@/util'
import LazyImg from '@common/LazyImg'

export default {
  components: { LazyImg },
  props: {
    currentCam: Object,
    /**
     * emit events
     * select Object
     */
  },
  data() {
    return {
      Util,
      placeholderImg: require('@/assets/skeleton.jpg'),
      camList: [],
      loading: false,
      page: 1,
      LIMIT: 12,
      NUM_OF_COUNT: 4,
      INTERVAL_SITE_IMAGE: 10000,
      isListEnd: false,
    }
  },
  computed: {
    ...mapState({ cams: 'camList' }),
  },
  created() {
    this.loading = true
    this.refreshCamList()
  },
  watch: {
    cams(val) {
      if (val) {
        this.refreshCamList()
      }
    },
  },
  destroyed() {
    clearTimeout(this.intervalImages)
  },
  methods: {
    onClickViewMore() {
      this.page++
      this.loading = true
      this.refreshCamList(true)
    },
    requestIntervalImages() {
      clearTimeout(this.intervalImages)
      this.intervalImages = setTimeout(() => {
        this.refreshCamList(this.loading)
      }, this.INTERVAL_SITE_IMAGE)
    },
    async refreshCamList(isLoading) {
      const cams = this.truncateCamList(this.cams)
      this.isListEnd = cams.length === this.cams.length
      this.camList = this.manipulateCamList(cams)
      this.loading = false
      // if (isLoading) {
      //   setTimeout(() => {
      //     const htmlEl = document.querySelector('html')
      //     htmlEl.scroll({ top: htmlEl.scrollHeight, behavior: 'smooth' })
      //   }, 500)
      // }
      this.requestIntervalImages()
    },
    truncateCamList(cams) {
      const count = this.page * this.LIMIT
      const truncatedCams = cams.slice(0, count)
      // already loaded cams
      this.loadedCams = []
      for (let i = count; i < cams.length; i++) {
        const cam = cams[i]
        if (cam.image) {
          this.loadedCams.push(cam)
        }
      }
      return truncatedCams
    },
    manipulateCamList(cams) {
      const camList = []
      let tmp = []
      for (let i = 0, j = 0; i < cams.length; i++, j++) {
        if (j === this.NUM_OF_COUNT) {
          camList.push(tmp)
          j = 0
          tmp = []
        }
        tmp.push(cams[i])
        if (i === cams.length - 1) {
          camList.push(tmp)
          // add empty childs
          if (tmp.length < this.NUM_OF_COUNT) {
            for (let i = this.NUM_OF_COUNT - tmp.length; i > 0; i--) {
              tmp.push({})
            }
          }
        }
      }
      return camList
    },
    onClickCam(cam) {
      if (cam.name) {
        this.$emit('select', cam)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.background {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 20px;
}

.camlist-header {
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-bottom: 15px;
}

.camlist-title {
  color: var(--v-textDefault);
  font-weight: 500;
  font-size: 18px;
}

.camlist-count {
  font-size: 14px;
  color: var(--v-textDefault);
}

.cam-row-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 15px 30px;
}

.cam-container {
  display: flex;
  flex-direction: column;
  width: 24%;
  box-shadow: 5px 5px 10px #00000014;
  background-color: var(--v-backgroundSite-base);
  border-radius: 20px;
}

.cam-image {
  width: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0px 0px;
}

.cam--focus {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #00000059;
  outline: 5px solid #2c89f5;
  outline-offset: -5px;
}

.cam-text {
  font-weight: 500;
  font-size: 14px;
  color: var(--v-textDefault);
  padding: 7px 20px;
  text-align: center;
  line-height: 20px;
}

.button-container {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  margin-bottom: 50px;
}

.button-more {
  text-transform: capitalize;
  background-color: #3d51fb !important;
  border-radius: 35px;
  width: 260px;
  height: 40px !important;
  font-weight: 500;
  font-size: 16px;
  color: #ffffffde !important;
}
</style>
