<template>
  <div>
    <div>
      <div class="log-accordian-calendar-container">
        <div class="log-accordian-label">Date</div>
        <div class="label">{{ dates }}</div>
        <div @click="openDatepicker = !openDatepicker" class="image">
          <img src="@/assets/Icon material-date-range.svg" />
        </div>
      </div>
      <Calendar
        v-if="openDatepicker"
        :min-date="calendarMinDate"
        v-click-outside="onClickCalendarOutside"
        @update="onUpdateDate"
        class="datepicker"
      ></Calendar>
      <div class="log-accordian-label site"></div>
    </div>
    <div class="log-accordian-content history">
      <div class="wrapper">
        <div v-for="date in sortedDateList" :key="date.name">
          <div
            v-if="date.logs.length"
            @click="onClickLog(date)"
            :class="{
              site: true,
              focus: selectSiteDate === date.name,
            }"
          >
            <div class="text-truncate">
              {{ date.name }} <span class="log-count">{{ date.logs.length }}</span>
            </div>
            <div class="spacer-1"></div>
            <img v-if="!$vuetify.theme.dark && selectSiteDate !== date.name" src="@/assets/Icon ionic-ios-arrow-down-sub-black.svg" />
            <img v-if="$vuetify.theme.dark && selectSiteDate !== date.name" src="@/assets/Icon ionic-ios-arrow-down-dark.svg" width="14px" />
            <img v-if="!$vuetify.theme.dark && selectSiteDate == date.name" src="@/assets/Icon ionic-ios-arrow-up-sub-black.svg" />
            <img v-if="$vuetify.theme.dark && selectSiteDate == date.name" src="@/assets/Icon ionic-ios-arrow-up-sub.svg" />
          </div>
          <div class="wrapper">
            <div v-if="selectSiteDate === date.name" class="restrict-height">
              <div
                v-for="log in date.logs"
                :key="log.log_id"
                @click="onClickLog(log)"
                :class="{
                  text: true,
                  sub: true,
                  focus: selectId === log.log_id,
                }"
              >
                {{ displayLogTime(log) }}
                <div class="spacer-1"></div>
                <div
                  :class="{
                    'icon-label': true,
                    fire: log.event_type_id === eventTypeList[2].id,
                    'non-fire': log.event_type_id === eventTypeList[3].id,
                    unknown: log.event_type_id === eventTypeList[4].id,
                    planned: log.event_type_id === eventTypeList[5].id,
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Util from '@/util'
import Calendar from '@common/Calendar'
import moment from 'moment'

export default {
  components: { Calendar },
  props: {
    selectSite: Number,
    selectId: Number,
    tabIndex: Number,
    siteList: Array,
    /**
     * emit events
     * click-log Object
     */
  },
  data() {
    const startDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')
    return {
      openDatepicker: false,
      dates: `${startDate} ~ ${endDate}`,
      selectedDate: '',
    }
  },
  computed: {
    ...mapState(['camMap', 'eventTypeList', 'camList', 'user', 'logListv2']),
    calendarMinDate() {
      const history = moment(this.user.history_start)
      const days = moment().diff(history, 'days')
      return moment()
        .startOf('day')
        .subtract(days + 1, 'days')
    },
    sortedDateList() {
      this.logListv2.sort((a, b) => a.created_at > b.created_at)
      const dateList = []
      const dateMap = {}
      this.logListv2.forEach((log) => {
        const date = moment(log.created_at).format('YYYY-MM-DD')
        if (dateMap[date] === undefined) {
          dateMap[date] = dateList.length
          const site = this.siteList.find((site) => site.id === log.camera_id)
          dateList.push({ name: date, site, logs: [] })
        }
        dateList[dateMap[date]].logs.push(log)
      })
      return dateList
    },
    selectSiteDate() {
      this.$log.debug('AsideLogHistory#selectSiteDate', this.selectSite, this.selectId)
      if (this.selectSite === -1 || this.selectId === -1 || this.selectId === undefined) {
        this.selectedDate = ''
        return ''
      }
      const log = this.logListv2.find((log) => log.log_id === this.selectId)
      if (log) {
        const date = moment(log.created_at).format('YYYY-MM-DD')
        this.selectedDate = date
        return date
      } else {
        this.selectedDate = ''
        return ''
      }
    },
  },
  mounted() {
    // 검색 가능 기간의 default는 1주 전이지만 계약기간이 이보다 작다면 default 검색을 검색 가능한 기간으로 설정한다.
    let prevWeek = moment().subtract(7, 'days')
    let startDate = moment(this.calendarMinDate).add(1, 'days')
    if (prevWeek.isAfter(startDate)) {
      startDate = prevWeek.format('YYYY-MM-DD')
    } else {
      startDate = startDate.format('YYYY-MM-DD')
    }
    const endDate = moment().format('YYYY-MM-DD')
    this.onUpdateDate([startDate, endDate])
  },
  methods: {
    ...mapActions(['getLogList']),
    onClickCalendarOutside() {
      this.openDatepicker = false
    },
    displayLogTime(log) {
      const cam = this.camMap[log.camera_id]
      if (cam) {
        const date = Util.getOffsetDate(log.created_at, log.offset)
        return `[${date}] ${cam.name}`
      } else {
        return log.log
      }
    },
    async onUpdateDate(date) {
      this.$log.debug('AsideLogHistory#onUpdateDate', date)
      const startDate = moment(date[0])
      const endDate = moment(date[1])
      this.dates = `${date[0]} ~ ${date[1]}`
      this.openDatepicker = false
      await this.getLogList({ startDate: startDate.startOf('day').format(), endDate: endDate.endOf('day').format(), tabIndex: this.tabIndex })
      // init list
      this.$log.debug('AsideLogHistory#onUpdateDate', this.selectedDate, this.selectSite, this.selectId)
      const log = this.logListv2.find((log) => log.log_id === this.selectId)
      if (log) {
        const date = moment(log.created_at).format('YYYY-MM-DD')
        this.selectedDate = date
        this.$emit('click-log', log)
      }
    },
    onClickLog(siteOrLog) {
      this.$log.debug('AsideLogHistory#onClickLog', this.selectedDate, ',', siteOrLog)
      if (siteOrLog.site) {
        if (this.selectedDate === siteOrLog.name || !this.selectedDate) {
          if (this.selectedDate === siteOrLog.name) {
            this.selectedDate = ''
          } else {
            this.selectedDate = siteOrLog.name
          }
          // 현재 선택된 로그의 사이트로 올려 보내야 닫힘
          const site = this.camList.find((cam) => cam.id === this.selectSite)
          this.$log.debug('AsideLogHistory#onClickLog site', site)
          if (site) {
            this.$emit('click-log', site)
          } else {
            this.$emit('click-log', siteOrLog.logs[0])
          }
        } else {
          this.selectedDate = siteOrLog.name
          this.$emit('click-log', siteOrLog.logs[0])
        }
      } else {
        this.$emit('click-log', siteOrLog)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.log-accordian-label {
  color: var(--v-textDefault-white);
  font-weight: 500;
  font-size: 20px;

  &.site {
    margin-top: 4px;
  }
}

.log-accordian-calendar-container {
  display: flex;
  align-items: center;
  color: var(--v-textDefault);
  margin-top: 15px;
  margin-left: 40px;
  margin-right: 21px;
  font-size: 16px;

  .label {
    width: 301px;
    height: 40px;
    background-color: var(--v-backgroundDate-base);
    border-radius: 10px;
    padding: 9px 20px;
    margin-left: 15px;
    margin-right: 10px;
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #3d51fb;
    user-select: none;
    cursor: pointer;
    &:active {
      opacity: 0.5;
    }
  }
}

.log-accordian-content {
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: var(--v-backgroundAsideHeaderRecentBorder-base);
  margin: 10px 16px 0px 34px;
  border-radius: 15px;
  max-height: 402px;
  overflow: hidden;

  &.history {
    flex-grow: 1;
    margin-top: 0px;
    margin-left: 54px;
    max-height: 280px;
    border-width: 0px;
    border-radius: 0px;
  }

  .wrapper {
    overflow: auto;
  }

  .log-count {
    margin-left: 10px;
    display: inline-block;
    text-align: center;
    background-color: var(--v-backgroundCount);
    color: var(--v-textCount);
    height: 22px;
    width: 36px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
  }

  .restrict-height {
    margin-top: 10px;

    .text {
      display: flex;
      font-weight: 400;
      align-items: center;
      font-size: 15px;
      color: var(--v-textDefault-white);
      padding: 5px 20px;
      user-select: text;

      &.focus {
        color: #3d51fb;
      }
    }
  }

  .site {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    height: 60px;
    flex: 0 0 60px;
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    color: var(--v-textDefault);
    background-color: var(--v-backgroundSearch-base);
    margin-top: 10px;

    &.focus {
      background-color: var(--v-backgroundAsideHeaderRecentLabel-base);
      // color: #5f8bff;
    }

    & div {
      flex-grow: 1;
      margin-left: 20px;
    }

    & img {
      margin: 0px 20px;
    }
  }
}

.icon-label {
  min-width: 13px;
  height: 13px;
  border-radius: 13px;
  border: 1px solid var(--v-backgroundUnselected);

  &.fire {
    background-color: var(--v-backgroundFire-base);
    border-width: 0px;
  }

  &.non-fire {
    background-color: var(--v-backgroundNoneFire-base);
    border-width: 0px;
  }

  &.unknown {
    background-color: var(--v-backgroundUnknown-base);
    border-width: 0px;
  }

  &.planned {
    background-color: var(--f-supplementary-purple);
    border-width: 0px;
  }
}

.datepicker {
  position: absolute;
  top: 292px;
  left: -345px;
}
</style>
