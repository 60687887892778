<template>
  <div class="review-events-container">
    <div class="review-events-title">Review Events</div>
    <div class="review-events-control-container">
      <div class="subtitle">Date</div>
      <div class="calendar-container">{{ dates }}</div>
      <div @click="openDatepicker = !openDatepicker" class="image">
        <img src="@/assets/Icon material-date-range.svg" />
      </div>
      <div class="spacer-1"></div>
      <MenuFilter @select="onChangeFilter"></MenuFilter>
      <div @click="onClickDownload" :class="{ 'button-download': true, disabled: !siteList.length }">Download as .xlsx</div>
    </div>
    <Calendar
      v-if="openDatepicker"
      :min-date="calendarMinDate"
      v-click-outside="onClickCalendarOutside"
      @update="onUpdateDate"
      class="datepicker"
    ></Calendar>
    <div class="split-line"></div>
    <div style="margin-left: 40px; margin-bottom: 20px">
      <InputSearchBar placeholder="Search" @input="onSelectSiteSearch"></InputSearchBar>
    </div>
    <div class="review-events-list-container">
      <div v-for="site in siteList" :key="site.camera_id" class="cam-container">
        <div
          @click="onSelectSite(site.id)"
          :class="{
            site: true,
            focus: selectSite === site.id,
          }"
        >
          <div class="text-truncate" v-html="createSearchMaskTag(site.name)"></div>
          <v-spacer></v-spacer>
          <img v-if="site.logs.length && !$vuetify.theme.dark && selectSite !== site.id" src="@/assets/Icon ionic-ios-arrow-down-sub-black.svg" />
          <img
            v-if="site.logs.length && $vuetify.theme.dark && selectSite !== site.id"
            src="@/assets/Icon ionic-ios-arrow-down-dark.svg"
            width="14px"
          />
          <img v-if="site.logs.length && !$vuetify.theme.dark && selectSite == site.id" src="@/assets/Icon ionic-ios-arrow-up-sub-black.svg" />
          <img v-if="site.logs.length && $vuetify.theme.dark && selectSite == site.id" src="@/assets/Icon ionic-ios-arrow-up-sub.svg" />
        </div>
        <div v-if="selectSite === site.id && site.logs.length" class="review-events-content-container">
          <div class="wrapper">
            <div
              v-for="log in site.logs"
              :key="log.log_id"
              @click="onClickLog(log)"
              :class="{
                'log-content': true,
                focus: selectId === log.log_id,
                hidden: selectId !== -1 && selectId !== log.log_id,
              }"
            >
              <div class="text-truncate">{{ displayLogTime(log) }}</div>
              <div class="spacer-1"></div>
              <div
                v-if="log.event_type_id > eventTypeList[1].id"
                :class="{
                  'icon-label': true,
                  fire: log.event_type_id === eventTypeList[2].id,
                  'non-fire': log.event_type_id === eventTypeList[3].id,
                  unknown: log.event_type_id === eventTypeList[4].id,
                }"
              >
                <div v-if="log.event_type_id === eventTypeList[2].id">{{ eventTypeList[2].name }}</div>
                <div v-if="log.event_type_id === eventTypeList[3].id">{{ eventTypeList[3].name }}</div>
                <div v-if="log.event_type_id === eventTypeList[4].id">{{ eventTypeList[4].name }}</div>
              </div>
              <img v-if="!$vuetify.theme.dark && selectId === -1" src="@/assets/Icon ionic-ios-arrow-down-sub-black.svg" />
              <img v-if="$vuetify.theme.dark && selectId === -1" src="@/assets/Icon ionic-ios-arrow-down-dark.svg" width="14px" />
              <img v-if="!$vuetify.theme.dark && selectId !== -1" src="@/assets/Icon ionic-ios-arrow-up-sub-black.svg" />
              <img v-if="$vuetify.theme.dark && selectId !== -1" src="@/assets/Icon ionic-ios-arrow-up.svg" />
            </div>
            <div v-if="selectId !== -1" class="log-control-container">
              <ImagePlayer
                ref="player"
                canvas-id="reivewlog"
                :currentLog="currentLog"
                :monitor="true"
                @stop="onStopPlayer"
                @loaded="
                  loadedImages = $event
                  loading = false
                "
                @frame="currentFrameIndex = $event"
                :frame="frameIndex"
                :play="play"
                :download="frameMode === 'FRAME' && displayLogTime(currentLog)"
                @margin-left="imageMarginLeft = $event"
                style="position: relative; min-height: 310px"
              ></ImagePlayer>
              <div class="log-control">
                <ImagePlayerBar
                  v-if="currentLog"
                  ref="playerbar"
                  :images="loadedImages"
                  :frame="currentFrameIndex"
                  :play="play"
                  @changeFrame="onChangeFrame"
                  @changeMode="frameMode = $event"
                  @onPlay="onPlay()"
                  :style="{ height: '53px', width: '430px' }"
                ></ImagePlayerBar>
                <ButtonPlayCircle
                  :didPlay="didPlay"
                  :playing="playing"
                  :play="play"
                  :loading="loading"
                  :disabled="loading"
                  @play="onPlay()"
                  style="margin-left: 25px; margin-right: 9px"
                ></ButtonPlayCircle>
                <ButtonEdit @click="showEditDialog = true"></ButtonEdit>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        v-model="page"
        :length="filteredList.length"
        :page-per-count="pagePerCount"
        @select-range="onChangePageRange"
        style="margin-top: 10px; margin-bottom: 40px"
      ></Pagination>
    </div>
    <EditLabelDialog
      v-model="showEditDialog"
      :event-type="currentLog ? currentLog.event_type_id : 1"
      :content="displayLogTime(currentLog)"
      @ok="onClickSaveLabel"
    ></EditLabelDialog>
    <SaveLabelDialog v-model="showSaveLabelDialog" @ok="onClickSaveLabel"></SaveLabelDialog>
    <SnackBar v-model="error" :color="errorColor" :message="errorMessage"></SnackBar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Constants from '@/constants'
import Util from '@/util'
import moment from 'moment'
import momentTz from 'moment-timezone'
import Calendar from '@common/Calendar'
import MenuFilter from './MenuFilter'
import Pagination from './Pagination'
import ButtonEdit from './ButtonEdit'
import ButtonPlayCircle from './ButtonPlayCircle'
import EditLabelDialog from '@desktop/dialog/EditLabelDialog'
import SaveLabelDialog from '@desktop/dialog/SaveLabelDialog'
import InputSearchBar from '@common/InputSearchBar'
import ImagePlayer from '@common/ImagePlayer/ImagePlayer'
import ImagePlayerBar from '@common/ImagePlayer/ImagePlayerBar'
import SnackBar from '@common/SnackBar'
import { alert } from '@/api'

export default {
  components: {
    Calendar,
    MenuFilter,
    Pagination,
    ImagePlayer,
    ImagePlayerBar,
    EditLabelDialog,
    InputSearchBar,
    SaveLabelDialog,
    ButtonEdit,
    ButtonPlayCircle,
    SnackBar,
  },
  data() {
    return {
      Constants,
      startDate: '',
      endDate: '',
      filter: [],
      filterSite: '',
      openDatepicker: false,
      selectSite: -1,
      selectId: -1,
      page: 1,
      pagePerCount: 12,
      pageRange: [0, 12],
      currentLog: null,
      play: 'stop',
      didPlay: false,
      playing: false,
      playLogId: -1,
      showEditDialog: false,
      showSaveLabelDialog: false,
      selectedLabel: '',
      imageMarginLeft: 0,
      error: false,
      errorColor: 'error',
      errorMessage: '',
      loadedImages: [],
      currentFrameIndex: 0,
      frameIndex: 0,
      frameMode: '',
      loading: false,
    }
  },
  computed: {
    ...mapState(['camMap', 'camList', 'eventTypeList', 'user']),
    dates() {
      return `${this.startDate} ~ ${this.endDate}`
    },
    displayList() {
      return this.camList.filter((cam) => cam.logs.length)
    },
    filteredList() {
      let filterList = []
      for (let i = 0, j = this.displayList.length; i < j; i++) {
        const cam = this.displayList[i]
        if (this.filterSite) {
          if (cam.name.toLowerCase().includes(this.filterSite)) {
            filterList.push({ ...cam })
          }
        } else {
          filterList.push({ ...cam })
        }
      }
      if (this.filter.length) {
        for (let i = 0, j = filterList.length; i < j; i++) {
          const cam = filterList[i]
          cam.logs = cam.logs.filter((log) => {
            if (log.event_type_id <= this.eventTypeList[1].id) {
              log.event_type_id = this.eventTypeList[1].id
            }
            return this.filter.includes(log.event_type_id)
          })
        }
        filterList = filterList.filter((cam) => cam.logs.length)
      }
      return filterList
    },
    siteList() {
      return this.filteredList.slice(this.pageRange[0], this.pageRange[1])
    },
    calendarMinDate() {
      const history = moment(this.user.history_start)
      const days = moment().diff(history, 'days')
      return moment()
        .startOf('day')
        .subtract(days + 1, 'days')
    },
  },
  async created() {
    // 검색 가능 기간의 default는 1주 전이지만 계약기간이 이보다 작다면 default 검색을 검색 가능한 기간으로 설정한다.
    let prevWeek = moment().subtract(7, 'days')
    let startDate = moment(this.calendarMinDate).add(1, 'days')
    if (prevWeek.isAfter(startDate)) {
      startDate = prevWeek.format('YYYY-MM-DD')
    } else {
      startDate = startDate.format('YYYY-MM-DD')
    }
    const endDate = moment().format('YYYY-MM-DD')
    await this.onUpdateDate([startDate, endDate])
  },
  methods: {
    ...mapActions(['getLogList', 'getRecentLogList']),
    async onUpdateDate(date) {
      this.startDate = date[0]
      this.endDate = date[1]
      const startDate = moment(this.startDate).startOf('day').format()
      const endDate = moment(this.endDate).endOf('day').format()
      this.statusClear()
      await this.getLogList({ startDate, endDate })
    },
    statusClear() {
      this.openDatepicker = false
      this.selectSite = -1
      this.selectId = -1
      this.currentLog = null
    },
    onChangeFilter(e) {
      const filter = this.eventTypeList.filter((type) => e[type.id]).map((event) => event.id)
      this.$log.debug('onChangeFilter', e, filter)
      this.filter = filter
      this.statusClear()
    },
    async onClickDownload() {
      if (!this.error && this.siteList.length) {
        try {
          this.error = true
          this.errorColor = 'secondary'
          this.errorMessage = 'File downloading...'
          const start_date = moment(this.startDate).startOf('day').format()
          const end_date = moment(this.endDate).endOf('day').format()
          const event_type_id = this.filter.length ? this.filter : this.eventTypeList.filter((type) => type.id).map((event) => event.id)
          const camera_name = this.filterSite ? this.filterSite : undefined
          const time_zone = moment.tz.guess()
          const { data } = await alert.downloadLogHistory({ start_date, end_date, event_type_id, camera_name, time_zone })
          var link = document.createElement('a')
          link.target = '_blank'
          link.href = Util.makeUrl(process.env.VUE_APP_CDN_URL, data.xlsx_path)
          link.click()
        } catch (e) {
          this.error = true
          this.errorColor = 'error'
          const message = e.response.data?.result?.message || e
          this.errorMessage = message
        } finally {
          this.error = false
        }
      }
    },
    createSearchMaskTag(text) {
      const lowerText = text.toLowerCase()
      if (this.filterSite && lowerText.includes(this.filterSite)) {
        const startIndex = lowerText.indexOf(this.filterSite)
        const endIndex = startIndex + this.filterSite.length
        return `${text.substring(0, startIndex)}<span class="search-mask">${text.substring(startIndex, endIndex)}</span>${text.substring(endIndex)}`
      } else {
        return text
      }
    },
    onSelectSiteSearch(text) {
      this.$log.debug('onSelectSiteSearch', text)
      this.statusClear()
      if (!text) {
        this.filterSite = ''
      } else {
        this.filterSite = text.toLowerCase()
      }
      this.page = 1
    },
    onSelectSite(camid) {
      this.$log.debug('onClickSite', camid)
      const cam = this.camMap[camid]
      if (cam && cam.logs.length) {
        if (this.selectSite !== cam.id) {
          this.selectSite = camid
        } else {
          this.selectSite = -1
        }
        this.selectId = -1
        this.currentLog = null
      }
    },
    async onClickLog(log) {
      this.$log.debug('onClickLog', log)
      if (this.selectId !== -1 && this.selectId === log.log_id) {
        this.selectId = -1
        this.currentLog = null
        this.$emit('select-log', null)
      } else {
        this.selectSite = log.camera_id
        this.selectId = log.log_id
        this.play = 'stop'
        this.didPlay = false
        this.playing = false
        this.currentLog = null
        this.loadedImages = []
        this.$nextTick(() => {
          this.currentLog = log
          this.$emit('select-log', log)
        })
      }
    },
    displayLogTime(log) {
      if (log) {
        const cam = this.camMap[log.camera_id]
        const date = Util.getOffsetDate(log.created_at, log.offset)
        return `[${date}] ${cam.name}`
      }
    },
    onClickCalendarOutside() {
      this.openDatepicker = false
    },
    onChangePageRange(pages) {
      this.pageRange = pages
    },
    onStopPlayer() {
      this.$log.debug('Admin#onStopPlayer', this.playLogId, this.currentLog?.log_id)
      this.play = 'stop'
      if (this.currentLog && this.playLogId === this.currentLog.log_id) {
        this.didPlay = true
      } else {
        this.didPlay = false
      }
      this.playing = false
    },
    onPlay() {
      if (this.currentLog) {
        this.$log.debug('ReviewEvents#onPlay', this.play, this.playing, this.didPlay)
        if (this.play === 'play') {
          this.play = 'pause'
          this.playing = true
        } else if (this.play === 'pause') {
          this.changeModePlaybar()
          this.play = 'play'
        } else {
          this.changeModePlaybar()
          this.didPlay = false
          this.play = 'play'
          this.playLogId = this.currentLog.log_id
          this.loading = true
        }
      }
    },
    changeModePlaybar() {
      const playerbar = this.$refs.playerbar instanceof Array ? this.$refs.playerbar[0] : this.$refs.playerbar
      if (playerbar) {
        if (playerbar.mode === playerbar.FRAME) {
          playerbar.onChangeMode(true)
        }
      }
    },
    onChangeFrame(frameIndex) {
      this.$log.debug('ReviewEvents#onChangeFrame', this.play, this.didPlay, this.frameIndex, frameIndex)
      if (this.frameIndex === frameIndex) {
        this.frameIndex = frameIndex
        const player = this.$refs.player instanceof Array ? this.$refs.player[0] : this.$refs.player
        this.$log.debug('ReviewEvents#onChangeFrame', this.$refs)
        player.playerPause(frameIndex)
      } else {
        this.frameIndex = frameIndex
      }
      if (this.play === 'play' || (this.play === 'stop' && !this.didPlay)) {
        this.onPlay()
      } else {
        this.play = 'pause'
        this.playing = true
        this.didPlay = false
      }
    },
    async onClickSaveLabel(label) {
      this.$log.debug('onClickSaveLabel', label)
      if (typeof label === 'number') {
        this.selectedLabel = label
        this.showSaveLabelDialog = true
      } else if (typeof label === 'boolean' && label) {
        try {
          await alert.updateEventType({ log_id: this.selectId, event_type_id: this.selectedLabel })
          this.currentLog.event_type_id = this.selectedLabel
          await this.getRecentLogList()
        } catch (e) {
          this.$log.error(e)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.review-events-container {
  display: flex;
  flex-direction: column;

  .review-events-title {
    font-weight: 500;
    font-size: 24px;
    color: var(--v-textDefault);
    margin: 15px 0 20px 40px;
  }

  .datepicker {
    position: absolute;
    top: 190px;
    left: 411px;
    z-index: 1;
  }

  .split-line {
    background-color: #d3d5dd;
    width: 100%;
    height: 1px;
    margin: 20px 0 20px 40px;
  }
}

.review-events-control-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: 50px;

  .subtitle {
    font-weight: 500;
    font-size: 20px;
    color: var(--v-textDefault);
    margin-right: 20px;
  }
  .calendar-container {
    display: flex;
    align-items: center;
    width: 270px;
    height: 40px;
    border-radius: 10px;
    background-color: var(--v-backgroundDate-base);
    font-size: 16px;
    color: var(--v-textDefault);
    padding: 0 20px;
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #3d51fb;
    user-select: none;
    cursor: pointer;
    margin-left: 27px;
    margin-right: 27px;

    &:active {
      opacity: 0.5;
    }
  }

  .button-download {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--v-backgroundDownload);
    font-weight: 500;
    font-size: 15px;
    color: var(--v-backgroundDownloadText);
    border-radius: 30px;
    margin-left: 15px;
    min-width: 150px;
    height: 40px;
    cursor: pointer;

    &:active {
      opacity: 0.5;
    }

    &.disabled {
      background-color: var(--v-backgroundDownloadDisabled);
      color: var(--v-backgroundDownloadDisabledText);
    }
  }
}

.review-events-list-container {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-bottom: 72px;

  .cam-container {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    margin-bottom: 15px;

    .site {
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      min-height: 60px;
      cursor: pointer;
      user-select: none;
      border-radius: 15px;
      color: var(--v-textDefault);
      background-color: var(--v-backgroundSearch-base);

      &.focus {
        background-color: var(--v-backgroundAsideHeaderRecentLabel-base);
        // color: #5f8bff;
      }

      & div {
        flex-grow: 1;
        margin-left: 30px;
      }

      & img {
        margin: 0px 25px;
      }
    }
  }
}

.review-events-content-container {
  max-height: 450px;
  background-color: var(--v-backgroundAsideHeaderRecent-base);
  border: 1px solid var(--v-backgroundMonitorSplitLine-base);
  border-radius: 15px;
  margin-top: 10px;
  overflow: auto;

  .wrapper {
    overflow: hidden;
  }

  .log-content {
    display: flex;
    align-items: center;
    min-height: 56px;
    padding: 0 25px 0 20px;
    cursor: pointer;

    &:not(:last-child) {
      border-width: 0 0 1px;
      border-style: solid;
      border-color: var(--v-backgroundMonitorSplitLine-base);
    }

    &.hidden {
      display: none;
    }

    img {
      margin-left: 20px;
    }

    .icon-label {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 110px;
      height: 36px;
      border-radius: 30px;
      font-weight: 500;
      font-size: 15px;
      color: #ffffffde;

      &.fire {
        background-color: var(--v-backgroundFire-base);
      }

      &.non-fire {
        background-color: var(--v-backgroundNoneFire-base);
      }

      &.unknown {
        background-color: var(--v-backgroundUnknown-base);
      }
    }
  }
}

.log-control-container {
  display: flex;
  flex-direction: column;

  .log-control {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 14px;
  }
}
</style>
