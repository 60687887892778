<template>
  <v-menu v-model="open" @input="onChange" offset-y nudge-bottom="10px" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" :class="{ 'filter-button': true, filtered }">
        Filter
        <v-spacer></v-spacer>
        <img v-if="open && !filtered" src="@/assets/icon-arrow-up.svg" />
        <img v-else-if="open && filtered" src="@/assets/icon-arrow-up-fill.svg" />
        <img v-else-if="!open && !filtered" src="@/assets/icon-arrow-down.svg" />
        <img v-else src="@/assets/icon-arrow-down-fill.svg" />
      </div>
    </template>
    <div class="filter-menu">
      <div @click="onClickItem(eventTypeList[2].id)" class="item">
        <img v-if="filter[eventTypeList[2].id]" src="@/assets/Checkbox_Checked_Icon.svg" />
        <img v-else-if="!$vuetify.theme.dark" src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" />
        <img v-else src="@/assets/Checkbox_Unchecked_Icon.svg" />
        {{ eventTypeList[2].name }}
      </div>
      <div @click="onClickItem(eventTypeList[3].id)" class="item">
        <img v-if="filter[eventTypeList[3].id]" src="@/assets/Checkbox_Checked_Icon.svg" />
        <img v-else-if="!$vuetify.theme.dark" src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" />
        <img v-else src="@/assets/Checkbox_Unchecked_Icon.svg" />
        {{ eventTypeList[3].name }}
      </div>
      <div @click="onClickItem(eventTypeList[4].id)" class="item">
        <img v-if="filter[eventTypeList[4].id]" src="@/assets/Checkbox_Checked_Icon.svg" />
        <img v-else-if="!$vuetify.theme.dark" src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" />
        <img v-else src="@/assets/Checkbox_Unchecked_Icon.svg" />
        {{ eventTypeList[4].name }}
      </div>
      <div @click="onClickItem(eventTypeList[1].id)" class="item">
        <img v-if="filter[eventTypeList[1].id]" src="@/assets/Checkbox_Checked_Icon.svg" />
        <img v-else-if="!$vuetify.theme.dark" src="@/assets/Checkbox_Unchecked_Grey_Icon.svg" />
        <img v-else src="@/assets/Checkbox_Unchecked_Icon.svg" />
        {{ eventTypeList[1].name }}
      </div>
      <v-spacer></v-spacer>
      <div @click="onClickSave" class="save-button">Save</div>
    </div>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import Constants from '@/constants'

export default {
  /**
   * emit events
   * select Object
   */
  data() {
    return {
      Constants,
      open: false,
      filtered: false,
      save: {},
      filter: {},
    }
  },
  computed: {
    ...mapState(['eventTypeList']),
  },
  created() {
    this.save = {
      [this.eventTypeList[1].id]: false,
      [this.eventTypeList[2].id]: false,
      [this.eventTypeList[3].id]: false,
      [this.eventTypeList[4].id]: false,
      [0]: false,
    }
    this.filter = {
      [this.eventTypeList[1].id]: false,
      [this.eventTypeList[2].id]: false,
      [this.eventTypeList[3].id]: false,
      [this.eventTypeList[4].id]: false,
      [0]: false,
    }
  },
  methods: {
    onChange(open) {
      this.open = open
      if (open) {
        this.filter = { ...this.save }
      }
    },
    onClickItem(label) {
      this.filter[label] = !this.filter[label]
    },
    onClickSave() {
      this.save = { ...this.filter }
      this.filtered = Object.values(this.save).some((v) => v)
      this.open = false
      this.$emit('select', this.save)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-button {
  display: flex;
  align-items: center;
  background-color: #3d51fb;
  border-radius: 30px;
  padding: 0px 20px;
  font-weight: 500;
  font-size: 15px;
  color: var(--v-textCount);
  min-width: 120px;
  height: 40px;

  &.filtered {
    background-color: transparent;
    color: #3d51fb;
    border: 1px solid #3d51fb;
  }
}

.filter-menu {
  display: flex;
  flex-direction: column;
  background-color: var(--v-backgroundAsideTooltip-base);
  border-radius: 15px;
  width: 133px;
  height: 207px;
  font-weight: 500;
  font-size: 15px;
  color: var(--v-textDefault);

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 12px 17px 0;

    img {
      margin-right: 7px;
    }
  }

  .save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3d51fb;
    border-radius: 30px;
    width: 90px;
    align-self: center;
    min-height: 30px;
    color: var(--v-textCount);
    margin-bottom: 15px;
    cursor: pointer;

    :active {
      opacity: 0.5;
    }
  }
}
</style>
