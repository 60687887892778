<template>
  <img :src="src" :alt="alt" />
</template>

<script>
export default {
  props: {
    dataSrc: String,
    placeholderSrc: String,
    alt: String,
  },
  data: function () {
    return {
      loaded: false,
      src: this.placeholderSrc,
    }
  },
  created() {
    this.loadImage()
  },
  watch: {
    dataSrc(val) {
      if (val) {
        this.loadImage()
      }
    },
  },
  methods: {
    loadImage: function () {
      const img = new Image()
      img.src = this.dataSrc
      img.addEventListener(
        'load',
        () => {
          this.src = this.dataSrc
          this.loaded = true
        },
        { once: true }
      )
    },
  },
}
</script>
