<template>
  <div class="background-calendar">
    <div class="header">
      <div class="text-month">{{ monthNames[current.get('month')] }}, {{ year }}</div>
    </div>
    <div class="calendar-split-line"></div>
    <div class="calendar-container">
      <img v-if="$vuetify.theme.dark" @click="prevMonth" src="@/assets/Icon ionic-ios-arrow-prev-white.svg" />
      <img v-else @click="prevMonth" src="@/assets/Icon ionic-ios-arrow-prev-black.svg" />
      <div>
        <!-- daysOfWeek -->
        <div class="row-week">
          <div v-for="(week, index) in daysOfWeek" :key="index" class="text-box">
            {{ week }}
          </div>
        </div>
        <div v-for="(row, rowIndex) in dateProps" :key="rowIndex" class="row-date">
          <div
            v-for="(prop, colIndex) in row"
            :key="colIndex"
            @click="onClickDate(prop.date)"
            class="day text-box"
            @mouseover="onMouseOver(prop.date)"
          >
            <div
              :class="{
                focus: isFocus(prop.date),
                start: isStart(prop.date),
                end: isEnd(prop.date),
                middle: isMiddle(prop.date),
              }"
            ></div>
            <div :class="{ today: isToday(prop.date), dot: !isFocus(prop.date), hide: prop.hide }"></div>
            <div
              :class="{
                hide: prop.hide || isFocus(prop.date),
                min: isMinDate(prop.date),
                max: isMaxDate(prop.date),
              }"
            >
              {{ prop.date.get('date') }}
            </div>
          </div>
        </div>
        <!-- calendar rows -->
      </div>
      <img v-if="$vuetify.theme.dark" @click="nextMonth" src="@/assets/Icon ionic-ios-arrow-next-white.svg" />
      <img v-else @click="nextMonth" src="@/assets/Icon ionic-ios-arrow-next-black.svg" />
    </div>
  </div>
</template>

<script>
import Calendar from '@common/Calendar'

export default {
  extends: Calendar,
  data() {
    return {
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    }
  },
}
</script>

<style lang="scss" scoped>
.background-calendar {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 268px;
  font-size: 12px;
  font-weight: 500;
  background-color: var(--v-backgroundAsideHeaderRecent-base);

  img {
    margin: 0 5px;
    opacity: 0.87;
  }
}

.header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  color: var(--v-textDefault);
  font-size: 14px;
  margin-top: 28px;
  margin-bottom: 10px;
}

.calendar-split-line {
  width: 238px;
  height: 1px;
  background-color: var(--v-backgroundMonitorSplitLine-base);
  margin-bottom: 12px;
}

.calendar-container {
  display: flex;
  flex-wrap: nowrap;
}

.text-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 26px;
}

.row-week {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  color: var(--v-textDefault);
}

.row-date {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .day {
    position: relative;
    font-weight: 400;
    color: var(--v-textDefault);
    cursor: pointer;

    &:first-child {
      color: var(--v-textCalendarSun-base);
    }

    &:last-child {
      color: var(--v-textCalendarSat-base);
    }

    .hide {
      color: var(--v-backgroundAsideHeaderRecent-base);
      z-index: 1;
    }

    .focus {
      position: absolute;
      width: 100%;
      height: 20px;
      background-color: #3d51fb;

      &.start {
        border-radius: 20px 0px 0px 20px;
      }

      &.end {
        border-radius: 0px 20px 20px 0px;
      }

      &.start.end {
        border-radius: 20px;
      }

      & ~ .hide {
        color: white;
      }
    }

    .min,
    .max {
      display: flex;
      align-items: center;
      justify-content: center;
      // text-decoration: line-through;
      width: 100%;
      height: 100%;
      position: absolute;
      color: var(--v-textDisabled);

      &.hide {
        color: var(--v-backgroundAsideHeaderRecent-base);
      }
    }

    .today {
      position: absolute;
      background-color: #3d51fb;
      width: 3px;
      height: 3px;
      margin-top: 26px;
      border-radius: 3px;
      z-index: 1;

      &.dot {
        background-color: #3d51fb;

        &.hide {
          background-color: var(--v-backgroundAsideHeaderRecent-base);
        }
      }
    }
  }
}
</style>
