var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"log-accordian-calendar-container"},[_c('div',{staticClass:"log-accordian-label"},[_vm._v("Date")]),_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.dates))]),_c('div',{staticClass:"image",on:{"click":function($event){_vm.openDatepicker = !_vm.openDatepicker}}},[_c('img',{attrs:{"src":require("@/assets/Icon material-date-range.svg")}})])]),(_vm.openDatepicker)?_c('Calendar',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickCalendarOutside),expression:"onClickCalendarOutside"}],staticClass:"datepicker",attrs:{"min-date":_vm.calendarMinDate},on:{"update":_vm.onUpdateDate}}):_vm._e(),_c('div',{staticClass:"log-accordian-label site"})],1),_c('div',{staticClass:"log-accordian-content history"},[_c('div',{staticClass:"wrapper"},_vm._l((_vm.sortedDateList),function(date){return _c('div',{key:date.name},[(date.logs.length)?_c('div',{class:{
            site: true,
            focus: _vm.selectSiteDate === date.name,
          },on:{"click":function($event){return _vm.onClickLog(date)}}},[_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(date.name)+" "),_c('span',{staticClass:"log-count"},[_vm._v(_vm._s(date.logs.length))])]),_c('div',{staticClass:"spacer-1"}),(!_vm.$vuetify.theme.dark && _vm.selectSiteDate !== date.name)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-down-sub-black.svg")}}):_vm._e(),(_vm.$vuetify.theme.dark && _vm.selectSiteDate !== date.name)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-down-dark.svg"),"width":"14px"}}):_vm._e(),(!_vm.$vuetify.theme.dark && _vm.selectSiteDate == date.name)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-up-sub-black.svg")}}):_vm._e(),(_vm.$vuetify.theme.dark && _vm.selectSiteDate == date.name)?_c('img',{attrs:{"src":require("@/assets/Icon ionic-ios-arrow-up-sub.svg")}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"wrapper"},[(_vm.selectSiteDate === date.name)?_c('div',{staticClass:"restrict-height"},_vm._l((date.logs),function(log){return _c('div',{key:log.log_id,class:{
                text: true,
                sub: true,
                focus: _vm.selectId === log.log_id,
              },on:{"click":function($event){return _vm.onClickLog(log)}}},[_vm._v(" "+_vm._s(_vm.displayLogTime(log))+" "),_c('div',{staticClass:"spacer-1"}),_c('div',{class:{
                  'icon-label': true,
                  fire: log.event_type_id === _vm.eventTypeList[2].id,
                  'non-fire': log.event_type_id === _vm.eventTypeList[3].id,
                  unknown: log.event_type_id === _vm.eventTypeList[4].id,
                  planned: log.event_type_id === _vm.eventTypeList[5].id,
                }})])}),0):_vm._e()])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }