var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"log-accordion-content"},[_c('div',{staticClass:"log-accordion-content wrapper"},_vm._l((_vm.recentLogList),function(log,index){return _c('div',{key:log.log_id,class:{
        'log-accordion-content': true,
        text: true,
        focus: _vm.selectId === log.log_id || (_vm.selectId === -1 && index === 0),
      },on:{"click":function($event){return _vm.onClickLog(log)}}},[_vm._v(" "+_vm._s(_vm.displayLogTime(log))+" "),_c('div',{staticClass:"spacer-1"}),_c('div',{class:{
          'icon-label': true,
          fire: log.event_type_id === _vm.eventTypeList[2].id,
          'non-fire': log.event_type_id === _vm.eventTypeList[3].id,
          unknown: log.event_type_id === _vm.eventTypeList[4].id,
          planned: log.event_type_id === _vm.eventTypeList[5].id,
        }})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }