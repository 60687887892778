<template>
  <v-dialog v-model="value" max-width="500px" @keydown.esc="hide" @click:outside="hide">
    <v-card style="border-radius: 30px; background-color: var(--v-backgroundAsideHeaderRecent-base)">
      <v-card-text class="pa-0">
        <div class="save-label-dialog-title">Edit Event Type</div>
        <div class="save-label-dialog-content">
          <div class="text-truncate" style="padding: 0 40px; margin: 40px 0 30px">{{ content }}</div>
          <div class="icon-label-container">
            <div
              @click="onClickLabel(eventTypeList[2].id)"
              :class="{ 'icon-label': true, fire: true, select: (select || eventType) === eventTypeList[2].id }"
            >
              {{ eventTypeList[2].name }}
            </div>
            <div
              @click="onClickLabel(eventTypeList[3].id)"
              :class="{ 'icon-label': true, 'non-fire': true, select: (select || eventType) === eventTypeList[3].id }"
            >
              {{ eventTypeList[3].name }}
            </div>
            <div
              @click="onClickLabel(eventTypeList[4].id)"
              :class="{ 'icon-label': true, unknown: true, select: (select || eventType) === eventTypeList[4].id }"
            >
              {{ eventTypeList[4].name }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-end" style="padding-right: 30px; padding-bottom: 20px">
          <button @click="hide" class="save-label-dialog-button cancel">Cancel</button>
          <button @click="ok" class="save-label-dialog-button ok">Save</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import Constants from '@/constants'

export default {
  props: {
    value: Boolean,
    content: String,
    eventType: Number,
    /**
     * emit events
     * ok
     */
  },
  data() {
    return {
      Constants,
      select: '',
    }
  },
  computed: {
    ...mapState(['eventTypeList']),
  },
  methods: {
    onClickLabel(label) {
      this.select = label
    },
    hide() {
      this.$emit('input', false)
      this.select = ''
    },
    ok() {
      if (this.select) {
        this.$emit('ok', this.select)
      }
      this.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.save-label-dialog-title {
  padding: 30px 40px 0;
  font-weight: 600;
  font-size: 20px;
  color: var(--v-textAside);
}

.save-label-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: var(--v-textDefault);

  .icon-label-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 62px;

    .icon-label {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 110px;
      height: 36px;
      border-radius: 30px;
      font-weight: 500;
      color: #8d90a2;
      border: 1px solid var(--v-textLabel);
      cursor: pointer;

      &.non-fire {
        margin: 0 10px;
      }

      &.select {
        color: #ffffffde;
        border-width: 0;

        &.fire {
          background-color: var(--v-backgroundFire-base);
        }

        &.non-fire {
          background-color: var(--v-backgroundNoneFire-base);
          margin: 0 10px;
        }

        &.unknown {
          background-color: var(--v-backgroundUnknown-base);
        }
      }
    }
  }
}

.save-label-dialog-button {
  font-weight: 500;
  font-size: 15px;
  width: 120px;
  height: 38px;
  border-radius: 30px;

  &.ok {
    background-color: #3d51fb;
    color: var(--v-textCount);
  }

  &.cancel {
    background-color: var(--v-backgroundButtonCancel-base);
    color: var(--v-textButtonCancel);
    margin-right: 15px;
  }
}
</style>
